import page from 'page';

export interface RouteCallbackArgs<
  T extends Record<string, string> | undefined = undefined
> {
  ctx: {querystring: string; params: T};
  navigate: (path: string) => void;
  redirect: (path: string) => void;
  updateUrl: (path: string) => void;
}

export interface Router {
  redirect: (path: string) => void;
  navigate: (path: string) => void;
  updateUrl: (path: string) => void;
}

class PageJSRouter<
  T extends {
    match: string;
    cb: (args: RouteCallbackArgs<any>) => void;
  }[]
> implements Router
{
  private readonly service = page;

  constructor(configs: T) {
    const {navigate, redirect, updateUrl} = this;
    configs.forEach((config) => {
      this.service(config.match, (ctx) =>
        config.cb({ctx, navigate, redirect, updateUrl})
      );
    });
    this.service();
  }

  public navigate = (path: string) => {
    this.service(path);
  };

  public redirect = (path: string) => {
    this.service.redirect(path);
  };

  public updateUrl = (path: string) => {
    const base = String(this.service.base());
    path = path.replace(`${window.location.protocol}//`, '');
    path = path.replace(window.location.host, '');

    if (!path.match(/^https?:\/\//) && !path.startsWith(base)) {
      path = `${base}/${path}`;
    }
    window.history.replaceState({path}, document.title, path);
  };
}

export const createRouter = <
  T extends {
    match: string;
    cb: (args: RouteCallbackArgs<any>) => void;
  }[]
>(
  configs: T
): Router => new PageJSRouter<T>(configs);

export type RouterFactory = typeof createRouter;
