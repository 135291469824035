import {ApiUsers} from './users/index';
import type {ApiService} from './service';
import {ApiRulesets} from './rulesets/index';
import {Paginated} from './base';
import {LinksWithNext} from './service';
import {ApiGroups} from './groups/index';
import {ApiRetrohuntJobs} from './retrohunt-jobs';
import {ApiShareableEntities} from './shareable-entities';

export class ApiClient {
  constructor(public readonly service: ApiService) {}
  public fromCollResponseToPaginated = <
    ACR extends {links?: LinksWithNext<any>}
  >(
    response: ACR
  ): Paginated<ACR> => ({
    response,
    next: response.links?.next
      ? () =>
          this.service
            .createSimpleRequest(response.links!.next!)
            .then(this.fromCollResponseToPaginated)
      : undefined,
  });
  public users = new ApiUsers(this.service);
  public groups = new ApiGroups(this.service);
  public rulesets = new ApiRulesets(this.service);
  public retrohuntJobs = new ApiRetrohuntJobs(this.service);
  public shareableEntities = new ApiShareableEntities(this.service);
}
