export const DEFAULT_RULES = `/*
  Livehunt YARA ruleset template

  Learn more about writing Livehunt YARA rules at
  https://docs.virustotal.com/docs/livehunt.

  Livehunt allows you to match file report metadata in addition to binary contents.
  A ruleset is a collection of one or more Livehunt rules. A ruleset containing 3
  YARA rules will consume 3 Livehunt rule credits. 2 rulesets, one containing 2
  YARA rules and another one containing 3 YARA rules, will consume 5 Livehunt
  rule credits.
*/
rule yara_template
{
  meta:
    author = ""
    description = ""
    target_entity = "file"
  strings:
    $a = "First string"
    $b = "Second string"
    $c = "Third string"
    $d = "Fourth string"
  condition:
    all of them
}`;

const DEFAULT_NETWORK_RULES_PREAMBLE = `/*
  Network Livehunt YARA ruleset template

  Learn more about writing network Livehunt YARA rules at
  https://docs.virustotal.com/docs/nethunt.

  Network Livehunt allows you to match URLs, domains and IP addresses
  reports. In the case of URLs, you can also match the downloaded
  contents, as if it was a regular file.
  A ruleset is a collection of one or more Livehunt rules. A ruleset containing 3
  YARA rules will consume 3 Livehunt rule credits. 2 rulesets, one containing 2
  YARA rules and another one containing 3 YARA rules, will consume 5 Livehunt
  rule credits.
*/
import "vt"
`;

export const DEFAULT_URL_RULES = `${DEFAULT_NETWORK_RULES_PREAMBLE}
rule url_template
{
  meta:
    author = ""
    description = ""
    target_entity = "url"
  condition:
    vt.net.url.new_url and
    vt.net.domain.raw == "example.com"
}`;

export const DEFAULT_DOMAIN_RULES = `${DEFAULT_NETWORK_RULES_PREAMBLE}
rule domain_template
{
  meta:
    author = ""
    description = ""
    target_entity = "domain"
  condition:
    vt.net.domain.new_domain and
    vt.net.domain.raw endswith ".example.com" and
    vt.net.domain.analysis_stats.malicious >= 2
}`;

export const DEFAULT_IP_ADDRESS_RULES = `${DEFAULT_NETWORK_RULES_PREAMBLE}
rule ip_address_template
{
  meta:
    author = ""
    description = ""
    target_entity = "ip_address"
  condition:
    vt.net.ip.raw matches /^11\.22\.33\./ and
    vt.net.ip.analysis_stats.malicious >= 2
}`;

// localStorage key used for default ruleset value
export const LS_RULES_KEY = 'hunting-ruleset-template';
export const LS_RULESET_NAME_KEY = 'hunting-ruleset-template-name';
export const LS_RULESET_NOTIFICATION_EMAILS_KEY =
  'hunting-ruleset-template-notification-emails';

const DEFAULTS_CACHE = new Map<string, string>();

function getFromCacheOrLocalStorage(key: string) {
  if (DEFAULTS_CACHE.has(key)) return DEFAULTS_CACHE.get(key);
  const value = localStorage.getItem(key)!;
  localStorage.removeItem(key);
  DEFAULTS_CACHE.set(key, value);
  return value;
}

export function getDefaultRules() {
  return getFromCacheOrLocalStorage(LS_RULES_KEY) || DEFAULT_RULES;
}

export function getDefaultURLRules() {
  return DEFAULT_URL_RULES;
}

export function getDefaultDomainRules() {
  return DEFAULT_DOMAIN_RULES;
}

export function getDefaultIPAddressRules() {
  return DEFAULT_IP_ADDRESS_RULES;
}

export function setDefaultRules(rules: string) {
  DEFAULTS_CACHE.delete(LS_RULES_KEY);
  localStorage.setItem(LS_RULES_KEY, rules);
}

export function setDefaultRulesetName(name: string) {
  DEFAULTS_CACHE.delete(LS_RULESET_NAME_KEY);
  localStorage.setItem(LS_RULESET_NAME_KEY, name);
}

export function setDefaultRulesetNotificationEmails(emails: string) {
  DEFAULTS_CACHE.delete(LS_RULESET_NOTIFICATION_EMAILS_KEY);
  localStorage.setItem(LS_RULESET_NOTIFICATION_EMAILS_KEY, emails);
}

export function getDefaultRulesetName() {
  return (
    getFromCacheOrLocalStorage(LS_RULESET_NAME_KEY) || 'Untitled YARA ruleset'
  );
}

export function getDefaultRulesetNotificationEmails() {
  return (
    getFromCacheOrLocalStorage(LS_RULESET_NOTIFICATION_EMAILS_KEY)?.split(
      ','
    ) || []
  );
}
