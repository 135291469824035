import {ApiClient} from '../api-client/index';
import {RulesetTargetKind} from '../api-client/rulesets/types';

export const getRuleset = (apiClient: ApiClient, id: string) =>
  apiClient.rulesets.get(id, ['owner'] as const);

export const getVtModule = (
  apiClient: ApiClient,
  kind: RulesetTargetKind,
  entityId: string
) =>
  apiClient.rulesets
    .getVtYaraModule(kind, entityId)
    .then(({data}) => ({vt: data}));
