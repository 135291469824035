const RULESET_TARGET_KINDS = ['file', 'url', 'domain', 'ip_address'] as const;
export type RulesetTargetKind = (typeof RULESET_TARGET_KINDS)[number];

export const rulesetTargetKind = (input: unknown): RulesetTargetKind | null => {
  if (
    typeof input === 'string' &&
    RULESET_TARGET_KINDS.includes(input as RulesetTargetKind)
  ) {
    return input as RulesetTargetKind;
  }
  return null;
};

export const humanizeKind = (kind: RulesetTargetKind): string => {
  switch (kind) {
    case 'domain':
      return 'domains';
    case 'file':
      return 'files';
    case 'ip_address':
      return 'IP addresses';
    case 'url':
      return 'URLs';
  }
};

export interface EditableRulesetAttributes {
  enabled: boolean;
  limit: number;
  name: string;
  rules?: string;
  notification_emails: string[];
  match_object_type: RulesetTargetKind;
}

export const EDITABLE_RULESET_ATTRIBUTES = new Set<
  keyof EditableRulesetAttributes
>([
  'enabled',
  'limit',
  'name',
  'rules',
  'notification_emails',
  'match_object_type',
]);

export interface RulesetAttributes extends EditableRulesetAttributes {
  creation_date: number;
  modification_date?: number;
  number_of_rules?: number;
  rate_limited?: boolean;
  rate_limited_ratio?: number;
  rule_names?: string[];
  scheduled_disable_date?: number;
  scheduled_disable_reason?: 'timeouts' | 'abandoned';
}

export interface RulesetContextAttributes {
  matches_count: number;
}
