import {LitElement, PropertyValueMap, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {retrohuntIcon} from '../../icons';
import {initializeTooltips} from '../../utils';
import '../unsaved-changes-indicator/unsaved-changes-indicator';
import {setDefaultRules} from '../../context/defaults';
import {SaveRulesetRequested} from '../../view/events';

@customElement('retrohunt-navbar')
export class RetrohuntNavbar extends LitElement {
  @property()
  public retrohuntJobId?: string;

  @property()
  public rules = '';

  @property({type: Boolean})
  public saving = false;

  public createRenderRoot() {
    return this;
  }

  public updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    super.updated(changedProperties);
    initializeTooltips(this);
  }

  public render() {
    return html`
      <style>
        .navbar-brand {
          width: 52.5px;
          transition: width 0.2s ease-in-out;
          overflow: hidden;
        }
        .navbar-brand:hover {
          background-color: var(--bs-tertiary-bg);
          width: 204.4px;
        }
      </style>
      <div class="hstack">
        <a
          href="https://www.virustotal.com/gui/hunting/retrohunt"
          class="navbar-brand m-0 fs-3 hstack gap-3 link-primary px-3 pe-0"
        >
          <i class="hstack flex-grow-1">${retrohuntIcon}</i>
          <span class="fs-6 pe-3">Retrohunt dashboard</span>
        </a>

        <div class="fw-light hstack gap-2">
          <span
            class="ruleset-name-input text-body-secondary fs-5 fw-normal px-2"
          >
            ${this.retrohuntJobId || 'New retrohunt job'}
          </span>

          <unsaved-changes-indicator
            class="d-inline-flex"
          ></unsaved-changes-indicator>
        </div>

        ${when(
          this.retrohuntJobId,
          () => html`
            <a
              class="btn btn-outline-primary btn-sm ms-auto"
              @click="${() => setDefaultRules(this.rules)}"
              href="${import.meta.env.BASE_URL + 'retrohunt/new'}"
              target="_blank"
              data-bs-title="Start a new retrohunt session using this one's rules"
              data-bs-toggle="tooltip"
            >
              Launch Another Retrohunt
            </a>
          `,
          () => html`
            <button
              id="createJobBtn"
              class="btn btn-primary btn-sm ms-auto"
              ?disabled="${this.saving}"
              @click="${() => this.dispatchEvent(new SaveRulesetRequested())}"
            >
              Create job
            </button>
          `
        )}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'retrohunt-navbar': RetrohuntNavbar;
  }
}
