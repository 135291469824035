import {consume} from '@lit/context';
import {LitElement, PropertyValues, html} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {MonacoFactory, monacoFactoryContext} from '../../context/editor/index';
import {Monaco, editor as MonacoEditor} from '../../context/editor/types';
import {CodeEditorChanged, SaveRulesetRequested} from '../../view/events';
import {buildTheme} from './theme';
import {Diagnostic, DiagnosticSeverity} from '../editor-console/diagnostics';
import {RulesetTargetKind} from '../../api-client/rulesets/types';

export const THEME_NAME = 'vt-ui-code-editor-theme';
const updateTheme = (editor: typeof MonacoEditor, scheme: 'light' | 'dark') => {
  const styles = getComputedStyle(document.querySelector('html')!);
  const theme = buildTheme(scheme, styles);
  editor.defineTheme(THEME_NAME, theme);
};

@customElement('code-editor')
export class CodeEditor extends LitElement {
  @consume({context: monacoFactoryContext, subscribe: true})
  @property({attribute: false})
  public monacoFactory?: MonacoFactory;

  @property({type: String})
  public value = '';

  @property({type: String})
  public kind!: RulesetTargetKind;

  @state()
  public monaco?: Monaco;

  @state()
  public editor?: MonacoEditor.IStandaloneCodeEditor;

  @property({type: Object})
  public options?: Omit<
    Partial<MonacoEditor.IStandaloneEditorConstructionOptions>,
    'value'
  >;

  private mutationObserver?: MutationObserver;
  private globalHTMLRef?: HTMLHtmlElement;

  private get defaultOptions() {
    return {
      language: 'yara',
      theme: THEME_NAME,
      automaticLayout: true,
      readOnly: false,
      smoothScrolling: true,
    };
  }

  private get themeScheme() {
    return this.globalHTMLRef?.dataset.bsTheme as 'light' | 'dark';
  }

  private get mergedOptions() {
    return {
      ...this.defaultOptions,
      ...this.options,
      value: this.editor?.getValue() || this.value,
    };
  }

  public createRenderRoot() {
    return this;
  }

  public disconnectedCallback() {
    this.mutationObserver?.disconnect();
    super.disconnectedCallback();
  }

  public getValue() {
    return this.editor?.getValue();
  }

  private setValue(content: string) {
    if (!this.editor) return;
    const currentContent = this.getValue()!;
    if (content !== currentContent) {
      this.editor.setValue(content || '');
    }
    this.editor.focus();
  }

  private setupEditorActions(editor: MonacoEditor.IStandaloneCodeEditor) {
    const monaco = this.monaco!;
    editor.addAction({
      id: 'save-ruleset',
      label: 'Save ruleset',
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
      precondition: '!editorReadonly',
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: () => {
        this.dispatchEvent(new SaveRulesetRequested());
      },
    });
  }

  public async updated(changedProperties: PropertyValues) {
    const changed = changedProperties.has.bind(changedProperties);
    if (
      ['monacoFactory', 'kind'].some(changed) &&
      this.kind &&
      this.monacoFactory
    ) {
      this.monaco = this.monacoFactory(this.kind);
    }
    if (['value', 'editor'].some(changed) && this.editor) {
      this.setValue(this.value);
    }
    if (changed('options') && this.editor) {
      this.editor.updateOptions(this.mergedOptions);
    }
    if (changed('monaco') && this.monaco) {
      this.mutationObserver = new MutationObserver((mutationList) => {
        const scheme = mutationList.find(
          (mutation) =>
            mutation.type === 'attributes' &&
            mutation.attributeName === 'data-bs-theme'
        );
        if (!scheme || !this.editor) return;
        updateTheme(this.monaco!.editor, this.themeScheme!);
      });
      this.globalHTMLRef = document.querySelector('html')!;
      this.mutationObserver.observe(this.globalHTMLRef, {
        childList: false,
        attributes: true,
      });
      updateTheme(this.monaco.editor, this.themeScheme!);
      const {value, ...restOfOptions} = this.mergedOptions;
      this.editor = this.monaco.editor.create(
        this.renderRoot!.querySelector<HTMLHtmlElement>('#editor-container')!,
        restOfOptions
      );
      this.editor.getModel()!.onDidChangeContent((e) => {
        this.setDiagnostics([]);
        this.dispatchEvent(
          new CodeEditorChanged({
            contentChange: e,
            value: this.getValue()!,
          })
        );
      });
      this.setupEditorActions(this.editor);
      this.editor.setValue(value);
    }
  }

  public render() {
    return html`<style>
        .monaco-editor {
          position: absolute !important;
        }
      </style>
      <div class="h-100 w-100 placeholder-glow">
        <main
          id="editor-container"
          class="h-100 w-100 ${classMap({placeholder: !this.editor})}"
        ></main>
      </div>`;
  }

  public setDiagnostics(diagnostics: Diagnostic[]) {
    const model = this.editor?.getModel();
    if (!(model && this.monaco)) return;
    const markers = diagnostics
      .filter((d): d is Diagnostic & {line: number} => d.line !== undefined)
      .map((d) => ({
        startLineNumber: d.line || 1,
        endLineNumber: d.line || 1,
        message: d.message,
        startColumn: model.getLineFirstNonWhitespaceColumn(d.line || 1),
        endColumn: model.getLineLastNonWhitespaceColumn(d.line || 1),
        severity:
          d.severity === DiagnosticSeverity.Error
            ? this.monaco!.MarkerSeverity.Error
            : this.monaco!.MarkerSeverity.Warning,
      }));
    this.monaco.editor.setModelMarkers(model, 'owner', markers);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'code-editor': CodeEditor;
  }
}
