import {consume, provide} from '@lit/context';
import {Toast} from 'bootstrap';
import {LitElement, PropertyValues, html, nothing} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {rulesetTargetKind} from '../../api-client/rulesets/types';
import {
  UpdateApp,
  hasUnsavedChangesContext,
  updateAppContext,
} from '../../context/app';
import {
  CurrentUser,
  UserSession,
  currentUserContext,
  sessionContext,
} from '../../context/auth/index';
import {routerContext, routerFactoryContext} from '../../context/router';
import {
  RouteCallbackArgs,
  Router,
  RouterFactory,
} from '../../context/router/utils';
import {xmarkIcon} from '../../icons';
import {
  ShowToastRequested,
  SignOutRequested,
  ToastOptions,
  UnsavedStateUpdated,
} from '../events';
import {LivehuntView} from '../livehunt-view/livehunt-view';
import {RetrohuntView} from '../retrohunt-view/retrohunt-view';

@customElement('app-shell')
export class AppShell extends LitElement {
  @consume({context: sessionContext, subscribe: true})
  @property({attribute: false})
  public userSession!: UserSession;

  @consume({context: currentUserContext, subscribe: true})
  @property({attribute: false})
  public currentUser!: CurrentUser;

  @consume({context: routerFactoryContext})
  @property({attribute: false})
  public routerFactory!: RouterFactory;

  @consume({context: updateAppContext})
  @property({attribute: false})
  public updateApp!: UpdateApp;

  @provide({context: routerContext})
  @property({attribute: false})
  public router!: Router;

  @provide({context: hasUnsavedChangesContext})
  @property({attribute: false})
  public hasUnsavedChanges = false;

  @state()
  private toastHistory: ToastOptions[] = [];

  @state()
  private currentViewTemplate: unknown = nothing;

  public createRenderRoot() {
    return this;
  }

  public connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener(ShowToastRequested.id, (e: ShowToastRequested) => {
      this.toastHistory.push(e.detail);
      this.requestUpdate('toastHistory');
    });

    this.addEventListener(UnsavedStateUpdated.id, (e: UnsavedStateUpdated) => {
      this.hasUnsavedChanges = e.detail;
    });

    const hasUnsavedChanges = () => this.hasUnsavedChanges;

    window.onload = function () {
      window.addEventListener('beforeunload', (e) => {
        if (!hasUnsavedChanges()) {
          return undefined;
        }
        e.preventDefault();
        const message =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';
        e.returnValue = message;
        return message;
      });
    };

    this.addEventListener(SignOutRequested.id, () => {
      const message =
        'It looks like you have been editing something. ' +
        'If you leave before saving, your changes will be lost.';
      if (!hasUnsavedChanges() || confirm(message)) {
        this.hasUnsavedChanges = false; // ignore unsaved changes
        this.userSession.signOut();
      }
    });

    if (this.currentUser.hasGTIAccess) {
      const linkFavicon =
        document.head.querySelector<HTMLLinkElement>('link[rel="icon"]');
      if (linkFavicon) {
        linkFavicon.href = '/gti-logo.png';
      }
      const linkmaskIcon = document.head.querySelector<HTMLLinkElement>(
        'link[rel="mask-icon"]'
      );
      if (linkmaskIcon) {
        linkmaskIcon.href = '/gti-logo.png';
      }
      document.title = 'Google Threat Intelligence - YARA rulesets editor';
    }
  }

  private setupRouter() {
    this.router = this.routerFactory([
      {
        match: '/livehunt/new',
        cb: ({ctx, redirect}: RouteCallbackArgs<{id: string}>) => {
          const query = new URLSearchParams(ctx.querystring);
          const kind = rulesetTargetKind(query.get('match_object_type'));
          if (!kind) {
            redirect('/livehunt/new?match_object_type=file');
            return;
          } else {
            this.currentViewTemplate = LivehuntView.newRulesetTemplate(kind);
          }
        },
      },
      {
        match: '/livehunt/import',
        cb: ({ctx, redirect}: RouteCallbackArgs<{id: string}>) => {
          redirect(
            `/livehunt/new${ctx.querystring ? `?${ctx.querystring}` : ''}`
          );
        },
      },
      {
        match: '/livehunt/:id',
        cb: ({ctx: {params}}: RouteCallbackArgs<{id: string}>) => {
          this.currentViewTemplate = LivehuntView.existingRulesetTemplate(
            params.id
          );
        },
      },
      {
        match: '/retrohunt/new',
        cb: () => {
          this.currentViewTemplate = RetrohuntView.newRulesetTemplate();
        },
      },
      {
        match: '/retrohunt/import',
        cb: ({ctx, redirect}: RouteCallbackArgs<{id: string}>) => {
          redirect(
            `/retrohunt/new${ctx.querystring ? `?${ctx.querystring}` : ''}`
          );
        },
      },
      {
        match: '/retrohunt/:id',
        cb: ({ctx: {params}}: RouteCallbackArgs<{id: string}>) => {
          this.currentViewTemplate = RetrohuntView.existingRulesetTemplate(
            params.id
          );
        },
      },
      {
        match: '*',
        cb: ({redirect}) => {
          redirect('/livehunt/new?match_object_type=file');
        },
      },
    ]);
  }

  protected willUpdate(changedProperties: PropertyValues) {
    super.willUpdate(changedProperties);
    if (changedProperties.has('routerFactory')) {
      this.setupRouter();
    }
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    if (changedProperties.has('toastHistory')) {
      Array.from(
        this.querySelectorAll<HTMLElement>(
          '.toast[role="alert"]:not(#liveToast)'
        )
      ).forEach((toastElement) => {
        if (Toast.getInstance(toastElement)) return;
        new Toast(toastElement).show();
      });
    }
  }

  public render() {
    return html`${this.currentUser && this.router
        ? this.currentViewTemplate
        : html`<main
            slot="spinner"
            class="vh-100 hstack justify-content-center"
          >
            <span
              class="spinner-grow spinner-grow-lg"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Loading...</span>
          </main>`}
      <div
        aria-live="polite"
        aria-atomic="true"
        class="toast-container top-0 start-50 translate-middle-x m-4 position-fixed"
      >
        <div
          id="liveToast"
          class="toast custom-toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div class="toast-body hstack gap-3">
            New version available.

            <a
              role="button"
              class="toast-link fw-bold p-2 rounded"
              @click="${() => this.updateApp()}"
            >
              Update
            </a>

            <a
              role="button"
              class="hstack fs-4 close-button ms-auto p-2 rounded-circle"
              data-bs-dismiss="toast"
              aria-label="Close"
            >
              ${xmarkIcon}
            </a>
          </div>
        </div>
        ${this.toastHistory.map(
          ({message, action, delay}) => html`<div
            class="toast custom-toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-delay="${delay || 5000}"
          >
            <div class="toast-body hstack gap-3">
              ${message}
              ${action
                ? 'string' === typeof action.linkOrFn
                  ? html`<a
                      class="toast-link fw-bold p-2 rounded"
                      href="${action.linkOrFn}"
                      target="_blank"
                    >
                      ${action.label}
                    </a>`
                  : html`<a
                      role="button"
                      class="toast-link fw-bold p-2 rounded"
                      @click="${action.linkOrFn}"
                    >
                      ${action.label}
                    </a>`
                : nothing}
              <a
                role="button"
                class="hstack fs-4 close-button ms-auto p-2 rounded-circle"
                data-bs-dismiss="toast"
                aria-label="Close"
              >
                ${xmarkIcon}
              </a>
            </div>
          </div>`
        )}
      </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'app-shell': AppShell;
  }
}
