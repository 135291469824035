import {ApiCrudBaseImpl} from '../base';
import {
  APIObjectResponse,
  ApiService,
  BasicRequestParams,
  ErrorFreeAPICollectionResponse,
  ListRequestParams,
  ObjectDescriptor,
  OneToManyRelationship,
  OneToOneRelationship,
  RelationshipNameToTypeMap,
  TypeToAttributes,
  TypeToOneToManyRelationships,
  TypeToOneToOneRelationships,
  TypeToRelationships,
} from '../service';
import {
  EditableRulesetAttributes,
  RulesetAttributes,
  RulesetContextAttributes,
  RulesetTargetKind,
} from './types';

interface RulesetRelationships {
  editors: OneToManyRelationship<'user' | 'group'>;
  viewers: OneToManyRelationship<'user' | 'group'>;
  owner: OneToOneRelationship<'user' | 'group'>;
}

export interface VtYaraModule {
  data: Record<string, unknown>;
}

declare module '../service' {
  interface TypeToAttributes {
    hunting_ruleset: RulesetAttributes;
  }

  interface TypeToRelationships {
    hunting_ruleset: RulesetRelationships;
  }
}

export class ApiRulesets extends ApiCrudBaseImpl<
  'hunting_ruleset',
  RulesetContextAttributes
> {
  protected readonly objectType = 'hunting_ruleset';

  constructor(protected service: ApiService) {
    super(service, 'intelligence/hunting_rulesets');
  }

  public get<
    ATTRS extends keyof TypeToAttributes['hunting_ruleset'],
    RELS extends keyof TypeToRelationships['hunting_ruleset'] = never
  >(
    id: string,
    abridged?: readonly RELS[],
    additionalHeaders?: HeadersInit,
    params?: BasicRequestParams<ATTRS>
  ): Promise<APIObjectResponse<'hunting_ruleset', RELS, ATTRS>> {
    return this.service.get({
      endPoint: `${this.collectionName}/${id}`,
      params: {
        relationships: abridged,
        ...(params || {}),
      },
      headers: additionalHeaders,
    });
  }

  public getVtYaraModule(
    kind: RulesetTargetKind,
    id: string,
    additionalHeaders?: HeadersInit
  ) {
    return this.service.get<VtYaraModule>({
      endPoint: `${this.collectionName}/vt_yara_module`,
      params: {
        kind,
        item: id,
      },
      headers: additionalHeaders,
    });
  }

  public list<
    ATTRS extends keyof TypeToAttributes['hunting_ruleset'],
    RELS extends keyof TypeToRelationships['hunting_ruleset'] = never
  >(params?: ListRequestParams<ATTRS, RELS>) {
    return this.service.get<
      ErrorFreeAPICollectionResponse<'hunting_ruleset', RELS, ATTRS>
    >({
      endPoint: `${this.collectionName}`,
      params: params,
    });
  }

  public create<
    RELS extends keyof TypeToRelationships['hunting_ruleset'] = never
  >(
    attributes: Partial<EditableRulesetAttributes>,
    abridged?: readonly RELS[]
  ): Promise<
    APIObjectResponse<'hunting_ruleset', RELS> & {
      meta?: {warnings?: string};
    }
  > {
    return this.service.post({
      endPoint: `${this.collectionName}`,
      data: {
        attributes,
        type: 'hunting_ruleset',
      },
      params: {
        relationships: abridged,
      },
    });
  }

  public patch<
    RELS extends keyof TypeToRelationships['hunting_ruleset'] = never
  >(
    id: string,
    attributes: Partial<EditableRulesetAttributes>,
    abridged?: readonly RELS[]
  ): Promise<
    APIObjectResponse<'hunting_ruleset', RELS> & {
      meta?: {warnings?: string};
    }
  > {
    return this.service.patch({
      endPoint: `${this.collectionName}/${id}`,
      data: {
        attributes,
        type: 'hunting_ruleset',
      },
      params: {
        relationships: abridged,
      },
    });
  }

  public listRelationship<
    R extends TypeToOneToManyRelationships['hunting_ruleset'],
    ATTRS extends keyof TypeToAttributes[RelationshipNameToTypeMap<
      'hunting_ruleset',
      R
    >],
    RELS extends keyof TypeToRelationships[RelationshipNameToTypeMap<
      'hunting_ruleset',
      R
    >] = never
  >(
    id: string,
    relationship: R,
    params?: ListRequestParams<ATTRS, RELS>,
    headers?: HeadersInit
  ): Promise<
    ErrorFreeAPICollectionResponse<
      RelationshipNameToTypeMap<'hunting_ruleset', R>,
      RELS,
      ATTRS
    >
  >;

  public listRelationship<
    R extends TypeToOneToOneRelationships['hunting_ruleset'],
    ATTRS extends keyof TypeToAttributes[RelationshipNameToTypeMap<
      'hunting_ruleset',
      R
    >],
    RELS extends keyof TypeToRelationships[RelationshipNameToTypeMap<
      'hunting_ruleset',
      R
    >]
  >(
    id: string,
    relationship: R,
    params?: ListRequestParams<ATTRS, RELS>,
    headers?: HeadersInit
  ): Promise<
    | APIObjectResponse<
        RelationshipNameToTypeMap<'hunting_ruleset', R>,
        RELS,
        ATTRS
      >
    | {data: null}
  >;

  public listRelationship(
    id: string,
    relationship: string,
    params?: ListRequestParams<any, any>,
    headers?: HeadersInit
  ) {
    return this.service.get({
      endPoint: `${this.collectionName}/${id}/${relationship}`,
      params: params,
      headers,
    });
  }

  public lookUpRelationship<
    R extends keyof TypeToRelationships['hunting_ruleset'],
    ATTRS extends keyof TypeToAttributes[RelationshipNameToTypeMap<
      'hunting_ruleset',
      R
    >],
    RELS extends keyof TypeToRelationships[RelationshipNameToTypeMap<
      'hunting_ruleset',
      R
    >] = never
  >(
    id: string,
    relationshipName: R,
    relatedId: string,
    params?: ListRequestParams<ATTRS, RELS>,
    headers?: HeadersInit
  ): Promise<{data: boolean}> {
    return this.service.get({
      endPoint: `${this.collectionName}/${id}/relationships/${relationshipName}/${relatedId}`,
      params: params,
      headers,
    });
  }

  public createRelationship<
    R extends keyof TypeToRelationships['hunting_ruleset']
  >(
    id: string,
    relationship: R,
    data: unknown,
    params?: ListRequestParams<never, never>
  ) {
    return this.service.post<void>({
      endPoint: `${this.collectionName}/${id}/relationships/${relationship}`,
      data,
      params,
      noResponse: true,
    });
  }

  public deleteRelationship<R extends 'viewers' | 'editors'>(
    id: string,
    relationship: R,
    contributors: (ObjectDescriptor<'user'> | ObjectDescriptor<'group'>)[],
    params?: ListRequestParams<never, never>
  ) {
    return this.service.delete<void>({
      endPoint: `${this.collectionName}/${id}/relationships/${relationship}`,
      body: JSON.stringify({data: contributors}),
      params,
      noResponse: true,
    });
  }

  public test(data: {item: string; kind: RulesetTargetKind; ruleset: string}) {
    return this.service.request<{
      data: {
        matches: {ruleName: string; snippet?: string; matchSource?: string}[];
      };
      meta?: {warnings?: string};
    }>({
      endPoint: `${this.collectionName}/test`,
      options: {
        method: 'post',
        body: JSON.stringify(data),
      },
    });
  }
}
