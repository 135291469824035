import type {editor} from '../../context/editor/types';

/**
 * Monaco theme color to css var
 */
const THEME_COLORS_MAPPINGS = {
  'foreground': '--bs-body-color',
  'background': '--bs-body-bg',
  'attribute.name': '--bs-info',
  'comment': '--bs-warning',
  'constant': '--bs-body-color',
  'invalid': '--bs-body-color',
  'keyword': '--bs-info',
  'number': '--bs-success',
  'number.hex': '--bs-success',
  'regexp': '--bs-success',
  'string': '--bs-danger',
  'variable': '--bs-body-color',
  'editor.background': '--bs-body-bg',
  'editor.foreground': '--bs-body-color',
  'editor.inactiveSelectionBackground': '--bs-secondary-bg',
  'editorIndentGuide.background': '--bs-secondary-bg',
  'editorIndentGuide.activeBackground': '--bs-tertiary-bg',
  'editor.selectionHighlightBackground': '--bs-tertiary-bg',
  'editor.lineHighlightBorder': '--bs-secondary-bg',
} as const;

type MonacoThemeColors = keyof typeof THEME_COLORS_MAPPINGS;
type CSSThemeVars = (typeof THEME_COLORS_MAPPINGS)[MonacoThemeColors];

const sanitizeValue = (cssValue: string) => {
  const trimmed = cssValue.trim().replace(/^#/, '');
  if (trimmed.toLowerCase() === 'fff') return 'ffffff';
  return trimmed;
};

export const buildTheme: (
  scheme: 'light' | 'dark',
  declaration: {
    getPropertyValue(variable: CSSThemeVars): string;
  }
) => editor.IStandaloneThemeData = (scheme, declaration) => {
  const value = (variable: CSSThemeVars) =>
    sanitizeValue(declaration.getPropertyValue(variable));
  return {
    base: scheme === 'dark' ? 'vs-dark' : 'vs',
    inherit: true,
    rules: [
      {
        token: '',
        foreground: value(THEME_COLORS_MAPPINGS.foreground),
        background: value(THEME_COLORS_MAPPINGS.background),
      },
      ...(
        [
          'attribute.name',
          'comment',
          'constant',
          'invalid',
          'keyword',
          'number',
          'number.hex',
          'regexp',
          'string',
          'variable',
        ] as const
      ).map((token) => ({
        token,
        foreground: value(THEME_COLORS_MAPPINGS[token]),
      })),
    ],
    colors: (
      [
        'editor.background',
        'editor.foreground',
        'editor.inactiveSelectionBackground',
        'editorIndentGuide.background',
        'editorIndentGuide.activeBackground',
        'editor.selectionHighlightBackground',
        'editor.lineHighlightBorder',
      ] as const
    ).reduce((acc, val) => {
      acc[val] = declaration
        .getPropertyValue(THEME_COLORS_MAPPINGS[val])
        .trim();
      return acc;
    }, {} as Record<MonacoThemeColors, string>),
  };
};
