import {APIObject, ApiService} from '../service';

export class ApiShareableEntities {
  constructor(private service: ApiService) {}
  public get(id: string): Promise<{
    data: (
      | APIObject<'user', never, 'first_name' | 'last_name'>
      | APIObject<'group', never, 'domain_name'>
    )[];
    // | APIObject<
    //     'team',
    //     keyof TypeToRelationships['team'],
    //     keyof TypeToAttributes['team']
    //   >
  }> {
    return this.service.get({
      endPoint: `get_shareable_entities/${id}`,
    });
  }
}
