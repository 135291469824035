import '@github/relative-time-element';
import {LitElement, PropertyValueMap, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {CurrentUser} from '../../context/auth';
import {moonIcon, sunIcon} from '../../icons';
import {initializeTooltips} from '../../utils';

@customElement('theme-switch')
export class ThemeSwitch extends LitElement {
  @property({type: Object})
  public user!: CurrentUser;

  public createRenderRoot() {
    return this;
  }

  public updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    super.updated(changedProperties);
    initializeTooltips(this);
  }

  private setColorMode(colorMode: 'dark' | 'light') {
    const storageEvent = new StorageEvent('storage', {
      bubbles: true,
      composed: true,
      key: 'colorMode',
      newValue: colorMode,
    });
    localStorage.setItem('colorMode', colorMode);
    this.dispatchEvent(storageEvent);
    this.requestUpdate();
  }

  public render() {
    return html`
      <!-- Color mode selector -->
      ${(localStorage.colorMode || 'dark') == 'dark'
        ? html`
            <a
              role="button"
              class="hstack fs-3"
              data-bs-title="Light mode"
              data-bs-toggle="tooltip"
              @click="${() => {
                this.setColorMode('light');
              }}"
            >
              ${sunIcon}
            </a>
          `
        : html`
            <a
              role="button"
              class="hstack fs-3"
              data-bs-title="Dark mode"
              data-bs-toggle="tooltip"
              @click="${() => {
                this.setColorMode('dark');
              }}"
            >
              ${moonIcon}
            </a>
          `}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'theme-switch': ThemeSwitch;
  }
}
