import {StatusRenderer, Task, TaskStatus} from '@lit/task';
import {Paginated} from '../api-client/base';
import {ReactiveControllerHost} from 'lit';

export class CollectionController<T> {
  public pages: Task<[], Paginated<{data: T[]}>>[];

  private constructor(
    public host: ReactiveControllerHost,
    private loader: () => Promise<Paginated<{data: T[]}>>
  ) {
    this.host = host;
    this.pages = [new Task<[], Paginated<{data: T[]}>>(host, loader)];
  }

  public static new<T>(
    host: ReactiveControllerHost,
    loader: () => Promise<Paginated<{data: T[]}>>
  ) {
    return new CollectionController<T>(host, loader);
  }

  public get lastPage() {
    return this.pages[this.pages.length - 1];
  }

  public async run() {
    this.pages = [new Task<[], Paginated<{data: T[]}>>(this.host, this.loader)];
    await this.lastPage.run();
  }

  public get more() {
    return !this.lastPage.value?.next
      ? null
      : async () => {
          this.pages.push(
            new Task<[], Paginated<{data: T[]}>>(
              this.host,
              this.lastPage.value!.next!
            )
          );
          await this.lastPage.run();
        };
  }

  public get data(): T[] {
    return this.pages.flatMap(({value}) => value?.response?.data || []);
  }

  public get isLoading() {
    return this.pages.some((task) => task.status === TaskStatus.PENDING);
  }

  public render(renderFn: StatusRenderer<T[]>) {
    if (this.pages.every((task) => task.status === TaskStatus.COMPLETE)) {
      return renderFn.complete?.(this.data);
    } else if (this.isLoading) {
      return renderFn.pending?.();
    }
    return this.lastPage.render({
      error: renderFn.error,
      initial: renderFn.initial,
    });
  }
}
