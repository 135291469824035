export enum DiagnosticSeverity {
  Error,
  Warning,
}

export interface Diagnostic {
  message: string;
  severity: DiagnosticSeverity;
  line?: number;
}

export const fromRawWarnings = (rawWarnings: string): Diagnostic[] => {
  return fromRawDiagnostic(rawWarnings, DiagnosticSeverity.Warning);
};

export const fromRawErrors = (rawErrors: string): Diagnostic[] => {
  return fromRawDiagnostic(rawErrors, DiagnosticSeverity.Error);
};

const CODE_PROBLEM_REGEXP = /^Line (?<line>\d+):\s*(?<message>.+)$/;
const fromRawDiagnostic = (
  rawDiagnostics: string,
  severity: DiagnosticSeverity
): Diagnostic[] => {
  if (!rawDiagnostics) return [];
  return rawDiagnostics.split('\n').map((rawDiagnostic) => {
    rawDiagnostic = rawDiagnostic.trim();
    const codeProblemMatch = rawDiagnostic.match(CODE_PROBLEM_REGEXP) as {
      groups: {
        line: string;
        message: string;
      };
    } | null;
    if (!codeProblemMatch) {
      return {
        message: rawDiagnostic,
        severity,
      };
    }
    return {
      message: codeProblemMatch.groups.message,
      line: parseInt(codeProblemMatch.groups.line),
      severity,
    };
  });
};
