import vt from './modules/vt/all.json';

const accessModuleProperty = (path: string[], submodule: any) =>
  path.reduce((prev, curr) => prev[curr], submodule);

export const assertVtModulePropertyValueExpression = (
  path: string,
  value: string,
  indentationLevel = 0,
  spacesPerIndentationLevel: 2 | 4 = 2
) => {
  const pathParts = path.split('.');
  const [parentModuleName, ...restPath] = pathParts;
  const realValue = getRealValue(pathParts, value);
  return assertPropertyValueExpression(
    restPath,
    realValue.replace(/\\/g, '\\\\').replace(/\$/g, '\\$'),
    indentationLevel,
    spacesPerIndentationLevel,
    vt,
    parentModuleName
  );
};

const assertPropertyValueExpression = (
  path: string[],
  value: string,
  indentationLevel: number,
  spacesPerIndentationLevel: 2 | 4,
  parentSubmoduleRef: any,
  parentSubmoduleRefVarname: string
): string => {
  const identation = ' '.repeat(indentationLevel * spacesPerIndentationLevel);

  if (!path.length) {
    return `${identation}${parentSubmoduleRefVarname} == ${value}`;
  }

  const nextLoopOrMapIndex = path.findIndex((_, index) => {
    const moduleProperty = accessModuleProperty(
      path.slice(0, index + 1),
      parentSubmoduleRef
    );
    return moduleProperty instanceof Array || moduleProperty === 'dictionary';
  });

  if (nextLoopOrMapIndex === -1)
    return `${identation}${parentSubmoduleRefVarname}.${path.join(
      '.'
    )} == ${value}`;

  const moduleProperty = accessModuleProperty(
    path.slice(0, nextLoopOrMapIndex + 1),
    parentSubmoduleRef
  );

  if (moduleProperty instanceof Array) {
    const modulePropertyPath = path.slice(0, nextLoopOrMapIndex + 1);
    const modulePropertyVar = `${parentSubmoduleRefVarname}_${modulePropertyPath.join(
      '_'
    )}`;
    const modulePropertyRef = `${parentSubmoduleRefVarname}.${modulePropertyPath.join(
      '.'
    )}`;
    return `${identation}for any ${modulePropertyVar} in ${modulePropertyRef}: (
${assertPropertyValueExpression(
  path.slice(nextLoopOrMapIndex + 2),
  value,
  indentationLevel + 1,
  spacesPerIndentationLevel,
  moduleProperty[0],
  modulePropertyVar
)}
${identation})`;
  }
  // Here, the property found is a dictionary
  const modulePropertyPath = path.slice(0, nextLoopOrMapIndex + 1);
  const modulePropertyRef = modulePropertyPath.join('.');
  const mapKey = path[nextLoopOrMapIndex + 1];
  return `${identation}${parentSubmoduleRefVarname}.${modulePropertyRef}["${mapKey}"] == ${value}`;
};

const getRealValue = (path: string[], value: string) => {
  const enumKey = value.split('"').join('');
  if (path.indexOf('metadata') !== -1 && path.indexOf('file_type') !== -1) {
    return `vt.FileType.${enumKey}`;
  }

  if (
    path.indexOf('downloaded_file') !== -1 &&
    path.indexOf('file_type') !== -1
  ) {
    return `vt.FileType.${enumKey}`;
  }

  if (
    path.indexOf('communicating_file') !== -1 &&
    path.indexOf('file_type') !== -1
  ) {
    return `vt.FileType.${enumKey}`;
  }

  if (
    path.indexOf('embedded_resources') !== -1 &&
    path.indexOf('file_type') !== -1
  ) {
    return `vt.FileType.${enumKey}`;
  }

  if (path.indexOf('files_dropped') !== -1 && path.indexOf('type') !== -1) {
    return `vt.FileType.${enumKey}`;
  }

  if (path.indexOf('behaviour') !== -1 && path.indexOf('trait') !== -1) {
    return `vt.BehaviourTrait.${enumKey}`;
  }

  if (
    path.indexOf('http_conversations') !== -1 &&
    path.indexOf('request_method') !== -1
  ) {
    return `vt.Http.Method.${enumKey}`;
  }

  if (
    path.indexOf('http_conversations') !== -1 &&
    path.indexOf('response_body_filetype') !== -1
  ) {
    return `vt.FileType.${enumKey}`;
  }

  if (
    path.indexOf('ip_traffic') !== -1 &&
    path.indexOf('transport_layer_protocol') !== -1
  ) {
    return `vt.Net.Protocol.${enumKey}`;
  }

  if (
    path.indexOf('smtp_conversations') !== -1 &&
    path.indexOf('attachments') !== -1 &&
    path.indexOf('type') !== -1
  ) {
    return `vt.FileType.${enumKey}`;
  }

  if (
    path.indexOf('ids_alerts') !== -1 &&
    path.indexOf('alert_severity') !== -1
  ) {
    return `vt.AlertSeverity.${enumKey}`;
  }

  if (
    path.indexOf('ids_alerts') !== -1 &&
    path.indexOf('alert_context') !== -1 &&
    path.indexOf('protocol') !== -1
  ) {
    return `vt.TransportLayerProtocol.${enumKey}`;
  }

  if (
    path.indexOf('sigma_analysis_results') !== -1 &&
    path.indexOf('rule_level') !== -1
  ) {
    return `vt.SigmaRule.Level.${enumKey}`;
  }

  if (
    path.indexOf('mitre_attack_techniques') !== -1 &&
    path.indexOf('severity') !== -1
  ) {
    return `vt.ImpactSeverity.${enumKey}`;
  }

  return value;
};
