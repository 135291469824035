import {Task, TaskStatus} from '@lit/task';
import {ReactiveControllerHost} from 'lit';
import {VtApiError} from '../../api-client/error';
import {ApiClient} from '../../api-client/index';
import {
  EDITABLE_RULESET_ATTRIBUTES,
  EditableRulesetAttributes,
  RulesetAttributes,
  RulesetTargetKind,
} from '../../api-client/rulesets/types';
import {
  APIObjectResponse,
  ObjectDescriptor,
  TypeToAttributes,
} from '../../api-client/service';
import {Settings} from '../../components/livehunt-settings/livehunt-settings';
import {Router} from '../../context/router/utils';
import {getRuleset} from '../../domain/rulesets';
import {ShowToastRequested} from '../events';
import {
  Diagnostic,
  fromRawErrors,
  fromRawWarnings,
} from '../../components/editor-console/diagnostics';
import {
  getDefaultURLRules,
  getDefaultDomainRules,
  getDefaultIPAddressRules,
  getDefaultRules,
  getDefaultRulesetName,
  getDefaultRulesetNotificationEmails,
} from '../../context/defaults';

export interface RulesetController {
  readonly id?: string;
  readonly name?: string;
  readonly modificationDate?: number;
  readonly owner?: ObjectDescriptor<'user' | 'group'>;
  readonly userCanEdit: boolean;
  readonly kind?: RulesetTargetKind;
  readonly settings?: Settings;
  readonly rules: string;
  readonly editableAttributes?: EditableRulesetAttributes;
  readonly unsavedDelta: Partial<EditableRulesetAttributes>;
  readonly isSaving: boolean;
  readonly isLoadingRuleset: boolean;
  readonly hasUnsavedChanges: boolean;
  saveRuleset(attr: Partial<EditableRulesetAttributes>): Promise<void>;
  prepareAttributesUpdate(changes: Partial<EditableRulesetAttributes>): void;
}

export type RulesetControllerConsumer = ReactiveControllerHost &
  EventTarget & {
    router: Router;
    setController: (controller: RulesetController) => void;
    reportDiagnostics: (diagnostics: Diagnostic[]) => void;
  };

const computeUnsavedAttributes = (
  saved: Partial<EditableRulesetAttributes>,
  current: Partial<EditableRulesetAttributes>
) => {
  return [...EDITABLE_RULESET_ATTRIBUTES]
    .filter((attrKey) => {
      if (!(attrKey in current)) return false;
      if (attrKey === 'notification_emails') {
        return (
          saved.notification_emails?.length !==
            current.notification_emails?.length ||
          saved.notification_emails?.some(
            (email) => !current.notification_emails?.includes(email)
          )
        );
      }
      return saved[attrKey] !== current?.[attrKey];
    })
    .reduce(
      (attrs, attrKey) => ({
        ...attrs,
        [attrKey]: current[attrKey],
      }),
      {} as Partial<EditableRulesetAttributes>
    );
};

export class NewRulesetController implements RulesetController {
  public readonly name = getDefaultRulesetName();
  private readonly notificationEmails = getDefaultRulesetNotificationEmails();
  public readonly isLoadingRuleset = false;
  public readonly userCanEdit = true;
  private unsavedAttributesDelta: Partial<EditableRulesetAttributes> = {};

  private readonly saveRulesetTask = new Task(
    this.host,
    ([attributes]: [Partial<EditableRulesetAttributes>]) =>
      this.apiClient.rulesets
        .create(attributes, ['owner'] as const)
        .catch((e: Error) => e)
  );

  private constructor(
    private readonly host: RulesetControllerConsumer,
    private readonly apiClient: ApiClient,
    public readonly kind: RulesetTargetKind,
    private readonly currentUserId: string
  ) {}

  public static new(
    host: RulesetControllerConsumer,
    apiClient: ApiClient,
    kind: RulesetTargetKind,
    currentUserId: string
  ) {
    return new NewRulesetController(host, apiClient, kind, currentUserId);
  }

  public get isSaving() {
    return this.saveRulesetTask.status === TaskStatus.PENDING;
  }

  public get rules() {
    switch (this.kind) {
      case 'url':
        return getDefaultURLRules();

      case 'domain':
        return getDefaultDomainRules();

      case 'ip_address':
        return getDefaultIPAddressRules();

      default:
        // The ruleset matches against files.
        return getDefaultRules();
    }
  }

  public get settings() {
    return {
      rules: this.rules,
      limit: 100,
      enabled: true,
      notification_emails: this.notificationEmails,
    };
  }

  public get editableAttributes() {
    return {
      name: this.name,
      match_object_type: this.kind,
      ...this.settings,
    };
  }

  public get unsavedDelta() {
    return {
      ...this.editableAttributes,
      ...this.unsavedAttributesDelta,
    };
  }

  public get hasUnsavedChanges() {
    return Object.keys(this.unsavedAttributesDelta).length > 0;
  }

  public async saveRuleset(attributes: Partial<EditableRulesetAttributes>) {
    await this.saveRulesetTask.run([attributes]);
    const res = this.saveRulesetTask.value!;
    this.host.reportDiagnostics([]);
    if (res instanceof Error) {
      if (res instanceof VtApiError && res.status === 400) {
        this.host.reportDiagnostics(fromRawErrors(res.message));
      }
      throw res;
    } else {
      this.host.reportDiagnostics(fromRawWarnings(res.meta?.warnings || ''));
      this.host.setController(
        ExistingRulesetController.fromResolvedTask(
          this.host,
          this.apiClient,
          res.data.id,
          this.currentUserId,
          {
            value: res,
            status: TaskStatus.COMPLETE,
          }
        )
      );
      this.host.router.updateUrl(`/livehunt/${res.data.id}`);
      this.host.requestUpdate();
    }
  }

  public prepareAttributesUpdate(changes: Partial<EditableRulesetAttributes>) {
    const savedAttributes = this.editableAttributes;
    const unsavedAttributes: Partial<EditableRulesetAttributes> = {
      ...this.unsavedAttributesDelta,
      ...changes,
    };
    this.unsavedAttributesDelta = computeUnsavedAttributes(
      savedAttributes,
      unsavedAttributes
    );
  }
}

interface RulesetTask {
  value?: APIObjectResponse<
    'hunting_ruleset',
    'owner',
    keyof TypeToAttributes['hunting_ruleset']
  >;
  status: TaskStatus;
}

const errorHandledGetRuleset = (
  host: EventTarget & {router: Router},
  api: ApiClient,
  id: string
) =>
  getRuleset(api, id).catch((e: unknown) => {
    host.dispatchEvent(
      new ShowToastRequested({
        message: VtApiError.getMessage(
          e,
          `There was an error loading the ruleset`
        ),
      })
    );
    host.router.redirect(`/livehunt/new`);
    throw e;
  }) as ReturnType<typeof getRuleset>;

export class ExistingRulesetController implements RulesetController {
  private readonly saveRulesetTask = new Task(
    this.host,
    ([attributes]: [Partial<RulesetAttributes>]) =>
      this.apiClient.rulesets
        .patch(this.rulesetId, attributes, ['owner'] as const)
        .catch((e: Error) => e)
  );

  private readonly ruleset: RulesetTask;
  private readonly isUserEditor = new Task(
    this.host,
    ([currentUserId]: [string]) =>
      this.apiClient.rulesets
        .lookUpRelationship(this.rulesetId, 'editors', currentUserId)
        .then(({data}) => data)
  );
  public unsavedDelta: Partial<EditableRulesetAttributes> = {};

  public get isSaving() {
    return this.saveRulesetTask.status === TaskStatus.PENDING;
  }

  public get isLoadingRuleset() {
    return this.ruleset.status === TaskStatus.PENDING;
  }

  private get attributes() {
    return this.ruleset.value?.data.attributes;
  }

  public get id() {
    return this.rulesetId;
  }

  public get kind() {
    return this.attributes?.match_object_type;
  }

  public get settings(): Settings | undefined {
    if (!this.attributes) return;
    const {limit, notification_emails, enabled} = this.attributes;
    return {limit, notification_emails, enabled};
  }

  public get rules(): string {
    return this.attributes?.rules || '';
  }

  public get name() {
    return this.attributes?.name;
  }

  public get modificationDate() {
    return this.attributes?.modification_date;
  }

  public get owner() {
    return this.ruleset.value?.data.relationships.owner.data;
  }

  public get userCanEdit() {
    return (
      this.owner?.id === this.currentUserId ||
      !!this.isUserEditor.render({
        complete: (value) => value,
        pending: () => true,
        initial: () => true,
        error: () => false,
      })
    );
  }

  public get editableAttributes() {
    if (!this.attributes) return;
    const {
      name,
      limit,
      notification_emails,
      enabled,
      rules,
      match_object_type,
    } = this.attributes;
    return {
      name,
      limit,
      notification_emails,
      enabled,
      rules,
      match_object_type,
    };
  }

  public get hasUnsavedChanges() {
    return Object.keys(this.unsavedDelta).length > 0;
  }

  private constructor(
    private readonly host: RulesetControllerConsumer,
    private readonly apiClient: ApiClient,
    private readonly rulesetId: string,
    private readonly currentUserId: string,
    rulesetTask?: RulesetTask
  ) {
    if (currentUserId) {
      this.isUserEditor.run([currentUserId]);
    }
    this.ruleset =
      rulesetTask ??
      new Task(
        this.host,
        ([api, id]: [ApiClient, string]) =>
          errorHandledGetRuleset(this.host, api, id),
        () => [this.apiClient, this.rulesetId] as [ApiClient, string]
      );
  }

  public static new(
    host: RulesetControllerConsumer,
    apiClient: ApiClient,
    rulesetId: string,
    currentUserId: string
  ) {
    return new ExistingRulesetController(
      host,
      apiClient,
      rulesetId,
      currentUserId,
      undefined
    );
  }

  public static fromResolvedTask(
    host: RulesetControllerConsumer,
    apiClient: ApiClient,
    rulesetId: string,
    currentUserId: string,
    rulesetTask: RulesetTask
  ) {
    return new ExistingRulesetController(
      host,
      apiClient,
      rulesetId,
      currentUserId,
      rulesetTask
    );
  }

  public async saveRuleset(attributes: Partial<EditableRulesetAttributes>) {
    await this.saveRulesetTask.run([attributes]);
    const res = this.saveRulesetTask.value!;
    this.host.reportDiagnostics([]);
    if (res instanceof Error) {
      if (res instanceof VtApiError && res.status === 400) {
        this.host.reportDiagnostics(fromRawErrors(res.message));
      }
      throw res;
    } else {
      this.host.reportDiagnostics(fromRawWarnings(res.meta?.warnings || ''));
      this.host.setController(
        ExistingRulesetController.fromResolvedTask(
          this.host,
          this.apiClient,
          res.data.id,
          this.currentUserId,
          {
            value: res,
            status: TaskStatus.COMPLETE,
          }
        )
      );
      this.host.requestUpdate();
    }
  }

  public prepareAttributesUpdate(changes: Partial<EditableRulesetAttributes>) {
    const savedAttributes = this.editableAttributes;
    const unsavedAttributes: Partial<EditableRulesetAttributes> = {
      ...this.unsavedDelta,
      ...changes,
    };
    this.unsavedDelta = savedAttributes
      ? computeUnsavedAttributes(savedAttributes, unsavedAttributes)
      : {};
  }
}
