import {LitElement, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {retrohuntIcon} from '../../icons';
import {
  RulesetEditableAttributesChanged,
  SaveRulesetRequested,
} from '../../view/events';
import {setDefaultRules} from '../../context/defaults';

export interface Settings {
  rules?: string;
  limit: number;
  enabled: boolean;
  notification_emails: string[];
}

const fromFormProps = (props: Record<string, string>): Settings => {
  const emailsInput = props.notification_emails;
  return {
    enabled: !!props.enabled,
    limit: parseInt(props.limit),
    notification_emails: emailsInput ? emailsInput.split('\n') : [],
  };
};

@customElement('livehunt-settings')
export class LivehuntSettings extends LitElement {
  @property({type: String})
  public rulesetId?: string;

  @property({type: String})
  public rulesetKind: RulesetTargetKind = 'file';

  @property({type: Object})
  public settings?: Settings;

  @property({type: Boolean})
  public saving = false;

  @property({type: String})
  public rules = '';

  @property({type: Boolean})
  public readonly = false;

  private formSettings?: Settings;

  public createRenderRoot() {
    return this;
  }

  public render() {
    return html`
      <form
        class="vstack gap-4 p-3"
        @change="${this.settingsChanged}"
        @submit="${this.handleSubmit}"
      >
        <h6 class="m-0 p-0 me-auto">Settings</h6>

        <div class="form-check form-switch">
          <input
            name="enabled"
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="rulesetStatusSwitch"
            ?checked=${this.settings?.enabled}
            ?disabled="${this.readonly}"
          />
          <label class="form-check-label" for="rulesetStatusSwitch">
            Ruleset active
          </label>
        </div>

        <div>
          <label class="form-label">Daily notifications limit</label>
          <input
            name="limit"
            class="form-control form-control-sm"
            value="${this.settings?.limit || 100}"
            required
            min="1"
            max="1000"
            ?readonly="${this.readonly}"
          />
        </div>

        <div>
          <label class="form-label">Notify by email</label>
          <textarea
            name="notification_emails"
            rows="3"
            class="form-control form-control-sm"
            ?readonly="${this.readonly}"
          >
${(this.settings?.notification_emails || []).join('\n')}</textarea
          >
          <div class="form-text">Write one email address per line</div>
        </div>

        ${when(
          this.rulesetKind == 'file',
          () => html`
            <div class="vstack gap-1">
              <label class="form-label p-0">Create Retrohunt job</label>
              <p class="p-0">
                You can use this YARA ruleset to launch a hunting job back in
                time against files uploaded to VirusTotal in the past.
              </p>
              <a
                id="runAsRetrohuntLink"
                role="button"
                class="hstack gap-2 link-primary fw-bold text-decoration-underline"
                @click="${this.setDefaultRules}"
                href="${import.meta.env.BASE_URL + 'retrohunt/new'}"
                target="_blank"
              >
                <span class="fs-4 hstack">${retrohuntIcon}</span>
                Run as Retrohunt
              </a>
            </div>
          `
        )}
      </form>
    `;
  }

  private settingsChanged(e: Event) {
    const form = e.currentTarget as HTMLFormElement;
    const formProps = Object.fromEntries(new FormData(form)) as Record<
      string,
      string
    >;
    this.formSettings = fromFormProps(formProps);
    this.dispatchEvent(new RulesetEditableAttributesChanged(this.formSettings));
  }

  private handleSubmit(e: SubmitEvent) {
    e.preventDefault();
    if (this.formSettings) {
      this.dispatchEvent(new SaveRulesetRequested());
    }
  }

  private setDefaultRules() {
    setDefaultRules(this.rules);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'livehunt-settings': LivehuntSettings;
  }
}
