import {LitElement, html, nothing} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {hasUnsavedChangesContext} from '../../context/app';
import {consume} from '@lit/context';
@customElement('unsaved-changes-indicator')
export class UnsavedChangesIndicator extends LitElement {
  @consume({context: hasUnsavedChangesContext, subscribe: true})
  @property({attribute: false})
  public hasUnsavedChanges!: boolean;

  protected createRenderRoot() {
    return this;
  }

  public render() {
    if (!this.hasUnsavedChanges) return nothing;
    return html`<span class="badge rounded-pill bg-info"
      >Unsaved changes</span
    >`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'unsaved-changes-indicator': UnsavedChangesIndicator;
  }
}
