import {LitElement, PropertyValues, html, nothing} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {live} from 'lit/directives/live.js';
import {when} from 'lit/directives/when.js';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {ObjectDescriptor} from '../../api-client/service';
import {CurrentUser} from '../../context/auth';
import {yaraIcon} from '../../icons';
import {initializeTooltips, unixtimeToDatetime} from '../../utils';
import {
  RulesetEditableAttributesChanged,
  SaveRulesetRequested,
} from '../../view/events';
import '../sharing-modal/sharing-modal';
import {SharingModal} from '../sharing-modal/sharing-modal';
import '../unsaved-changes-indicator/unsaved-changes-indicator';

@customElement('livehunt-navbar')
export class LivehuntNavbar extends LitElement {
  @property({type: String})
  public rulesetName?: string = 'Ruleset name here';

  @property({type: String})
  public rulesetKind: RulesetTargetKind = 'file';

  @property({type: Number})
  public readonly modificationDate?: number;

  @property({type: Object})
  public readonly user!: CurrentUser;

  @property({type: Object})
  public readonly owner?: ObjectDescriptor<'user' | 'group'>;

  @property({type: String})
  public rulesetId?: string;

  @property({type: Boolean})
  public saving = false;

  @property({type: Boolean})
  public readonly = false;

  @query('#rulesetNameInput')
  private rulesetNameInput!: HTMLInputElement;

  @query('#fakeInputContent')
  private fakeInputContent!: HTMLSpanElement;

  public createRenderRoot() {
    return this;
  }

  public updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    initializeTooltips(this);
    this.adjustWidthBasedOnContent(this.rulesetNameInput);
  }

  public render() {
    return html`
      <style>
        .ruleset-name-input {
          border: 1px solid transparent;
          outline: none;
        }
        .ruleset-name-input:hover {
          border-color: var(--bs-border-color);
        }
        .ruleset-name-input:focus {
          border-color: var(--bs-primary);
        }
        .navbar-brand {
          width: 52.5px;
          transition: width 0.2s ease-in-out;
          overflow: hidden;
        }
        .navbar-brand:hover {
          background-color: var(--bs-tertiary-bg);
          width: 195.4px;
        }
        #fakeInputContent {
          white-space: pre;
        }
      </style>
      <div class="hstack">
        <a
          href="https://www.virustotal.com/gui/hunting/rulesets"
          class="navbar-brand m-0 fs-3 hstack gap-3 link-primary px-3 pe-0"
        >
          <i class="hstack flex-grow-1">${yaraIcon}</i>
          <span class="fs-6 pe-3">Livehunt dashboard</span>
        </a>

        <div class="fw-light hstack gap-2">
          <input
            id="rulesetNameInput"
            type="text"
            class="ruleset-name-input text-body-secondary bg-body fs-5 px-2"
            spellcheck="false"
            @blur="${this.blurHandler}"
            @input="${this.rulesetNameInputHandler}"
            .value="${live(this.rulesetName || '')}"
          />
          <!-- Hidden element to calculate input's content width -->
          <span
            id="fakeInputContent"
            class="invisible position-absolute fs-5 px-2"
            >${this.rulesetName}</span
          >
          ${this.modificationDate && this.owner
            ? html`
                <small class="text-body-secondary">
                  Modified
                  <relative-time
                    tense="past"
                    datetime="${unixtimeToDatetime(
                      this.modificationDate
                    ).toString()}"
                  ></relative-time>
                  by ${this.renderOwnerLink(this.owner)}
                </small>
              `
            : nothing}

          <unsaved-changes-indicator
            class="d-inline-flex"
          ></unsaved-changes-indicator>
        </div>

        <div class="hstack gap-4 ms-auto">
          <span
            class="badge rounded-pill bg-primary-alt text-primary-alt fs-6 ms-auto"
          >
            matching entity: ${this.rulesetKind}
          </span>

          ${when(!this.readonly, () =>
            when(
              this.rulesetId,
              () => html`<button
                class="btn btn-outline-primary btn-sm"
                id="shareRuleModalBtn"
                data-bs-toggle="modal"
                data-bs-target="#sharingModal"
              >
                Share
              </button>`,
              () => html`<div
                data-bs-title="The ruleset must be saved before it can be shared"
                data-bs-toggle="tooltip"
              >
                <button class="btn btn-outline-primary btn-sm" disabled>
                  Share
                </button>
              </div>`
            )
          )}
          ${when(
            !this.readonly,
            () => html`<button
              id="${this.rulesetId ? 'saveRuleBtn' : 'createRuleBtn'}"
              class="btn btn-primary btn-sm"
              ?disabled="${this.saving}"
              @click="${this.saveRuleset}"
            >
              ${this.saving ? 'Saving...' : 'Save rule'}
            </button>`
          )}
        </div>
      </div>

      ${when(this.rulesetId, () =>
        when(
          this.rulesetName && this.modificationDate && this.owner,
          () =>
            SharingModal.template(this.user, this.rulesetId!, {
              modificationDate: this.modificationDate!,
              owner: this.owner!,
            }),
          () => SharingModal.template(this.user, this.rulesetId!)
        )
      )}
    `;
  }

  private adjustWidthBasedOnContent(input: HTMLInputElement) {
    requestAnimationFrame(() => {
      input.style.width = this.fakeInputContent.clientWidth + 5 + 'px';
    });
  }

  private rulesetNameInputHandler(e: InputEvent) {
    this.rulesetName = (e.currentTarget as HTMLInputElement).value;
    this.adjustWidthBasedOnContent(e.currentTarget as HTMLInputElement);
    this.dispatchEvent(
      new RulesetEditableAttributesChanged({name: this.rulesetName})
    );
  }

  /**
   * Sets the name's input with a default value when empty.
   * @param e
   */
  private blurHandler(e: FocusEvent) {
    const input = e.currentTarget as HTMLInputElement;
    const inputValue = (e.currentTarget as HTMLInputElement).value.trim();
    this.rulesetName = inputValue || 'Untitled YARA ruleset';
    this.requestUpdate('rulesetName');
    this.dispatchEvent(
      new RulesetEditableAttributesChanged({name: this.rulesetName})
    );
    this.adjustWidthBasedOnContent(input);
  }

  private renderOwnerLink(owner: ObjectDescriptor<'user' | 'group'>) {
    const {type, id} = owner;
    return html`<a href="https://www.virustotal.com/gui/${type}/${id}">
      ${id}
    </a>`;
  }

  private saveRuleset() {
    this.dispatchEvent(new SaveRulesetRequested());
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'livehunt-navbar': LivehuntNavbar;
  }
}
