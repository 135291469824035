import {
  APIObjectResponse,
  ApiService,
  BasicRequestParams,
  TypeToAttributes,
  TypeToRelationships,
} from '../service';
import {GroupAttributes} from './types';

declare module '../service' {
  interface TypeToAttributes {
    group: GroupAttributes;
  }

  interface TypeToRelationships {
    group: never;
  }
}

export class ApiGroups {
  constructor(private service: ApiService) {}
  public get<
    ATTRS extends keyof TypeToAttributes['group'],
    RELS extends keyof TypeToRelationships['group'] = never
  >(
    id: string,
    abridged?: readonly RELS[],
    additionalHeaders?: HeadersInit,
    params?: BasicRequestParams<ATTRS>
  ): Promise<APIObjectResponse<'group', RELS, ATTRS>> {
    return this.service.get({
      endPoint: `groups/${id}`,
      params: {
        relationships: abridged,
        ...(params || {}),
      },
      headers: additionalHeaders,
    });
  }
}
