import {provide} from '@lit/context';
import {html, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {ApiClient} from '../../api-client/index';
import {ApiService} from '../../api-client/service';
import {apiClientContext} from '../../context/api/index';
import {
  currentUserContext,
  LSUserManager,
  sessionContext,
} from '../../context/auth/index';
import {MonacoFactory, monacoFactoryContext} from '../../context/editor/index';
import type {Monaco, RegisterYaraFunction} from '../../context/editor/types';
import {routerFactoryContext} from '../../context/router/index';
import {createRouter} from '../../context/router/utils';
import '../app-shell/index';
import {Toast} from 'bootstrap';
// eslint-disable-next-line import/no-unresolved
import {registerSW} from 'virtual:pwa-register';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {updateAppContext} from '../../context/app';
import '../../error-reporting';
import {errorHandler} from '../../error-reporting';

const apiClient = new ApiClient(
  new ApiService({
    tool: 'vt-ui-yara-editor',
    apiUrl: import.meta.env.API_URL,
  })
);

const userSession = new LSUserManager(
  () =>
    apiClient.users
      .signIn()
      .then(({id, attributes, context_attributes}: any) => ({
        id: id,
        first_name: attributes.first_name,
        last_name: attributes.last_name,
        privileges: attributes.privileges,
        preferences: attributes.preferences,
        quotas: attributes.quotas,
        audiences: context_attributes?.audiences,
        ga_id: context_attributes?.ga_id,
      })),
  () => apiClient.users.signOut()
);

userSession.onValidated((changed) => {
  if (userSession.currentUser) return;
  console.log(changed ? 'session expired' : 'user not logged in');
  localStorage.setItem('vt-referrer', location.href);
  location.href = import.meta.env.SIGN_IN_URL;
});

userSession.validate().catch(() => {});

if (errorHandler && userSession.currentUser?.id) {
  errorHandler.setUser(userSession.currentUser?.id);
}

window.dataLayer = window.dataLayer || [];
if (userSession.currentUser) {
  const ga_id = userSession.currentUser.ga_id;
  if (ga_id) {
    window.dataLayer.push({user_id: ga_id});
  }

  const user_audiences = userSession.currentUser.audiences?.join(' ');
  if (user_audiences) {
    window.dataLayer.push({event: 'audiences', types: user_audiences});
  }
} else {
  window.dataLayer.push({event: 'audiences', types: 'not-registered free'});
}

const monacoDependencies: Promise<
  [
    {
      monaco: Monaco;
    },
    {
      registerYara: RegisterYaraFunction;
    }
  ]
> = Promise.all([
  import('../../context/editor/monaco'),
  import('../../context/editor/language/yara/registerYara'),
]);

/**
 * This component is meant to be a dependency container/provider for the whole application.
 */
@customElement('app-context')
export class AppContext extends LitElement {
  @provide({context: sessionContext})
  @property({attribute: false})
  public userSession = userSession;

  @provide({context: currentUserContext})
  @property({attribute: false})
  public currentUser = userSession.currentUser!;

  @provide({context: monacoFactoryContext})
  @property({attribute: false})
  public monacoFactory: MonacoFactory | undefined;

  @provide({context: apiClientContext})
  @property({attribute: false})
  public apiClient = apiClient;

  @provide({context: updateAppContext})
  @property({attribute: false})
  public updateApp = registerSW({
    onNeedRefresh() {
      const toastLiveExample = document.getElementById('liveToast')!;
      const toast = new Toast(toastLiveExample);
      toast.show();
    },
  });

  @provide({context: routerFactoryContext})
  @property({attribute: false})
  public routerFactory = createRouter;

  public createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    userSession.onValidated(() => {
      this.currentUser = userSession.currentUser!;
    });
    monacoDependencies.then(([{monaco}, {registerYara}]) => {
      this.monacoFactory = (kind: RulesetTargetKind) => {
        registerYara(monaco.languages, kind);
        return monaco;
      };
    });
  }

  public render() {
    return html`<app-shell></app-shell>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'app-context': AppContext;
  }
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
