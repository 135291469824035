import {Task} from '@lit/task';
import {ReactiveControllerHost} from 'lit';

export class Poller<T> {
  private lastCompletedResponse?: Task<[], T>;
  private currentResponse: Task<[], T>;
  private pollingTimeoutHandle?: number;
  private hardTimeoutHandle?: number;
  private stopped = false;

  private constructor(
    public host: ReactiveControllerHost,
    private loader: () => Promise<T>,
    private stopCondition: (arg: T) => boolean,
    initValue?: T
  ) {
    this.host = host;
    if (initValue) {
      this.currentResponse = new Task(host, () => Promise.resolve(initValue));
      this.currentResponse.run();
    } else {
      this.currentResponse = new Task(host, loader);
    }
  }

  public async startPolling() {
    await this.currentResponse.taskComplete;
    // If the initial value fulfills the condition, don't start the polling
    if (this.stopCondition(this.currentResponse.value!)) return;
    this.stopPolling();
    this.stopped = false;
    this.hardTimeoutHandle = window.setTimeout(() => {
      this.stopPolling();
    }, 15 * 60 * 1000);
    this.lastCompletedResponse = this.currentResponse;
    this.poll();
  }

  private poll() {
    this.currentResponse = new Task(this.host, this.loader);
    this.currentResponse.run().then(() => {
      this.lastCompletedResponse = this.currentResponse;
      this.host.requestUpdate();
      if (
        this.stopped ||
        this.stopCondition(this.lastCompletedResponse.value!)
      ) {
        this.stopPolling();
        return;
      }
      this.pollingTimeoutHandle = window.setTimeout(() => {
        this.poll();
      }, 5 * 1000);
    });
  }

  public stopPolling() {
    this.stopped = true;
    this.clearTimeouts();
  }

  private clearTimeouts() {
    window.clearTimeout(this.pollingTimeoutHandle);
    window.clearTimeout(this.hardTimeoutHandle);
  }

  public static new<T>(
    host: ReactiveControllerHost,
    loader: () => Promise<T>,
    stopCondition: (arg: T) => boolean,
    initValue?: T
  ) {
    return new Poller<T>(host, loader, stopCondition, initValue);
  }

  public get data() {
    return this.lastCompletedResponse?.value || this.currentResponse.value;
  }
}
