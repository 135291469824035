import StackdriverErrorReporter from 'stackdriver-errors-js';

export const errorHandler = new StackdriverErrorReporter();
errorHandler.start({
  key: import.meta.env.PUBLIC_VITE_STACKDRIVER_KEY,
  projectId: 'virustotal-ui-error-reporting',
  service: 'yara-editor',
  version: import.meta.env.APP_VERSION,
  disabled: import.meta.env.DEV, // Disabled on dev env
});

window.addEventListener('error', (ErrorEvent) => {
  errorHandler.report(ErrorEvent.error);
});
