import {LitElement, html} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {AddSnippetToRulesetRequested} from '../../view/events';
import yaraTemplates from './yara-templates.json'; // Generated by npm run generate:yara-templates

export enum YaraRuleTemplateCategories {
  'campaing-iocs' = 'Uncover new artifacts and infrastructure related to a known campaign',
  'malware-infra' = 'Unearth malicious infrastructure being used by certain malware toolkits',
  'track-new-infra' = 'Track specific threat actors and their newly created infrastructure',
  'supply-chain' = 'Identify supply chain risk, mainly phishing, against my identity provider or specific companies.',
  'infra-management' = 'Attack surface/infrastructure management.',
  'exposed-assets' = 'False positives related to your internet-exposed assets.',
}

enum EntityType {
  'file' = 'file',
  'domain' = 'domain',
  'url' = 'url',
  'ip_address' = 'ip_address',
}

export interface YaraRuleTemplate {
  name: string;
  category: YaraRuleTemplateCategories;
  ruleset: string;
  kind: EntityType;
}

@customElement('sidebar-templates')
export class SidebarTemplates extends LitElement {
  @property({type: String})
  private kind?: RulesetTargetKind = 'file';

  @state()
  private filter = '';

  public createRenderRoot() {
    return this;
  }

  public get filteredYaraTemplates() {
    return yaraTemplates.filter((yaraTemplate) => {
      return (
        (yaraTemplate.name.toLowerCase().includes(this.filter.toLowerCase()) ||
          yaraTemplate.category
            .toLowerCase()
            .includes(this.filter.toLowerCase())) &&
        this.kind == yaraTemplate.kind
      );
    });
  }

  public render() {
    return html`
      <div class="vstack gap-3 p-3">
        <div class="hstack gap-2">
          <h6 class="m-0 p-0 me-auto">Templates</h6>
        </div>

        <p class="m-0">
          Add different templates to your ruleset and make the most out of Yara.
        </p>

        <input
          type="text"
          class="form-control"
          placeholder="Filter by name, category, description, etc."
          @input="${(e: InputEvent) => {
            this.filter = (e.currentTarget as HTMLInputElement).value;
          }}"
        />

        <!-- Template list -->
        ${this.filteredYaraTemplates.length === 0
          ? html`There is no template with the given filters`
          : html` ${this.filteredYaraTemplates.map(
              (yaraTemplate) => html`
                <div class="card bg-body-tertiary">
                  <div class="card-body">
                    <h6 class="card-title">${yaraTemplate.name}</h6>
                    <div class="hstack gap-2">
                      <span
                        class="badge rounded-pill bg-info-alt text-info-alt"
                      >
                        ${yaraTemplate.category}
                      </span>

                      <a
                        role="button"
                        class="ms-auto link-primary hstack gap-1 add-template"
                        @click="${() =>
                          this.addTemplate(
                            yaraTemplate.ruleset,
                            yaraTemplate.dependencies
                          )}"
                      >
                        <span class="text-decoration-underline"> Add </span>
                        +
                      </a>
                    </div>
                  </div>
                </div>
              `
            )}`}
      </div>
    `;
  }

  private addTemplate(ruleset: string, dependencies: string[]) {
    this.dispatchEvent(new AddSnippetToRulesetRequested(ruleset, dependencies));
  }
}
