import {createContext} from '@lit/context';
import type {
  Privileges,
  PrivilegeState,
  Quotas,
  UserPreferences,
} from '../../api-client/users/types';
import {
  LS_RULES_KEY,
  LS_RULESET_NAME_KEY,
  LS_RULESET_NOTIFICATION_EMAILS_KEY,
} from '../defaults';

export interface CurrentUserData {
  id: string;
  first_name: string;
  last_name: string;
  privileges: Record<string, PrivilegeState>;
  preferences: UserPreferences | undefined;
  quotas: Quotas | undefined;
  audiences: string[] | undefined;
  ga_id: string | undefined; // Google Analytics ID
}

export class CurrentUser {
  public readonly id: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly privileges: Readonly<Privileges>;
  public readonly preferences?: Readonly<UserPreferences> | undefined;
  public readonly quotas?: Readonly<Quotas> | undefined;
  public readonly audiences?: string[] | undefined;
  public readonly ga_id?: string | undefined;

  constructor(data: CurrentUserData) {
    this.id = data.id;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.privileges = data.privileges;
    this.preferences = data.preferences;
    this.quotas = data.quotas;
    this.audiences = data.audiences;
    this.ga_id = data.ga_id;
  }

  public get mainGroupId() {
    return (
      this.privileges['intelligence']?.inherited_from ||
      this.privileges['private']?.inherited_from ||
      null
    );
  }

  public get hasGTIAccess() {
    return this.privileges['google-threat-intel']?.granted;
  }

  public toJSON(): CurrentUserData {
    return {
      id: this.id,
      first_name: this.firstName,
      last_name: this.lastName,
      preferences: this.preferences,
      privileges: this.privileges,
      quotas: this.quotas,
      audiences: this.audiences,
      ga_id: this.ga_id,
    };
  }
}

export type ValidationSubscription = (changed: boolean) => void;
export type Unsubscribe = () => void;

export interface UserSession {
  readonly currentUser: CurrentUser | undefined;
  /**
   * Adds a new subscription to session validation
   */
  readonly onValidated: (cb: ValidationSubscription) => Unsubscribe;
  readonly validate: () => Promise<void>;
  readonly signOut: () => Promise<void>;
}

const IGNORED_PROPERTIES_DIFF: (keyof CurrentUserData)[] = [
  'preferences',
  'quotas',
];

const LS_ITEMS_CRITICAL_KEYS = [
  'vt-referrer',
  'colorMode',
  LS_RULES_KEY,
  LS_RULESET_NAME_KEY,
  LS_RULESET_NOTIFICATION_EMAILS_KEY,
];

/*
 * We intentionally ignore preferences and quotas to check equality because they change with too much frequency and
 * triggers the page reloading when it isn't necessary.
 */
const checkDiff = (
  localUser?: CurrentUser,
  remoteUser?: CurrentUser,
  ignoredProps = IGNORED_PROPERTIES_DIFF
) => {
  const overriddenProperties = ignoredProps.reduce(
    (a, b) => ({
      ...a,
      [b]: null,
    }),
    {} as Record<keyof CurrentUserData, null>
  );

  return (
    JSON.stringify(
      localUser
        ? {
            ...localUser.toJSON(),
            ...overriddenProperties,
          }
        : undefined
    ) !==
    JSON.stringify(
      remoteUser
        ? {
            ...remoteUser.toJSON(),
            ...overriddenProperties,
          }
        : undefined
    )
  );
};

function safeParse(cachedUserJSON: string) {
  try {
    return JSON.parse(cachedUserJSON) as CurrentUserData;
  } catch (e) {
    const err = e instanceof Error ? e : new Error('Parsing error');
    console.error(err);
    return null;
  }
}

const USER_SERVICE_LS_KEY = 'vt-ui-main-current-user';

export class LSUserManager implements UserSession {
  private localUser?: CurrentUser;
  private validationSubscriptions = new Set<ValidationSubscription>();
  public get currentUser() {
    return this.localUser;
  }

  constructor(
    private readonly remoteUser: () => Promise<CurrentUserData>,
    private readonly signOutCall: () => Promise<void>
  ) {
    const cachedUser = localStorage.getItem(USER_SERVICE_LS_KEY) || '""';
    const maybeParsed = safeParse(cachedUser);
    this.localUser = maybeParsed ? new CurrentUser(maybeParsed) : undefined;
  }

  public async validate() {
    const changed = await this.remoteUser()
      .then((data) => new CurrentUser(data))
      .then((remoteUser) => {
        if (checkDiff(this.currentUser, remoteUser)) {
          this.sessionChanged(remoteUser);
          return true;
        } else {
          // We want to update the LS user to keep the preferences up to date, but avoid reloading the page.
          this.updateLS(remoteUser);
          return false;
        }
      })
      .catch(() => {
        if (checkDiff(this.currentUser, undefined)) {
          this.sessionChanged(undefined);
          return true;
        }
        return false;
      });
    this.notifySubscribers(changed);
  }

  private notifySubscribers(changed: boolean) {
    for (const sub of this.validationSubscriptions) {
      sub(changed);
    }
  }

  public onValidated(cb: ValidationSubscription) {
    this.validationSubscriptions.add(cb);
    return () => this.validationSubscriptions.delete(cb);
  }

  public async signOut() {
    await this.signOutCall();
    this.sessionChanged(undefined);
    this.notifySubscribers(true);
  }

  private sessionChanged(newFormattedUser: CurrentUser | undefined): void {
    this.updateLS(newFormattedUser);
  }

  private updateLS(newFormattedUser?: CurrentUser) {
    this.clearStorage(LS_ITEMS_CRITICAL_KEYS);
    this.localUser = newFormattedUser;
    localStorage.removeItem(USER_SERVICE_LS_KEY);
    if (this.currentUser) {
      try {
        localStorage.setItem(
          USER_SERVICE_LS_KEY,
          JSON.stringify(newFormattedUser)
        );
      } catch (e) {
        const err =
          e instanceof Error ? e : new Error('Parsing or storage error');

        if (
          err.name !== 'QUOTA_EXCEEDED_ERR' &&
          err.name !== 'NS_ERROR_DOM_QUOTA_REACHED' &&
          err.name !== 'QuotaExceededError'
        ) {
          throw e;
        }
        // try to make room before setting the user object.
        localStorage.removeItem('vtg-entity-list-search');
        localStorage.setItem(
          USER_SERVICE_LS_KEY,
          JSON.stringify(newFormattedUser)
        );
      }
    }
  }
  /**
   * Clears localStorage while keeping key values
   */
  private clearStorage(keysToSave?: string[]) {
    const savedValues = keysToSave?.map((key) => ({
      key,
      value: localStorage.getItem(key),
    }));
    localStorage.clear();
    savedValues?.forEach(
      (savedValue) =>
        savedValue.value &&
        localStorage.setItem(savedValue.key, savedValue.value)
    );
  }
}

export const currentUserContext = createContext<CurrentUser>('currentUser');

export const sessionContext = createContext<UserSession>('userSession');
