import {consume} from '@lit/context';
import {LitElement, html, nothing} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {VtApiError} from '../../api-client/error';
import {ApiClient} from '../../api-client/index';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import '../../components/code-editor';
import {CodeEditor} from '../../components/code-editor/index';
import {Diagnostic} from '../../components/editor-console/diagnostics';
import '../../components/editor-console/editor-console';
import {EditorConsole} from '../../components/editor-console/editor-console';
import '../../components/livehunt-navbar/livehunt-navbar';
import '../../components/livehunt-settings/livehunt-settings';
import '../../components/theme-switch/theme-switch';
import '../../components/user-menu/user-menu';
import '../../components/resizable-container/resizable-container';
import {apiClientContext} from '../../context/api/index';
import {CurrentUser, currentUserContext} from '../../context/auth/index';
import {manuallyInsertSnippet} from '../../context/editor/language/yara/providers/completion';
import {routerContext} from '../../context/router/index';
import {Router} from '../../context/router/utils';
import {
  AddSnippetToRulesetRequested,
  CodeDiagnosticsReported,
  CodeEditorChanged,
  RulesetEditableAttributesChanged,
  SaveRulesetRequested,
  ShowToastRequested,
  UnsavedStateUpdated,
} from '../events';
import './../../components/side-bar/side-bar';
import {
  ExistingRulesetController,
  NewRulesetController,
  RulesetController,
} from './controllers';

export const QUOTA_KEY = 'intelligence_hunting_rules';

@customElement('livehunt-view')
export class LivehuntView extends LitElement {
  @consume({context: currentUserContext, subscribe: true})
  @property({attribute: false})
  public currentUser!: CurrentUser;

  @consume({context: apiClientContext})
  @property({attribute: false})
  public apiClient!: ApiClient;

  @consume({context: routerContext})
  @property({attribute: false})
  public router!: Router;

  @property({type: String})
  public consoleTestInput = '';

  @property({type: String})
  public codeEditorValue = '';

  @query('editor-console')
  private editorConsole!: EditorConsole;

  @query('code-editor')
  private codeEditor!: CodeEditor;

  // These two properties below are intended to be used only for the controller construction.
  // Use the controller properties in the view logic.
  public readonly rulesetId: string | undefined;
  public readonly matchObjectType: RulesetTargetKind | undefined;

  private rulesetController!: RulesetController;

  public createRenderRoot() {
    return this;
  }

  public connectedCallback(): void {
    super.connectedCallback();
    if (this.rulesetId) {
      this.setController(
        ExistingRulesetController.new(
          this,
          this.apiClient,
          this.rulesetId!,
          this.currentUser.id
        )
      );
    } else {
      this.setController(
        NewRulesetController.new(
          this,
          this.apiClient,
          this.matchObjectType!,
          this.currentUser.id
        )
      );
    }

    this.addEventListener(SaveRulesetRequested.id, this.saveRuleset);
    window.addEventListener('keydown', (e: KeyboardEvent) => {
      // (ctrl | cmd) + s
      if (e.key == 's' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        this.saveRuleset();
      }
    });

    this.addEventListener(
      AddSnippetToRulesetRequested.id,
      this.addCodeToRuleset
    );

    this.addEventListener(
      RulesetEditableAttributesChanged.id,
      (e: RulesetEditableAttributesChanged) => {
        this.rulesetController.prepareAttributesUpdate(e.detail);
        this.dispatchEvent(
          new UnsavedStateUpdated(this.rulesetController.hasUnsavedChanges)
        );
      }
    );

    this.addEventListener(CodeDiagnosticsReported.id, (e) =>
      this.setDiagnostics(e.detail)
    );
  }

  public reportDiagnostics(diagnostics: Diagnostic[]) {
    this.dispatchEvent(new CodeDiagnosticsReported(diagnostics));
  }

  public setDiagnostics(diagnostics: Diagnostic[]) {
    this.editorConsole.setDiagnostics(diagnostics);
    this.codeEditor.setDiagnostics(diagnostics);
  }

  public setController(controller: RulesetController) {
    this.rulesetController = controller;
  }

  private consoleTestInputChangedHandler(e: CustomEvent<string>) {
    this.consoleTestInput = e.detail;
  }

  public render() {
    return html`<main class="vh-100 vstack">
      <nav
        class="navbar bg-body border-bottom container-fluid py-0 pe-3 hstack gap-4"
      >
        <livehunt-navbar
          .rulesetId="${this.rulesetController.id}"
          .rulesetName="${this.rulesetController.name}"
          .rulesetKind="${this.rulesetController.kind || 'file'}"
          .modificationDate="${this.rulesetController.modificationDate}"
          .user="${this.currentUser}"
          .owner="${this.rulesetController.owner}"
          .saving="${this.rulesetController.isSaving}"
          .readonly="${!this.rulesetController.userCanEdit}"
          class="flex-grow-1"
        ></livehunt-navbar>
        <theme-switch></theme-switch>
        <user-menu .user=${this.currentUser}></user-menu>
      </nav>
      <div class="container-fluid flex-grow-1 d-flex bg-body-secondary">
        <div class="workspace w-100 row flex-grow-1 flex-nowrap">
          <side-bar
            class="col-auto row flex-nowrap"
            .rulesetKind="${this.rulesetController.kind || 'file'}"
            .readonly="${!this.rulesetController.userCanEdit}"
            .isLivehunt="${true}"
            .consoleTestInput="${this.consoleTestInput}"
            .savingSettings="${this.rulesetController.isSaving}"
          ></side-bar>
          <main class="col p-0 vstack">
            <!-- Editor -->
            <div class="flex-grow-1 position-relative">
              <code-editor
                .kind="${this.rulesetController.kind || 'file'}"
                .value="${this.rulesetController.rules || ''}"
                .options="${{
                  readOnly: !this.rulesetController.userCanEdit,
                }}"
                @code-editor-changed="${this.onCodeEditorValueChanged}"
              ></code-editor>
              ${this.rulesetController.isLoadingRuleset
                ? html`<div
                    class="position-absolute top-0 left-0 w-100 h-100 hstack gap-3 align-items-center justify-content-center bg-body opacity-75"
                  >
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <h2 class="m-0">
                      Loading ruleset ${this.rulesetController.id}
                    </h2>
                  </div>`
                : nothing}
            </div>

            <!-- Bottom toolbar -->
            <editor-console
              @console-test-input-changed="${this
                .consoleTestInputChangedHandler}"
              .codeEditorValue="${this.codeEditorValue}"
              .kind="${this.rulesetController.kind || 'file'}"
              .usedQuota="${this.currentUser.quotas?.[QUOTA_KEY].used}"
              .totalQuota="${this.currentUser.quotas?.[QUOTA_KEY].allowed}"
              .userEnterpriseGroup="${this.currentUser.mainGroupId!}"
            ></editor-console>
          </main>

          <resizable-container
            .edges="${{left: true}}"
            class="sidebar col-auto border-start"
          >
            <livehunt-settings
              .rulesetId="${this.rulesetId}"
              .rulesetKind="${this.rulesetController.kind || 'file'}"
              .settings="${this.rulesetController.settings}"
              .readonly="${!this.rulesetController.userCanEdit}"
              .rules="${this.codeEditorValue}"
              .saving="${this.rulesetController.isSaving}"
            ></livehunt-settings>
          </resizable-container>
        </div>
      </div>
    </main>`;
  }

  private onCodeEditorValueChanged(e: CodeEditorChanged) {
    this.codeEditorValue = e.detail.value;
    this.rulesetController.prepareAttributesUpdate({
      rules: this.codeEditorValue,
    });
    this.dispatchEvent(
      new UnsavedStateUpdated(this.rulesetController.hasUnsavedChanges)
    );
  }

  private async saveRuleset() {
    const unsavedChanges = this.rulesetController.unsavedDelta;
    if (!Object.keys(unsavedChanges).length) {
      this.dispatchEvent(
        new ShowToastRequested({
          message: `Nothing to save`,
        })
      );
      return;
    }
    try {
      await this.rulesetController.saveRuleset(unsavedChanges);
      this.dispatchEvent(new UnsavedStateUpdated(false));
      this.dispatchEvent(
        new ShowToastRequested({
          message: `Ruleset successfully saved`,
        })
      );
    } catch (e: unknown) {
      if (e instanceof VtApiError && e.status === 400) return;
      this.dispatchEvent(
        new ShowToastRequested({
          message: VtApiError.getMessage(
            e,
            'There was an error saving the ruleset'
          ),
        })
      );
    }
  }

  /**
   * Appends code at the end scrolling to the new content and placing the caret position right
   * after the appended code
   * @param e
   */
  private addCodeToRuleset({
    detail: {snippet, dependencies},
  }: AddSnippetToRulesetRequested) {
    const monacoEditor = this.codeEditor.editor;
    if (monacoEditor) {
      manuallyInsertSnippet(monacoEditor, snippet, dependencies);
    }
  }

  public static existingRulesetTemplate(rulesetId: string | undefined) {
    return html`<livehunt-view .rulesetId="${rulesetId}"></livehunt-view>`;
  }

  public static newRulesetTemplate(kind: RulesetTargetKind) {
    return html`<livehunt-view .matchObjectType="${kind}"></livehunt-view>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'livehunt-view': LivehuntView;
  }
}
