import '@github/relative-time-element';
import {consume} from '@lit/context';
import {Task, TaskStatus} from '@lit/task';
import {Modal} from 'bootstrap';
import {html, LitElement, nothing, ReactiveControllerHost} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import {when} from 'lit/directives/when.js';
import {ApiClient} from '../../api-client/index';
import {APIObject, Links, ObjectDescriptor} from '../../api-client/service';
import {apiClientContext} from '../../context/api/index';
import {CurrentUser} from '../../context/auth/index';
import {CollectionController} from '../../controllers/collection';
import {copyIcon} from '../../icons';
import {capitalize, sendToClipboard} from '../../utils';
import {debounce} from 'lodash';

export type ContributionType = 'editor' | 'viewer';
export type Contributor =
  | ObjectDescriptor<'user'>
  | APIObject<'group', never, 'domain_name'>;
export type ContributorTuple = [Contributor, ContributionType];
export type ContributorDescriptor =
  | ObjectDescriptor<'user'>
  | ObjectDescriptor<'group'>;

export class ShareActionsController {
  public readonly saveChangesTask = new Task(
    this.host,
    async ([newEditors, newViewers, editorsDeletions, viewersDeletion]: [
      ContributorDescriptor[],
      ContributorDescriptor[],
      ContributorDescriptor[],
      ContributorDescriptor[]
    ]) => {
      // Deliberately doing the requests in series.
      if (newEditors.length) {
        await this.apiClient.rulesets.createRelationship(
          this.rulesetId,
          'editors',
          newEditors
        );
      }
      if (newViewers.length) {
        await this.apiClient.rulesets.createRelationship(
          this.rulesetId,
          'viewers',
          newViewers
        );
      }
      if (editorsDeletions.length) {
        await this.apiClient.rulesets.deleteRelationship(
          this.rulesetId,
          'editors',
          editorsDeletions
        );
      }
      if (viewersDeletion.length) {
        await this.apiClient.rulesets.deleteRelationship(
          this.rulesetId,
          'viewers',
          viewersDeletion
        );
      }
    }
  );

  private getShareableEntities(input: string) {
    return this.apiClient.shareableEntities
      .get(input)
      .then((res) =>
        res.data.filter(
          ({id}) => !this.unsavedData.some(([entity]) => id === entity.id)
        )
      );
  }

  public shareableEntitiesLookUpTask = new Task(
    this.host,
    ([input]: [string]) => this.getShareableEntities(input)
  );

  public readonly editors = CollectionController.new(this.host, () =>
    this.apiClient.rulesets
      .listRelationship(this.rulesetId, 'editors', {
        'limit': 10,
        'attributes[group]': ['domain_name'] as const,
      })
      .then((res) => this.apiClient.fromCollResponseToPaginated(res))
  ) as unknown as CollectionController<
    ObjectDescriptor<'user'> | APIObject<'group', never, 'domain_name'>
  >;

  public readonly viewers = CollectionController.new(this.host, () =>
    this.apiClient.rulesets
      .listRelationship(this.rulesetId, 'viewers', {
        'limit': 10,
        'attributes[group]': ['domain_name'] as const,
      })
      .catch(
        () =>
          ({
            data: [],
            links: {
              self: '',
            },
          } as {
            data: [];
            links: Links<unknown>;
          })
      )
      .then((res) => this.apiClient.fromCollResponseToPaginated(res))
  ) as unknown as CollectionController<
    ObjectDescriptor<'user'> | APIObject<'group', never, 'domain_name'>
  >;

  private readonly contributorAdditions = new Map<string, ContributorTuple>();
  private readonly contributorDeletions = new Map<string, ContributorTuple>();

  constructor(
    private readonly host: ReactiveControllerHost,
    private readonly apiClient: ApiClient,
    private readonly rulesetId: string
  ) {
    this.editors.run().then(() => this.loadMoreIfPossible(this.editors));
    this.viewers.run().then(() => this.loadMoreIfPossible(this.viewers));
  }

  private loadMoreIfPossible<T>(collection: CollectionController<T>) {
    collection.more?.().then(() => this.loadMoreIfPossible(collection));
  }

  public get hasChanges() {
    return this.contributorAdditions.size + this.contributorDeletions.size > 0;
  }

  public async addContributor(contributor: ContributorTuple) {
    const [{id}, contributionType] = contributor;
    // Reset state
    this.shareableEntitiesLookUpTask = new Task(
      this.host,
      ([input]: [string]) => this.getShareableEntities(input)
    );
    if (this.contributorDeletions.get(id)?.[1] === contributionType) {
      this.contributorDeletions.delete(id);
      this.host.requestUpdate();
      return;
    }
    this.contributorAdditions.set(id, contributor);
    this.host.requestUpdate();
  }

  public removeContributor(contributor: ContributorTuple) {
    const [{id}] = contributor;
    if (!!this.contributorAdditions.get(id)) {
      this.contributorAdditions.delete(id);
    } else {
      this.contributorDeletions.set(id, contributor);
    }
    this.host.requestUpdate();
  }

  public get unsavedData() {
    return [
      ...this.contributorAdditions.values(),
      ...this.editors.data
        .map((editor) => [editor, 'editor'] as ContributorTuple)
        .filter(
          ([{id}, contributionType]) =>
            this.contributorDeletions.get(id)?.[1] !== contributionType
        ),
      ...this.viewers.data
        .map((viewer) => [viewer, 'viewer'] as ContributorTuple)
        .filter(
          ([{id}, contributionType]) =>
            this.contributorDeletions.get(id)?.[1] !== contributionType
        ),
    ].sort(([{id: id1}], [{id: id2}]) => id1.localeCompare(id2));
  }

  private getDescriptors(
    contributors: ContributorTuple[],
    filterBy: ContributionType
  ) {
    return contributors
      .filter(([, contributionType]) => contributionType === filterBy)
      .map(([contributor]) => ({id: contributor.id, type: contributor.type}));
  }

  public async saveChanges() {
    const additions = [...this.contributorAdditions.values()];
    const deletions = [...this.contributorDeletions.values()];
    const newEditors = this.getDescriptors(additions, 'editor');
    const newViewers = this.getDescriptors(additions, 'viewer');
    const editorsDeletions = this.getDescriptors(
      deletions.filter(
        ([{id}]) => this.contributorAdditions.get(id)?.[1] !== 'viewer'
      ),
      'editor'
    );
    const viewerDeletions = this.getDescriptors(
      deletions.filter(
        ([{id}]) => this.contributorAdditions.get(id)?.[1] !== 'editor'
      ),
      'viewer'
    );
    await this.saveChangesTask.run([
      newEditors,
      newViewers,
      editorsDeletions,
      viewerDeletions,
    ]);
  }
}

@customElement('sharing-modal')
export class SharingModal extends LitElement {
  @consume({context: apiClientContext})
  @property({attribute: false})
  public apiClient!: ApiClient;

  @property({type: Object})
  public user!: CurrentUser;

  @property({type: String})
  public readonly rulesetId!: string;

  @property({type: Number})
  public readonly modificationDate?: number;

  @property({type: Object})
  public readonly owner?: ObjectDescriptor<'user' | 'group'>;

  @state()
  private sharingController!: ShareActionsController;

  @state()
  private isSuggestionsDropdownOpen = false;

  public createRenderRoot() {
    return this;
  }

  public connectedCallback() {
    super.connectedCallback();
    this.sharingController = new ShareActionsController(
      this,
      this.apiClient,
      this.rulesetId
    );
  }

  private async saveChanges() {
    await this.sharingController.saveChanges();
    const modal = Modal.getInstance(
      this.querySelector<HTMLElement>('#sharingModal')!
    );
    modal?.hide();
    // Reset state
    this.sharingController = new ShareActionsController(
      this,
      this.apiClient,
      this.rulesetId
    );
  }

  private searchShareableEntities = debounce(async (e: InputEvent) => {
    const input = (e.target as HTMLInputElement).value;
    await this.sharingController.shareableEntitiesLookUpTask.run([input]);
    this.isSuggestionsDropdownOpen = true;
  }, 500);

  private onInputFormFocus() {
    this.isSuggestionsDropdownOpen = true;
  }

  private onInputFormBlur(e: FocusEvent) {
    if ('suggestionDropdownOption' in (e.relatedTarget as HTMLElement).dataset)
      return;
    this.isSuggestionsDropdownOpen = false;
  }

  private renderOwner(owner: ObjectDescriptor<'user' | 'group'>) {
    const {type, id} = owner;
    return html`<a href="https://www.virustotal.com/gui/${type}/${id}"
      >${id}</a
    >`;
  }

  private renderAvatar(userOrGroup: {type: 'user' | 'group'; id: string}) {
    return html`<img
      src="https://www.virustotal.com/ui/${userOrGroup.type}s/${userOrGroup.id}/avatar"
      alt="Owner avatar"
      class="rounded-circle"
      style="width: 2rem; height: 2rem"
    />`;
  }

  private renderContributorRow(
    e: ObjectDescriptor<'user'> | APIObject<'group', never, 'domain_name'>
  ) {
    return html`<div>${this.renderAvatar(e)}</div>
      ${e.type === 'group'
        ? html`<div class="vstack justify-content-center">
            <div>
              <span class="text-body-tertiary">[group]</span>
              <span class="fw-semibold col">${e.id}</span>
            </div>
            ${when(
              e.attributes?.domain_name,
              () => html`<div>${e.attributes.domain_name}</div>`
            )}
          </div>`
        : html`<div class="fw-semibold col">${e.id}</div>`}`;
  }

  private userIsTheOwner() {
    return this.owner?.id === this.user.id;
  }

  public render() {
    return html`
      <div class="modal" tabindex="-1" id="sharingModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Share ruleset</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body vstack gap-3">
              ${when(
                this.owner,
                () => html`<div class="hstack gap-3">
                  <div>${this.renderAvatar(this.owner!)}</div>
                  <div class="vstack">
                    <div class="fw-semibold">
                      ${when(
                        this.userIsTheOwner(),
                        () => 'You are the owner',
                        () =>
                          html`${this.renderOwner(this.owner!)} is the owner`
                      )}
                    </div>
                    <div>
                      <small class="text-body-tertiary"
                        >Last updated
                        <relative-time
                          .date="${new Date(this.modificationDate! * 1000)}"
                        ></relative-time
                      ></small>
                    </div>
                  </div>
                </div>`
              )}
              <div class="vstack gap-2">
                <label class="h5 fw-semibold" for="#shareToInput"
                  >Search for users and groups</label
                >
                <form class="has-validation">
                  <input
                    class="form-control"
                    name="share-to"
                    placeholder="User or group name"
                    @input="${this.searchShareableEntities}"
                    @focus="${this.onInputFormFocus}"
                    @blur="${this.onInputFormBlur}"
                    autocomplete="off"
                  />
                  ${this.sharingController.shareableEntitiesLookUpTask.render({
                    pending: () => html`<div
                      class="py-1 d-inline-flex align-items-center gap-2"
                    >
                      <small class="form-text m-0">
                        Retrieving users and groups...
                      </small>
                      <span
                        class="spinner-border spinner-border-sm text-primary"
                      >
                      </span>
                    </div>`,
                  })}
                  ${this.isSuggestionsDropdownOpen &&
                  this.sharingController.shareableEntitiesLookUpTask.value
                    ?.length
                    ? html`<div class="position-relative w-100">
                        <div
                          class="dropdown-menu me-2 w-100 show overflow-auto"
                          style="max-height: 200px"
                        >
                          ${this.sharingController.shareableEntitiesLookUpTask.render(
                            {
                              complete: (data) =>
                                data.map(
                                  (entity) =>
                                    html`<button
                                      class="dropdown-item hstack gap-2"
                                      data-suggestion-dropdown-option
                                      @click="${() => {
                                        this.isSuggestionsDropdownOpen = false;
                                        this.querySelector<HTMLInputElement>(
                                          `input[name="share-to"]`
                                        )!.value = '';
                                        this.sharingController.addContributor([
                                          entity,
                                          'editor',
                                        ]);
                                      }}"
                                    >
                                      ${this.renderContributorRow(entity)}
                                    </button>`
                                ),
                            }
                          )}
                          <div></div>
                        </div>
                      </div>`
                    : nothing}
                </form>
              </div>

              ${this.sharingController.editors.render({
                complete: () =>
                  when(
                    this.sharingController.unsavedData.length,
                    () => html`<div>
                      <h5 class="fw-semibold">Shared with</h5>
                      <div>
                        <ul
                          class="p-0 m-0 mt-3 vstack gap-3"
                          style="list-style: none"
                        >
                          ${repeat(
                            this.sharingController.unsavedData,
                            ([e]) => e.id,
                            ([e, contributionType]) => html`<li>
                              <div class="hstack gap-3">
                                ${this.renderContributorRow(e)}
                                <div class="position-relative">
                                  <button
                                    class="btn btn-link dropdown-toggle text-body-secondary"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    ${capitalize(contributionType)}
                                  </button>
                                  <div
                                    class="dropdown-menu dropdown-menu-end me-2"
                                  >
                                    <button
                                      class="dropdown-item"
                                      aria-current="${contributionType ===
                                      'viewer'}"
                                      @click="${() => {
                                        if (contributionType === 'viewer')
                                          return;
                                        this.sharingController.removeContributor(
                                          [e, contributionType]
                                        );
                                        this.sharingController.addContributor([
                                          e,
                                          'viewer',
                                        ]);
                                      }}"
                                    >
                                      Viewer
                                    </button>
                                    <button
                                      class="dropdown-item"
                                      aria-current="${contributionType ===
                                      'editor'}"
                                      @click="${() => {
                                        if (contributionType === 'editor')
                                          return;
                                        this.sharingController.removeContributor(
                                          [e, contributionType]
                                        );
                                        this.sharingController.addContributor([
                                          e,
                                          'editor',
                                        ]);
                                      }}"
                                    >
                                      Editor
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <button
                                      class="dropdown-item"
                                      @click="${() =>
                                        this.sharingController.removeContributor(
                                          [e, contributionType]
                                        )}"
                                    >
                                      Remove access
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>`
                          )}
                        </ul>
                      </div>
                    </div>`
                  ),
              })}
              <div class="vstack gap-2">
                <h5 class="fw-semibold">Share the ruleset</h5>
                <p>
                  This is the link to access the ruleset.
                  <b>Only collaborators can see it.</b>
                </p>
                <div class="input-group">
                  <input
                    class="form-control"
                    readonly
                    .value="${location.href}"
                  />
                  <button
                    class="btn btn-outline-primary fs-4 p-2 py-0"
                    type="button"
                    @click="${() => sendToClipboard(this, location.href)}"
                  >
                    ${copyIcon}
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-footer hstack justify-content-between">
              <button
                type="button"
                class="btn btn-link text-decoration-underline link-primary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                id="shareRulesetBtn"
                class="btn btn-primary"
                ?disabled="${!this.sharingController.hasChanges ||
                this.sharingController.saveChangesTask.status ===
                  TaskStatus.PENDING}"
                ?aria-disabled="${!this.sharingController.hasChanges ||
                this.sharingController.saveChangesTask.status ===
                  TaskStatus.PENDING}"
                @click="${this.saveChanges}"
                style="min-width: 125px"
              >
                ${this.sharingController.saveChangesTask.render({
                  pending: () => html`<div
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>`,
                  error: () => 'Share ruleset',
                  complete: () => 'Share ruleset',
                  initial: () => 'Share ruleset',
                })}
              </button>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  public static template(
    user: CurrentUser,
    rulesetId: string,
    delayed?: {
      modificationDate: number;
      owner: ObjectDescriptor<'user' | 'group'>;
    }
  ) {
    return html`<sharing-modal
      .user="${user}"
      .rulesetId="${rulesetId}"
      .modificationDate="${delayed?.modificationDate}"
      .owner="${delayed?.owner}"
    ></sharing-modal>`;
  }
}
