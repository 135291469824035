import {ApiCrudBaseImpl} from '../base';
import {APIObjectResponse, ApiService} from '../service';
import {RetrohuntJobAttributes, SettingsAttributes} from './types';

declare module '../service' {
  interface TypeToAttributes {
    retrohunt_job: RetrohuntJobAttributes;
  }
  interface TypeToRelationships {
    retrohunt_job: {};
  }
}

export class ApiRetrohuntJobs extends ApiCrudBaseImpl<'retrohunt_job'> {
  protected readonly objectType = 'retrohunt_job';

  constructor(protected service: ApiService) {
    super(service, 'intelligence/retrohunt_jobs');
  }

  public create(attributes: Partial<SettingsAttributes>): Promise<
    APIObjectResponse<'retrohunt_job'> & {
      meta?: {warnings?: string};
    }
  > {
    return this.service.post({
      endPoint: `${this.collectionName}`,
      data: {
        attributes,
        type: 'retrohunt_job',
      },
    });
  }
}
