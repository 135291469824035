import '@github/relative-time-element';
import {LitElement, PropertyValues, html, nothing} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {initializeDropdowns} from '../../utils';
import {SignOutRequested} from '../../view/events';
import {CurrentUser} from './../../context/auth/index';

@customElement('user-menu')
export class UserMenu extends LitElement {
  @property({type: Object})
  public user!: CurrentUser;

  public createRenderRoot() {
    return this;
  }

  public updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    initializeDropdowns(this);
  }

  public render() {
    if (!this.user) return nothing;
    return html`<div>
      <a role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img
          src="https://www.virustotal.com/ui/users/${this.user.id}/avatar"
          alt="User Avatar"
          class="rounded-circle"
          style="width: 2rem; height: 2rem"
        />
      </a>
      <div class="dropdown-menu dropdown-menu-end me-2">
        <a class="dropdown-item" href="${this.guiLink(`user/${this.user.id}`)}">
          Profile
        </a>
        <a
          class="dropdown-item"
          href="${this.guiLink(`user/${this.user.id}/apikey`)}"
        >
          API Key
        </a>
        <a class="dropdown-item" href="${this.guiLink(`settings`)}">
          Settings
        </a>
        ${this.user.mainGroupId
          ? html` <a
              class="dropdown-item"
              href="${this.guiLink(`group/${this.user.mainGroupId}`)}"
            >
              ${this.user.hasGTIAccess
                ? `User Management`
                : `VT Enterprise group`}
            </a>`
          : nothing}
        <a class="dropdown-item" role="button" @click="${this.signOut}">
          Sign Out
        </a>
      </div>
    </div>`;
  }

  private guiLink(path: string) {
    return `https://www.virustotal.com/gui/${path}`;
  }

  private signOut() {
    this.dispatchEvent(new SignOutRequested());
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'user-menu': UserMenu;
  }
}
