import {LitElement, html, nothing} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {Privileges} from '../../api-client/users/types';
import {RetrohuntSettingsChanged} from '../../view/events';

export interface Settings {
  corpus: 'goodware' | 'main';
  notification_email: string;
  rules?: string;
  time_range?: {end?: number; start: number};
}

@customElement('retrohunt-settings')
export class RetrohuntSettings extends LitElement {
  @property({type: Boolean})
  public readonly = false;

  @property({type: Object})
  public settings?: Settings;

  @property({type: Object})
  public userPrivileges?: Privileges;

  @state()
  private showRangeInputs = false;

  @state()
  private showTimeRange = true;

  public createRenderRoot() {
    return this;
  }

  public render() {
    return html`
      <form @change=${this.settingsChanged}>
        <fieldset class="vstack gap-4 p-3" ?disabled="${this.readonly}">
          <div class="vstack gap-2">
            <label class="form-label">Corpus</label>
            <select
              id="selectCorpus"
              name="corpus"
              class="form-select"
              ?disabled="${this.readonly}"
              @change="${this.handleCorpusSelectChange}"
            >
              <option
                ?selected="${this.settings?.corpus === 'main'}"
                value="main"
              >
                Default
              </option>
              <option
                ?selected="${this.settings?.corpus === 'goodware'}"
                value="goodware"
              >
                Goodware
              </option>
            </select>
            <span class="form-text">
              You can choose to run your retrohunt against a corpus of goodware
              files in order to test a rule, this job is fast.
            </span>
          </div>

          ${this.readonly || !this.showTimeRange
            ? nothing
            : html`<div class="vstack gap-2">
                <label class="form-label">Time range</label>
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="range_preset"
                      value="30"
                      @click="${this.handlePresetRangeClicked}"
                    />
                    Last 30 days
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="range_preset"
                      value="90"
                      @click="${this.handlePresetRangeClicked}"
                      ?checked="${this.getTier() == 1}"
                    />
                    Last 90 days
                  </label>
                </div>
                ${when(
                  this.availableRange(180),
                  () => html`
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="range_preset"
                          value="180"
                          @click="${this.handlePresetRangeClicked}"
                          ?checked="${this.getTier() == 2}"
                        />
                        Last 180 days
                      </label>
                    </div>
                  `
                )}
                ${when(
                  this.availableRange(365),
                  () => html`
                    <div class="form-check">
                      <label class="form-check-label">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="range_preset"
                          value="365"
                          @click="${this.handlePresetRangeClicked}"
                          ?checked="${this.getTier() == 3}"
                        />
                        Last 365 days
                      </label>
                    </div>
                  `
                )}

                <div class="form-check">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="range_preset"
                      value="custom"
                      @click="${() => {
                        this.showRangeInputs = true;
                      }}"
                    />
                    Custom range
                  </label>
                </div>
                <label class="form-label" ?hidden="${!this.showRangeInputs}">
                  From:
                  <input
                    name="custom_from"
                    class="form-control"
                    type="date"
                    .max="${this.formatDate(new Date())}"
                    .min="${this.getMinDate()}"
                    value="${this.getMinDate()}"
                  />
                </label>
                <label class="form-label" ?hidden="${!this.showRangeInputs}">
                  To:
                  <input
                    name="custom_to"
                    class="form-control"
                    type="date"
                    .max="${this.formatDate(new Date())}"
                    .min="${this.getMinDate()}"
                    value="${this.formatDate(new Date())}"
                  />
                </label>
                <div class="form-text">
                  Only files submitted to VT in this time range will be scanned
                  by the job
                </div>
              </div>`}

          <div class="vstack gap-2">
            <label for="" class="form-label">Notify</label>
            <div class="hstack gap-2">
              <input
                type="text"
                class="form-control"
                .value="${this.settings?.notification_email || ''}"
                name="notification_email"
              />
            </div>
            <div class="form-text">
              When job finishes an email will be sent to provided email address.
            </div>
          </div>
        </fieldset>
      </form>
    `;
  }

  private handlePresetRangeClicked() {
    this.showRangeInputs = false;
  }

  private rangeFromOffset(days: number) {
    const dayMilliseconds = 24 * 60 * 60 * 1000;
    const minuteMilliseconds = 60 * 1000;
    const fromTimestamp =
      new Date().getTime() - days * dayMilliseconds + minuteMilliseconds; // One minute margin
    return {
      start: this.dateToSecondsTimestamp(new Date(fromTimestamp)),
    };
  }

  private dateToSecondsTimestamp(date: Date) {
    return Math.floor(date.getTime() / 1000);
  }

  private settingsChanged(e: Event) {
    const form = e.currentTarget as HTMLFormElement;
    const formData = new FormData(form);
    const settings = {
      corpus: formData.get('corpus') as 'goodware' | 'main',
      notification_email: (formData.get('notification_email') as string) || '',
      time_range: formData.has('range_preset')
        ? formData.get('range_preset') === 'custom'
          ? {
              start: this.dateToSecondsTimestamp(
                new Date(formData.get('custom_from') as string)
              ),
              end: this.dateToSecondsTimestamp(
                new Date(formData.get('custom_to') as string)
              ),
            }
          : this.rangeFromOffset(
              parseInt(formData.get('range_preset') as string)
            )
        : undefined,
    };
    this.dispatchEvent(new RetrohuntSettingsChanged(settings));
  }

  private getMinDate() {
    let days = 90;
    const tier = this.getTier();

    if (tier == 3) {
      days = 365;
    } else if (tier == 2) {
      days = 180;
    }
    const date = new Date();
    date.setDate(new Date().getDate() - days);
    return this.formatDate(date);
  }

  private formatDate(d: Date): string {
    return d.toISOString().slice(0, 10);
  }

  private getTier() {
    const privileges = this.userPrivileges;

    if (privileges) {
      if (privileges['retrohunt-tier-3']?.granted) {
        return 3;
      } else if (privileges['retrohunt-tier-2']?.granted) {
        return 2;
      } else {
        return 1;
      }
    }
  }

  private availableRange(days: number) {
    const tier = this.getTier();
    if (tier == 1) {
      return days <= 90;
    } else if (tier == 2) {
      return days <= 180;
    } else {
      return true;
    }
  }

  private handleCorpusSelectChange(e: InputEvent) {
    this.showTimeRange =
      ((e.currentTarget as HTMLSelectElement).value as 'goodware' | 'main') ===
      'main';
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'retrohunt-settings': RetrohuntSettings;
  }
}
