import {
  APICollectionResponse,
  APIObjectResponse,
  ApiService,
  BasicRequestParams,
  ListRequestParams,
  ObjectTypes,
  TypeToAttributes,
  TypeToRelationships,
} from './service';

export interface Paginated<T> {
  response: T;
  next?: () => Promise<Paginated<T>>;
}

function preloadMore<T>(
  prev: Paginated<T>,
  pages = 0
): (() => Promise<Paginated<T>>) | undefined {
  const pgs = pages < 0 ? 0 : pages;
  if (!pgs || !prev.next) return prev.next;
  const preloaded = prev.next().then((next) => ({
    response: next.response,
    next: preloadMore(next, pgs - 1),
  }));
  return () =>
    preloaded
      .catch(() => prev.next!())
      .then((lastLoaded) => ({
        response: lastLoaded.response,
        next: preloadMore(lastLoaded, 1),
      }));
}

/**
 * Performs the request of `pages` pages upfront.
 */
export function preload<D>(
  useCase: (bypassCache?: boolean) => Promise<Paginated<D>>,
  pages = 0
): () => Promise<Paginated<D>> {
  const pgs = pages < 0 ? 0 : pages;
  return (bypassCache?: boolean) => {
    const preloadedResponse = useCase(bypassCache).then((fstPage) => ({
      response: fstPage.response,
      next: preloadMore(fstPage, pgs),
    }));
    return preloadedResponse;
  };
}

export abstract class ApiCrudBaseImpl<
  T extends ObjectTypes,
  LIST_CA extends Record<string, any> = never
> {
  protected abstract readonly objectType: T;
  protected constructor(
    protected service: ApiService,
    protected collectionName: string
  ) {}

  /**
   * Requests a list of objects from a collection.
   *
   * @param params Optional parameters such as order, limit etc.
   */
  public list<
    ATTRS extends keyof TypeToAttributes[T],
    RELS extends keyof TypeToRelationships[T] = never
  >(params?: ListRequestParams<ATTRS, RELS>) {
    return this.service.get<APICollectionResponse<T, RELS, ATTRS, LIST_CA>>({
      endPoint: `${this.collectionName}`,
      params: params,
    });
  }

  public more<
    ATTRS extends keyof TypeToAttributes[T],
    RELS extends keyof TypeToRelationships[T]
  >(next: string): Promise<APICollectionResponse<T, RELS, ATTRS>> {
    return this.service.fromLink(next);
  }

  public get<
    ATTRS extends keyof TypeToAttributes[T],
    RELS extends keyof TypeToRelationships[T] = never
  >(
    id: string,
    abridged?: readonly RELS[],
    additionalHeaders?: HeadersInit,
    params?: BasicRequestParams<ATTRS>
  ): Promise<APIObjectResponse<T, RELS, ATTRS>> {
    return this.service.get({
      endPoint: `${this.collectionName}/${id}`,
      params: {
        relationships: abridged,
        ...(params || {}),
      },
      headers: additionalHeaders,
    });
  }

  public delete(
    id?: string,
    params?: BasicRequestParams<never>
  ): Promise<unknown> {
    const endPoint = id ? `${this.collectionName}/${id}` : this.collectionName;

    return this.service.delete({
      endPoint,
      params,
    });
  }

  public patch<R extends keyof TypeToRelationships[T]>(
    id: string,
    attributes: Partial<TypeToAttributes[T]>,
    abridged?: readonly R[]
  ): Promise<APIObjectResponse<T, R, keyof TypeToAttributes[T]>> {
    const data = {
      id: id,
      type: this.objectType,
      attributes,
    };
    return this.service.patch({
      endPoint: `${this.collectionName}/${id}`,
      params: {
        relationships: abridged,
      },
      data,
    });
  }
}
