import {Task, TaskStatus} from '@lit/task';
import {ReactiveControllerHost} from 'lit';
import {VtApiError} from '../../api-client/error';
import {ApiClient} from '../../api-client/index';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {Diagnostic, fromRawErrors, fromRawWarnings} from './diagnostics';

export interface MatchingTestData {
  matches: {ruleName: string; snippet?: string; matchSource?: string}[];
}

export interface FailedTestData {
  error: string;
}

export interface Test<T extends MatchingTestData | FailedTestData> {
  item: string;
  test: Task<[], T>;
}

export class TestSuite {
  public readonly tests: (Test<MatchingTestData> | Test<FailedTestData>)[];

  public get isLoading() {
    return this.tests.some((test) => TestSuite.isTestRunning(test));
  }

  public get completedTests() {
    return this.tests.filter((test) => !TestSuite.isTestRunning(test));
  }

  public get passingTests() {
    return this.completedTests.filter((test) => !TestSuite.isFailedTest(test));
  }

  public static isFailedTest(test: Test<any>): test is Test<FailedTestData> {
    return !TestSuite.isTestRunning(test) && 'error' in test.test.value!;
  }

  public static isTestRunning(test: Test<any>) {
    return test.test.status === TaskStatus.PENDING;
  }

  constructor(
    private readonly host: ReactiveControllerHost & {
      reportDiagnostics(diagnostics: Diagnostic[]): void;
    },
    apiClient: ApiClient,
    items: string[],
    kind: RulesetTargetKind,
    ruleset: string
  ) {
    let syntaxErrors: string;
    let warnings: string;
    this.tests = items.map((item) => {
      const test = new Task(this.host, () =>
        apiClient.rulesets
          .test({item, kind, ruleset})
          .then(({data, meta}) => {
            warnings = meta?.warnings || '';
            return !data.matches.length
              ? {
                  error: `Ruleset doesn't match this entity.`,
                }
              : data;
          })
          .catch((e) => {
            if (e instanceof VtApiError && e.code === 'InvalidRulesetError') {
              syntaxErrors = e.message;
            }
            return {
              error: VtApiError.getMessage(e, 'Something went wrong.'),
            };
          })
      );
      test.run();
      return {
        item,
        test,
      } as Test<MatchingTestData> | Test<FailedTestData>;
    });
    this.host.reportDiagnostics([]);
    Promise.allSettled(this.tests.map(({test}) => test.taskComplete))
      .then(() => [
        ...fromRawErrors(syntaxErrors),
        ...fromRawWarnings(warnings),
      ])
      .then((diagnostics) => {
        this.host.reportDiagnostics(diagnostics);
        return;
      })
      .catch(() => {
        this.host.reportDiagnostics([]);
      });
  }
}
