import {LitElement, PropertyValueMap, TemplateResult, html, nothing} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {diagramIcon, docPenCheckIcon, retrohuntIcon} from '../../icons';
import {initializeTooltips} from '../../utils';
import './sidebar-retrohunt';
import './sidebar-structure';
import './sidebar-templates';
import '../resizable-container/resizable-container';

interface Section {
  name: string;
  icon: TemplateResult<any>;
  tooltip?: string;
}

const DEV_ONLY_SECTIONS: Section[] = [{name: 'retrohunt', icon: retrohuntIcon}];

const READONLY_SECTIONS: Section[] = [];

const NON_READONLY_SECTIONS: Section[] = [
  {
    name: 'templates',
    icon: docPenCheckIcon,
    tooltip: 'Explore rules templates to add into your ruleset',
  },
  {
    name: 'structure',
    icon: diagramIcon,
    tooltip: 'Explore IoC structure to add new components to your ruleset',
  },
];

@customElement('side-bar')
export class SideBar extends LitElement {
  @property({type: String})
  public rulesetKind: RulesetTargetKind = 'file';

  @property({type: Boolean})
  public isLivehunt = false;

  @property({type: String})
  public consoleTestInput = '';

  @property({type: Boolean})
  public readonly = false;

  @property({type: Boolean})
  public savingSettings = false;

  @state()
  /**
   * `undefined` maps to 'closed' side-panel.
   */
  private currentSection: string | undefined = 'templates';

  private get sections() {
    return (this.readonly ? READONLY_SECTIONS : NON_READONLY_SECTIONS).concat(
      import.meta.env.DEV ? DEV_ONLY_SECTIONS : []
    );
  }

  private get sectionButtons() {
    const availableSections = new Set(this.sections.map(({name}) => name));
    return NON_READONLY_SECTIONS.concat(
      import.meta.env.DEV ? DEV_ONLY_SECTIONS : []
    ).map((tab) => ({...tab, disabled: !availableSections.has(tab.name)}));
  }

  protected firstUpdated(): void {
    if (
      !this.sections.some((section) => section.name === this.currentSection)
    ) {
      this.currentSection = this.sections[0]?.name || undefined;
    }
  }

  public willUpdate(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (!this.sections.length) {
      this.currentSection = undefined;
    }
    if (
      changedProperties.has('readonly') &&
      !this.sections.some((section) => section.name === this.currentSection)
    ) {
      this.currentSection = undefined;
    }
  }

  public updated(
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    super.updated(changedProperties);
    initializeTooltips(this);
  }

  public createRenderRoot() {
    return this;
  }

  private renderTabButtons() {
    return html`
      <!-- Main sidebar -->
      <ul class="nav nav-pills nav-flush vstack col-auto border-end p-0">
        ${this.sectionButtons.map((section) => {
          const title =
            section.name.charAt(0).toUpperCase() + section.name.slice(1);
          const tooltipText = section.disabled
            ? `${title}: ${
                this.isLivehunt
                  ? `you don't have permission to edit this file`
                  : `an existing retrohunt cannot be edited`
              }`
            : section.tooltip || title;
          return html` <li
            class="nav-item"
            data-bs-title="${tooltipText}"
            data-bs-toggle="tooltip"
          >
            <a
              id="sidebar-${section.name}"
              role="button"
              class="nav-link p-3 hstack justify-content-center fs-3 ${classMap(
                {
                  'link-tertiary': this.currentSection != section.name,
                  'text-body': this.currentSection == section.name,
                  'disabled': section.disabled,
                }
              )}"
              @click="${() => this.setCurrentSection(section.name)}"
              ?disabled="${section.disabled}"
              aria-disabled="${section.disabled}"
            >
              ${section.icon}
            </a>
          </li>`;
        })}
      </ul>
    `;
  }

  public render() {
    if (this.sectionButtons.length === 0) {
      return nothing;
    }

    return html`
      ${this.renderTabButtons()}

      <!-- Section sidebar -->
      <resizable-container
        class="sidebar border-end vstack gap-3 p-0"
        ?hidden="${!this.currentSection}"
      >
        ${this.sections.map(({name}) => {
          switch (name) {
            case 'structure':
              return html`<sidebar-structure
                id="sidebarStructure"
                ?hidden="${this.currentSection !== 'structure'}"
                .kind="${this.rulesetKind}"
                .entityIds="${this.consoleTestInput
                  .split(/\s/)
                  .filter((t) => !!t)}"
              ></sidebar-structure>`;
            case 'templates':
              return html`<sidebar-templates
                id="sidebarTemplates"
                ?hidden="${this.currentSection !== 'templates'}"
                .kind="${this.rulesetKind}"
              ></sidebar-templates>`;
            case 'retrohunt':
              return html`<sidebar-retrohunt
                ?hidden="${this.currentSection !== 'retrohunt'}"
              ></sidebar-retrohunt>`;
          }
        })}
      </resizable-container>
    `;
  }

  private setCurrentSection(section: string) {
    if (this.currentSection === section) {
      this.currentSection = undefined;
      return;
    } else {
      this.currentSection = section;
    }
  }
}
