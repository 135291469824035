import '@alenaksu/json-viewer';
import {
  LitElement,
  PropertyValueMap,
  html,
  nothing,
  ReactiveControllerHost,
} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {
  arrowRotateRightIcon,
  checkCircleIcon,
  spinnerQuarterIcon,
  xmarkCircleIcon,
} from '../../icons';
import {capitalize, initializeTooltips, unixtimeToDatetime} from '../../utils';
import {CollectionController} from '../../controllers/collection';
import {consume} from '@lit/context';
import {apiClientContext} from '../../context/api';
import {ApiClient} from '../../api-client';
import {
  APICollectionResponse,
  APILink,
  APIObject,
  LinksWithNext,
  TypeToAttributes,
} from '../../api-client/service';
import '@github/relative-time-element';
import {Paginated} from '../../api-client/base';
import {Poller} from '../../controllers/poller';
import {RetrohuntJobStatus} from '../../api-client/retrohunt-jobs/types';

const RETROHUNT_JOB_ATTRIBUTES = [
  'creation_date',
  'num_matches',
  'status',
  'progress',
] as const;

const toPaginated = <T, R extends {links?: LinksWithNext<T>}>(
  makeRequest: (link: APILink<T>) => Promise<R>,
  response: R
): Paginated<R> => ({
  response,
  next: response.links?.next
    ? () =>
        makeRequest(response.links!.next!).then((res) =>
          toPaginated(makeRequest, res)
        )
    : undefined,
});

const toPollers = <ATTRS extends keyof TypeToAttributes['retrohunt_job']>(
  host: ReactiveControllerHost,
  apiClient: ApiClient,
  {data, links}: APICollectionResponse<'retrohunt_job', never, ATTRS | 'status'>
) => ({
  links,
  data: data
    .filter(
      (job): job is APIObject<'retrohunt_job', never, ATTRS | 'status'> =>
        'attributes' in job
    )
    .map((job) => {
      const poller = Poller.new(
        host,
        () =>
          apiClient.retrohuntJobs
            .get(
              job.id,
              [],
              {},
              {
                attributes: Object.keys(job.attributes) as (ATTRS | 'status')[],
              }
            )
            .then(({data}) => data),
        (arg) => ['finished', 'aborted'].includes(arg.attributes.status),
        job
      );
      poller.startPolling();
      return poller;
    }),
});

const listJobs = (host: ReactiveControllerHost, apiClient: ApiClient) =>
  apiClient.retrohuntJobs
    .list({
      attributes: RETROHUNT_JOB_ATTRIBUTES,
    })
    .then((res) => {
      return toPaginated(
        (link: Exclude<(typeof res)['links']['next'], undefined>) =>
          apiClient.service
            .createSimpleRequest(
              link.replace(
                /^https:\/\/www.virustotal.com/,
                apiClient.service.apiUrl
              ) as typeof link
            )
            .then((res) => toPollers(host, apiClient, res)),
        toPollers(host, apiClient, res)
      );
    });

@customElement('sidebar-retrohunt')
export class SidebarRetrohunt extends LitElement {
  @consume({context: apiClientContext})
  @property({attribute: false})
  public apiClient!: ApiClient;

  private jobs = CollectionController.new(this, () =>
    listJobs(this, this.apiClient)
  );

  public createRenderRoot() {
    return this;
  }

  public connectedCallback() {
    super.connectedCallback();
    this.loadJobs();
  }

  public disconnectedCallback() {
    this.jobs.data.forEach((p) => p.stopPolling());
    super.disconnectedCallback();
  }

  private loadJobs() {
    this.jobs.data.forEach((p) => p.stopPolling());
    this.jobs.run().then(() => this.loadMoreJobsIfPossible());
  }

  private loadMoreJobsIfPossible() {
    this.jobs.more?.().then(() => this.loadMoreJobsIfPossible());
  }

  public updated(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    super.updated(_changedProperties);

    initializeTooltips(this);
  }

  private renderCompletedIndicator() {
    return html`<span
      class="hstack text-success"
      data-bs-toggle="tooltip"
      data-bs-title="Finished 100%"
    >
      ${checkCircleIcon}
    </span>`;
  }

  private renderAbortedIndicator(status: RetrohuntJobStatus, progress: number) {
    return html`<span
      class="hstack text-danger"
      data-bs-toggle="tooltip"
      data-bs-title="${capitalize(status)} ${progress.toFixed(2)}%"
    >
      ${xmarkCircleIcon}
    </span>`;
  }

  private renderRunningIndicator(status: RetrohuntJobStatus, progress: number) {
    return html`<span
      class="spinner hstack text-warning"
      data-bs-toggle="tooltip"
      data-bs-title="${capitalize(status)} ${progress.toFixed(2)}%"
    >
      ${spinnerQuarterIcon}
      <span class="visually-hidden">Loading...</span>
    </span>`;
  }

  private renderJob(
    job:
      | APIObject<
          'retrohunt_job',
          never,
          (typeof RETROHUNT_JOB_ATTRIBUTES)[number]
        >
      | undefined
  ) {
    if (!job) return nothing;
    return html`<div class="card bg-body-tertiary">
      <div class="card-body">
        <h6 class="card-title hstack gap-2">
          ${job.attributes.status === 'finished'
            ? this.renderCompletedIndicator()
            : ['starting', 'running'].includes(job.attributes.status)
            ? this.renderRunningIndicator(
                job.attributes.status,
                job.attributes.progress
              )
            : ['aborted', 'aborting'].includes(job.attributes.status)
            ? this.renderAbortedIndicator(
                job.attributes.status,
                job.attributes.progress
              )
            : nothing}
          ${job.id}
          <small class="fw-light text-body-secondary"
            ><relative-time
              tense="past"
              datetime="${unixtimeToDatetime(job.attributes.creation_date)}"
            ></relative-time
          ></small>
        </h6>
        <div class="hstack gap-2 justify-content-between">
          <a
            href="https://www.virustotal.com/gui/hunting/retrohunt/matches/${job.id}"
            target="_blank"
            class="text-primary"
            >${job.attributes.num_matches} matches</a
          >
          <a role="button" class="text-primary">Ruleset</a>
        </div>
      </div>
    </div>`;
  }

  public render() {
    return html`<div class="vstack gap-3 p-3">
      <h6 class="m-0 p-0 hstack gap-2 justify-content-between">
        Retrohunt

        <a
          role="button"
          class="hstack fs-5"
          data-bs-title="Reload"
          data-bs-toggle="tooltip"
          @click="${this.loadJobs}"
        >
          ${arrowRotateRightIcon}
        </a>
      </h6>

      <p class="m-0">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      </p>

      <button class="btn btn-primary">New retrohunt job</button>
      ${this.jobs.data.map(({data}) => this.renderJob(data))}
      ${this.jobs.render({
        pending: () => (this.jobs.data.length ? nothing : 'Loading...'),
      })}
    </div>`;
  }
}
