import '@github/relative-time-element';
import interact from 'interactjs';
import {LitElement, PropertyValueMap, html} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('resizable-container')
export class ResizableContainer extends LitElement {
  @property({
    type: Object,
    hasChanged: (a, b) => JSON.stringify(a) !== JSON.stringify(b),
  })
  public edges: Partial<Record<'top' | 'left' | 'bottom' | 'right', boolean>> =
    {right: true};

  @property({type: Number})
  public minHeightPx?: number;

  protected updated(changedProperties: PropertyValueMap<this>) {
    if (changedProperties.has('edges')) {
      this.initializeSidebarResizing();
    }
  }

  public render() {
    return html`<slot></slot>`;
  }

  private initializeSidebarResizing() {
    const classes = ['border-3', 'border-primary'];
    interact(this)
      .resizable({
        edges: this.edges,
        listeners: {
          move: (event) => {
            let {x, y} = event.target.dataset;

            x = (parseFloat(x) || 0) + event.deltaRect.left;
            y = (parseFloat(y) || 0) + event.deltaRect.top;

            if (this.edges.right || this.edges.left) {
              Object.assign(event.target.style, {
                width: `${event.rect.width}px`,
              });
            }
            if (this.edges.bottom || this.edges.top) {
              const height = Math.max(event.rect.height, this.minHeightPx || 0);
              Object.assign(event.target.style, {
                height: `${height}px`,
              });
            }

            event.target.classList.add(...classes);

            Object.assign(event.target.dataset, {x, y});
          },
        },
      })
      .on(['resizeend'], (event) => {
        event.target.classList.remove(...classes);
      });
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'resizable-container': ResizableContainer;
  }
}
