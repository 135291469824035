// File autogenerated, Do not change
// Raw imports
import actorRaw from '@virustotal/vtstrap-icons/src/icons/actor.svg?raw';
import aiRaw from '@virustotal/vtstrap-icons/src/icons/ai.svg?raw';
import androidFullRaw from '@virustotal/vtstrap-icons/src/icons/android-full.svg?raw';
import androidSmallRaw from '@virustotal/vtstrap-icons/src/icons/android-small.svg?raw';
import apiBRaw from '@virustotal/vtstrap-icons/src/icons/api-b.svg?raw';
import apiKeyRaw from '@virustotal/vtstrap-icons/src/icons/api-key.svg?raw';
import apiRaw from '@virustotal/vtstrap-icons/src/icons/api.svg?raw';
import arrowCornersRaw from '@virustotal/vtstrap-icons/src/icons/arrow-corners.svg?raw';
import arrowCurveTopRightRaw from '@virustotal/vtstrap-icons/src/icons/arrow-curve-top-right.svg?raw';
import arrowDown19Raw from '@virustotal/vtstrap-icons/src/icons/arrow-down-1-9.svg?raw';
import arrowDownCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/arrow-down-circle-filled.svg?raw';
import arrowDownCircleRaw from '@virustotal/vtstrap-icons/src/icons/arrow-down-circle.svg?raw';
import arrowDownZARaw from '@virustotal/vtstrap-icons/src/icons/arrow-down-z-a.svg?raw';
import arrowDownRaw from '@virustotal/vtstrap-icons/src/icons/arrow-down.svg?raw';
import arrowLeftRaw from '@virustotal/vtstrap-icons/src/icons/arrow-left.svg?raw';
import arrowPointerRaw from '@virustotal/vtstrap-icons/src/icons/arrow-pointer.svg?raw';
import arrowRightArrowLeftRaw from '@virustotal/vtstrap-icons/src/icons/arrow-right-arrow-left.svg?raw';
import arrowRightFromRectangleRaw from '@virustotal/vtstrap-icons/src/icons/arrow-right-from-rectangle.svg?raw';
import arrowRightToRectangleRaw from '@virustotal/vtstrap-icons/src/icons/arrow-right-to-rectangle.svg?raw';
import arrowRightRaw from '@virustotal/vtstrap-icons/src/icons/arrow-right.svg?raw';
import arrowRotateLeftRaw from '@virustotal/vtstrap-icons/src/icons/arrow-rotate-left.svg?raw';
import arrowRotateRightRaw from '@virustotal/vtstrap-icons/src/icons/arrow-rotate-right.svg?raw';
import arrowTrendingDownRaw from '@virustotal/vtstrap-icons/src/icons/arrow-trending-down.svg?raw';
import arrowTrendingUpRaw from '@virustotal/vtstrap-icons/src/icons/arrow-trending-up.svg?raw';
import arrowTurnDownRightRaw from '@virustotal/vtstrap-icons/src/icons/arrow-turn-down-right.svg?raw';
import arrowTurnRightFromSquareRaw from '@virustotal/vtstrap-icons/src/icons/arrow-turn-right-from-square.svg?raw';
import arrowUpAZRaw from '@virustotal/vtstrap-icons/src/icons/arrow-up-a-z.svg?raw';
import arrowUpCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/arrow-up-circle-filled.svg?raw';
import arrowUpCircleRaw from '@virustotal/vtstrap-icons/src/icons/arrow-up-circle.svg?raw';
import arrowUpDownRaw from '@virustotal/vtstrap-icons/src/icons/arrow-up-down.svg?raw';
import arrowUpRightFromSquareRaw from '@virustotal/vtstrap-icons/src/icons/arrow-up-right-from-square.svg?raw';
import arrowUpRaw from '@virustotal/vtstrap-icons/src/icons/arrow-up.svg?raw';
import arrowsCenterFromCornersRaw from '@virustotal/vtstrap-icons/src/icons/arrows-center-from-corners.svg?raw';
import arrowsDownUpListRaw from '@virustotal/vtstrap-icons/src/icons/arrows-down-up-list.svg?raw';
import arrowsUpDownListRaw from '@virustotal/vtstrap-icons/src/icons/arrows-up-down-list.svg?raw';
import artificialIntelligenceRaw from '@virustotal/vtstrap-icons/src/icons/artificial-intelligence.svg?raw';
import asmRaw from '@virustotal/vtstrap-icons/src/icons/asm.svg?raw';
import atSignRaw from '@virustotal/vtstrap-icons/src/icons/at-sign.svg?raw';
import balanceRaw from '@virustotal/vtstrap-icons/src/icons/balance.svg?raw';
import bankRaw from '@virustotal/vtstrap-icons/src/icons/bank.svg?raw';
import barChartRaw from '@virustotal/vtstrap-icons/src/icons/bar-chart.svg?raw';
import barsFilterRaw from '@virustotal/vtstrap-icons/src/icons/bars-filter.svg?raw';
import barsRaw from '@virustotal/vtstrap-icons/src/icons/bars.svg?raw';
import bellFilledRaw from '@virustotal/vtstrap-icons/src/icons/bell-filled.svg?raw';
import bellSlashRaw from '@virustotal/vtstrap-icons/src/icons/bell-slash.svg?raw';
import bellRaw from '@virustotal/vtstrap-icons/src/icons/bell.svg?raw';
import binRaw from '@virustotal/vtstrap-icons/src/icons/bin.svg?raw';
import bombRaw from '@virustotal/vtstrap-icons/src/icons/bomb.svg?raw';
import bookBookmarkRaw from '@virustotal/vtstrap-icons/src/icons/book-bookmark.svg?raw';
import bookRaw from '@virustotal/vtstrap-icons/src/icons/book.svg?raw';
import bookmarkRaw from '@virustotal/vtstrap-icons/src/icons/bookmark.svg?raw';
import booksRaw from '@virustotal/vtstrap-icons/src/icons/books.svg?raw';
import boxClosed2Raw from '@virustotal/vtstrap-icons/src/icons/box-closed-2.svg?raw';
import boxClosedRaw from '@virustotal/vtstrap-icons/src/icons/box-closed.svg?raw';
import boxOpenRaw from '@virustotal/vtstrap-icons/src/icons/box-open.svg?raw';
import bracketsRaw from '@virustotal/vtstrap-icons/src/icons/brackets.svg?raw';
import briefcaseRaw from '@virustotal/vtstrap-icons/src/icons/briefcase.svg?raw';
import browserArrowUpRaw from '@virustotal/vtstrap-icons/src/icons/browser-arrow-up.svg?raw';
import browserContentRaw from '@virustotal/vtstrap-icons/src/icons/browser-content.svg?raw';
import browserEarthEuropeRaw from '@virustotal/vtstrap-icons/src/icons/browser-earth-europe.svg?raw';
import browserRaw from '@virustotal/vtstrap-icons/src/icons/browser.svg?raw';
import bug2Raw from '@virustotal/vtstrap-icons/src/icons/bug-2.svg?raw';
import bugPlayRaw from '@virustotal/vtstrap-icons/src/icons/bug-play.svg?raw';
import bugTargetRaw from '@virustotal/vtstrap-icons/src/icons/bug-target.svg?raw';
import bugRaw from '@virustotal/vtstrap-icons/src/icons/bug.svg?raw';
import buildingsRaw from '@virustotal/vtstrap-icons/src/icons/buildings.svg?raw';
import busRaw from '@virustotal/vtstrap-icons/src/icons/bus.svg?raw';
import buzzerRaw from '@virustotal/vtstrap-icons/src/icons/buzzer.svg?raw';
import cableRaw from '@virustotal/vtstrap-icons/src/icons/cable.svg?raw';
import calendarRaw from '@virustotal/vtstrap-icons/src/icons/calendar.svg?raw';
import cameraLensRaw from '@virustotal/vtstrap-icons/src/icons/camera-lens.svg?raw';
import candleRaw from '@virustotal/vtstrap-icons/src/icons/candle.svg?raw';
import carRaw from '@virustotal/vtstrap-icons/src/icons/car.svg?raw';
import cardsRaw from '@virustotal/vtstrap-icons/src/icons/cards.svg?raw';
import caretDownRaw from '@virustotal/vtstrap-icons/src/icons/caret-down.svg?raw';
import caretLeftRaw from '@virustotal/vtstrap-icons/src/icons/caret-left.svg?raw';
import caretRightRaw from '@virustotal/vtstrap-icons/src/icons/caret-right.svg?raw';
import caretUpDownRaw from '@virustotal/vtstrap-icons/src/icons/caret-up-down.svg?raw';
import caretUpRaw from '@virustotal/vtstrap-icons/src/icons/caret-up.svg?raw';
import centerHorizontallyRaw from '@virustotal/vtstrap-icons/src/icons/center-horizontally.svg?raw';
import centerNodeRaw from '@virustotal/vtstrap-icons/src/icons/center-node.svg?raw';
import centerVerticallyRaw from '@virustotal/vtstrap-icons/src/icons/center-vertically.svg?raw';
import chartBarsRaw from '@virustotal/vtstrap-icons/src/icons/chart-bars.svg?raw';
import checkCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/check-circle-filled.svg?raw';
import checkCircleRaw from '@virustotal/vtstrap-icons/src/icons/check-circle.svg?raw';
import checkDottedRaw from '@virustotal/vtstrap-icons/src/icons/check-dotted.svg?raw';
import checkSquareRaw from '@virustotal/vtstrap-icons/src/icons/check-square.svg?raw';
import checkRaw from '@virustotal/vtstrap-icons/src/icons/check.svg?raw';
import chevronCircleDownRaw from '@virustotal/vtstrap-icons/src/icons/chevron-circle-down.svg?raw';
import chevronCircleLeftRaw from '@virustotal/vtstrap-icons/src/icons/chevron-circle-left.svg?raw';
import chevronCircleRightRaw from '@virustotal/vtstrap-icons/src/icons/chevron-circle-right.svg?raw';
import chevronCircle_upRaw from '@virustotal/vtstrap-icons/src/icons/chevron-circle_up.svg?raw';
import chevronDownUpRaw from '@virustotal/vtstrap-icons/src/icons/chevron-down-up.svg?raw';
import chevronDownRaw from '@virustotal/vtstrap-icons/src/icons/chevron-down.svg?raw';
import chevronLeftRaw from '@virustotal/vtstrap-icons/src/icons/chevron-left.svg?raw';
import chevronRightRaw from '@virustotal/vtstrap-icons/src/icons/chevron-right.svg?raw';
import chevronUpDownRaw from '@virustotal/vtstrap-icons/src/icons/chevron-up-down.svg?raw';
import chevronUpRaw from '@virustotal/vtstrap-icons/src/icons/chevron-up.svg?raw';
import circleLockRaw from '@virustotal/vtstrap-icons/src/icons/circle-lock.svg?raw';
import circleSlashRaw from '@virustotal/vtstrap-icons/src/icons/circle-slash.svg?raw';
import circleRaw from '@virustotal/vtstrap-icons/src/icons/circle.svg?raw';
import cityRaw from '@virustotal/vtstrap-icons/src/icons/city.svg?raw';
import clipboardRaw from '@virustotal/vtstrap-icons/src/icons/clipboard.svg?raw';
import clockRotateLeftRaw from '@virustotal/vtstrap-icons/src/icons/clock-rotate-left.svg?raw';
import clockSlashRaw from '@virustotal/vtstrap-icons/src/icons/clock-slash.svg?raw';
import clockRaw from '@virustotal/vtstrap-icons/src/icons/clock.svg?raw';
import cloudCodeRaw from '@virustotal/vtstrap-icons/src/icons/cloud-code.svg?raw';
import cloudDownloadRaw from '@virustotal/vtstrap-icons/src/icons/cloud-download.svg?raw';
import cloudUploadRaw from '@virustotal/vtstrap-icons/src/icons/cloud-upload.svg?raw';
import cloudRaw from '@virustotal/vtstrap-icons/src/icons/cloud.svg?raw';
import clubRaw from '@virustotal/vtstrap-icons/src/icons/club.svg?raw';
import clueRaw from '@virustotal/vtstrap-icons/src/icons/clue.svg?raw';
import clusteringRaw from '@virustotal/vtstrap-icons/src/icons/clustering.svg?raw';
import collectionRaw from '@virustotal/vtstrap-icons/src/icons/collection.svg?raw';
import commentsRaw from '@virustotal/vtstrap-icons/src/icons/comments.svg?raw';
import componentsRaw from '@virustotal/vtstrap-icons/src/icons/components.svg?raw';
import connectedNodesRaw from '@virustotal/vtstrap-icons/src/icons/connected-nodes.svg?raw';
import connectionsRaw from '@virustotal/vtstrap-icons/src/icons/connections.svg?raw';
import consoleRaw from '@virustotal/vtstrap-icons/src/icons/console.svg?raw';
import cookieRaw from '@virustotal/vtstrap-icons/src/icons/cookie.svg?raw';
import copyLinkRaw from '@virustotal/vtstrap-icons/src/icons/copy-link.svg?raw';
import copyRaw from '@virustotal/vtstrap-icons/src/icons/copy.svg?raw';
import crosshairRaw from '@virustotal/vtstrap-icons/src/icons/crosshair.svg?raw';
import crowdsourcedYaraRaw from '@virustotal/vtstrap-icons/src/icons/crowdsourced-yara.svg?raw';
import dashboardAlternateRaw from '@virustotal/vtstrap-icons/src/icons/dashboard-alternate.svg?raw';
import dashboardRaw from '@virustotal/vtstrap-icons/src/icons/dashboard.svg?raw';
import databaseRaw from '@virustotal/vtstrap-icons/src/icons/database.svg?raw';
import destructionRaw from '@virustotal/vtstrap-icons/src/icons/destruction.svg?raw';
import deviceRaw from '@virustotal/vtstrap-icons/src/icons/device.svg?raw';
import devicesRaw from '@virustotal/vtstrap-icons/src/icons/devices.svg?raw';
import diagram2Raw from '@virustotal/vtstrap-icons/src/icons/diagram-2.svg?raw';
import diagramCircle2Raw from '@virustotal/vtstrap-icons/src/icons/diagram-circle-2.svg?raw';
import diagramCircleRaw from '@virustotal/vtstrap-icons/src/icons/diagram-circle.svg?raw';
import diagramRaw from '@virustotal/vtstrap-icons/src/icons/diagram.svg?raw';
import diffRaw from '@virustotal/vtstrap-icons/src/icons/diff.svg?raw';
import diplomaRaw from '@virustotal/vtstrap-icons/src/icons/diploma.svg?raw';
import dnaRaw from '@virustotal/vtstrap-icons/src/icons/dna.svg?raw';
import docChartBarMagnifyingGlassRaw from '@virustotal/vtstrap-icons/src/icons/doc-chart-bar-magnifying-glass.svg?raw';
import docChartBarRaw from '@virustotal/vtstrap-icons/src/icons/doc-chart-bar.svg?raw';
import docConfirmRaw from '@virustotal/vtstrap-icons/src/icons/doc-confirm.svg?raw';
import docDollarRaw from '@virustotal/vtstrap-icons/src/icons/doc-dollar.svg?raw';
import docEmptyRaw from '@virustotal/vtstrap-icons/src/icons/doc-empty.svg?raw';
import docEvtxRaw from '@virustotal/vtstrap-icons/src/icons/doc-evtx.svg?raw';
import docIssueRaw from '@virustotal/vtstrap-icons/src/icons/doc-issue.svg?raw';
import docLinkRaw from '@virustotal/vtstrap-icons/src/icons/doc-link.svg?raw';
import docMemdumpRaw from '@virustotal/vtstrap-icons/src/icons/doc-memdump.svg?raw';
import docPenCheckRaw from '@virustotal/vtstrap-icons/src/icons/doc-pen-check.svg?raw';
import docPenRaw from '@virustotal/vtstrap-icons/src/icons/doc-pen.svg?raw';
import docPhoneRaw from '@virustotal/vtstrap-icons/src/icons/doc-phone.svg?raw';
import docSecureRaw from '@virustotal/vtstrap-icons/src/icons/doc-secure.svg?raw';
import docSkullRaw from '@virustotal/vtstrap-icons/src/icons/doc-skull.svg?raw';
import docUrlRaw from '@virustotal/vtstrap-icons/src/icons/doc-url.svg?raw';
import docVtRaw from '@virustotal/vtstrap-icons/src/icons/doc-vt.svg?raw';
import docWrittenRaw from '@virustotal/vtstrap-icons/src/icons/doc-written.svg?raw';
import dollarChipRaw from '@virustotal/vtstrap-icons/src/icons/dollar-chip.svg?raw';
import dollarRaw from '@virustotal/vtstrap-icons/src/icons/dollar.svg?raw';
import domainRaw from '@virustotal/vtstrap-icons/src/icons/domain.svg?raw';
import downloadDocRaw from '@virustotal/vtstrap-icons/src/icons/download-doc.svg?raw';
import downloadRaw from '@virustotal/vtstrap-icons/src/icons/download.svg?raw';
import draftmanSquareRaw from '@virustotal/vtstrap-icons/src/icons/draftman-square.svg?raw';
import dragDottedRaw from '@virustotal/vtstrap-icons/src/icons/drag-dotted.svg?raw';
import dragIndicatorRaw from '@virustotal/vtstrap-icons/src/icons/drag-indicator.svg?raw';
import dragRaw from '@virustotal/vtstrap-icons/src/icons/drag.svg?raw';
import enterpriseRaw from '@virustotal/vtstrap-icons/src/icons/enterprise.svg?raw';
import envelopeArrowRightRaw from '@virustotal/vtstrap-icons/src/icons/envelope-arrow-right.svg?raw';
import envelopeFastRaw from '@virustotal/vtstrap-icons/src/icons/envelope-fast.svg?raw';
import envelopeHookRaw from '@virustotal/vtstrap-icons/src/icons/envelope-hook.svg?raw';
import envelopeRaw from '@virustotal/vtstrap-icons/src/icons/envelope.svg?raw';
import ethernetRaw from '@virustotal/vtstrap-icons/src/icons/ethernet.svg?raw';
import exclamationCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-circle-filled.svg?raw';
import exclamationCircleRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-circle.svg?raw';
import exclamationMarkDiamondFilledRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-mark-diamond-filled.svg?raw';
import exclamationMarkDiamondRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-mark-diamond.svg?raw';
import exclamationMarkOctagonFilledRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-mark-octagon-filled.svg?raw';
import exclamationMarkOctagonRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-mark-octagon.svg?raw';
import exclamationTriangleFilledRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-triangle-filled.svg?raw';
import exclamationTriangleRaw from '@virustotal/vtstrap-icons/src/icons/exclamation-triangle.svg?raw';
import exclamationRaw from '@virustotal/vtstrap-icons/src/icons/exclamation.svg?raw';
import expandCircleRaw from '@virustotal/vtstrap-icons/src/icons/expand-circle.svg?raw';
import expandRaw from '@virustotal/vtstrap-icons/src/icons/expand.svg?raw';
import eyeSlashRaw from '@virustotal/vtstrap-icons/src/icons/eye-slash.svg?raw';
import eyeRaw from '@virustotal/vtstrap-icons/src/icons/eye.svg?raw';
import facebookRaw from '@virustotal/vtstrap-icons/src/icons/facebook.svg?raw';
import fastForwardRaw from '@virustotal/vtstrap-icons/src/icons/fast-forward.svg?raw';
import faviconDetectionsRaw from '@virustotal/vtstrap-icons/src/icons/favicon-detections.svg?raw';
import faviconRaw from '@virustotal/vtstrap-icons/src/icons/favicon.svg?raw';
import filetypeApkRaw from '@virustotal/vtstrap-icons/src/icons/filetype-apk.svg?raw';
import filetypeAsfRaw from '@virustotal/vtstrap-icons/src/icons/filetype-asf.svg?raw';
import filetypeBpmRaw from '@virustotal/vtstrap-icons/src/icons/filetype-bpm.svg?raw';
import filetypeCRaw from '@virustotal/vtstrap-icons/src/icons/filetype-c.svg?raw';
import filetypeCabRaw from '@virustotal/vtstrap-icons/src/icons/filetype-cab.svg?raw';
import filetypeCapRaw from '@virustotal/vtstrap-icons/src/icons/filetype-cap.svg?raw';
import filetypeCppRaw from '@virustotal/vtstrap-icons/src/icons/filetype-cpp.svg?raw';
import filetypeCrxRaw from '@virustotal/vtstrap-icons/src/icons/filetype-crx.svg?raw';
import filetypeDebRaw from '@virustotal/vtstrap-icons/src/icons/filetype-deb.svg?raw';
import filetypeDmgRaw from '@virustotal/vtstrap-icons/src/icons/filetype-dmg.svg?raw';
import filetypeDocRaw from '@virustotal/vtstrap-icons/src/icons/filetype-doc.svg?raw';
import filetypeDocxRaw from '@virustotal/vtstrap-icons/src/icons/filetype-docx.svg?raw';
import filetypeElfRaw from '@virustotal/vtstrap-icons/src/icons/filetype-elf.svg?raw';
import filetypeEmailRaw from '@virustotal/vtstrap-icons/src/icons/filetype-email.svg?raw';
import filetypeFileRaw from '@virustotal/vtstrap-icons/src/icons/filetype-file.svg?raw';
import filetypeFlashRaw from '@virustotal/vtstrap-icons/src/icons/filetype-flash.svg?raw';
import filetypeGifRaw from '@virustotal/vtstrap-icons/src/icons/filetype-gif.svg?raw';
import filetypeGzipRaw from '@virustotal/vtstrap-icons/src/icons/filetype-gzip.svg?raw';
import filetypeHtmlRaw from '@virustotal/vtstrap-icons/src/icons/filetype-html.svg?raw';
import filetypeIcoRaw from '@virustotal/vtstrap-icons/src/icons/filetype-ico.svg?raw';
import filetypeIpaRaw from '@virustotal/vtstrap-icons/src/icons/filetype-ipa.svg?raw';
import filetypeIsoRaw from '@virustotal/vtstrap-icons/src/icons/filetype-iso.svg?raw';
import filetypeJarRaw from '@virustotal/vtstrap-icons/src/icons/filetype-jar.svg?raw';
import filetypeJavaRaw from '@virustotal/vtstrap-icons/src/icons/filetype-java.svg?raw';
import filetypeJavascriptRaw from '@virustotal/vtstrap-icons/src/icons/filetype-javascript.svg?raw';
import filetypeJpegRaw from '@virustotal/vtstrap-icons/src/icons/filetype-jpeg.svg?raw';
import filetypeLnkRaw from '@virustotal/vtstrap-icons/src/icons/filetype-lnk.svg?raw';
import filetypeMachoRaw from '@virustotal/vtstrap-icons/src/icons/filetype-macho.svg?raw';
import filetypeMp3Raw from '@virustotal/vtstrap-icons/src/icons/filetype-mp3.svg?raw';
import filetypeMp4Raw from '@virustotal/vtstrap-icons/src/icons/filetype-mp4.svg?raw';
import filetypeMsiRaw from '@virustotal/vtstrap-icons/src/icons/filetype-msi.svg?raw';
import filetypeOdpRaw from '@virustotal/vtstrap-icons/src/icons/filetype-odp.svg?raw';
import filetypeOdtRaw from '@virustotal/vtstrap-icons/src/icons/filetype-odt.svg?raw';
import filetypePcapRaw from '@virustotal/vtstrap-icons/src/icons/filetype-pcap.svg?raw';
import filetypePdfRaw from '@virustotal/vtstrap-icons/src/icons/filetype-pdf.svg?raw';
import filetypePedllRaw from '@virustotal/vtstrap-icons/src/icons/filetype-pedll.svg?raw';
import filetypePeexeRaw from '@virustotal/vtstrap-icons/src/icons/filetype-peexe.svg?raw';
import filetypePngRaw from '@virustotal/vtstrap-icons/src/icons/filetype-png.svg?raw';
import filetypePptRaw from '@virustotal/vtstrap-icons/src/icons/filetype-ppt.svg?raw';
import filetypePptxRaw from '@virustotal/vtstrap-icons/src/icons/filetype-pptx.svg?raw';
import filetypeRarRaw from '@virustotal/vtstrap-icons/src/icons/filetype-rar.svg?raw';
import filetypeRomRaw from '@virustotal/vtstrap-icons/src/icons/filetype-rom.svg?raw';
import filetypeRpmRaw from '@virustotal/vtstrap-icons/src/icons/filetype-rpm.svg?raw';
import filetypeRtfRaw from '@virustotal/vtstrap-icons/src/icons/filetype-rtf.svg?raw';
import filetypeSvgRaw from '@virustotal/vtstrap-icons/src/icons/filetype-svg.svg?raw';
import filetypeSzipRaw from '@virustotal/vtstrap-icons/src/icons/filetype-szip.svg?raw';
import filetypeTarRaw from '@virustotal/vtstrap-icons/src/icons/filetype-tar.svg?raw';
import filetypeTiffRaw from '@virustotal/vtstrap-icons/src/icons/filetype-tiff.svg?raw';
import filetypeTtfRaw from '@virustotal/vtstrap-icons/src/icons/filetype-ttf.svg?raw';
import filetypeTxtRaw from '@virustotal/vtstrap-icons/src/icons/filetype-txt.svg?raw';
import filetypeUrlRaw from '@virustotal/vtstrap-icons/src/icons/filetype-url.svg?raw';
import filetypeWavRaw from '@virustotal/vtstrap-icons/src/icons/filetype-wav.svg?raw';
import filetypeXlsRaw from '@virustotal/vtstrap-icons/src/icons/filetype-xls.svg?raw';
import filetypeXlslxRaw from '@virustotal/vtstrap-icons/src/icons/filetype-xlslx.svg?raw';
import filetypeXmlRaw from '@virustotal/vtstrap-icons/src/icons/filetype-xml.svg?raw';
import filetypeZipRaw from '@virustotal/vtstrap-icons/src/icons/filetype-zip.svg?raw';
import filterRaw from '@virustotal/vtstrap-icons/src/icons/filter.svg?raw';
import filtersRaw from '@virustotal/vtstrap-icons/src/icons/filters.svg?raw';
import fingerprintRaw from '@virustotal/vtstrap-icons/src/icons/fingerprint.svg?raw';
import fireRaw from '@virustotal/vtstrap-icons/src/icons/fire.svg?raw';
import flagFilledRaw from '@virustotal/vtstrap-icons/src/icons/flag-filled.svg?raw';
import flagRaw from '@virustotal/vtstrap-icons/src/icons/flag.svg?raw';
import floppyDiskArrowRightRaw from '@virustotal/vtstrap-icons/src/icons/floppy-disk-arrow-right.svg?raw';
import floppyDiskRaw from '@virustotal/vtstrap-icons/src/icons/floppy-disk.svg?raw';
import folderArrowUpLeftRaw from '@virustotal/vtstrap-icons/src/icons/folder-arrow-up-left.svg?raw';
import folderArrowUpRaw from '@virustotal/vtstrap-icons/src/icons/folder-arrow-up.svg?raw';
import folderPlusRaw from '@virustotal/vtstrap-icons/src/icons/folder-plus.svg?raw';
import folderXmarkRaw from '@virustotal/vtstrap-icons/src/icons/folder-xmark.svg?raw';
import folderRaw from '@virustotal/vtstrap-icons/src/icons/folder.svg?raw';
import formatAlignCenterRaw from '@virustotal/vtstrap-icons/src/icons/format-align-center.svg?raw';
import formatAlignLeftRaw from '@virustotal/vtstrap-icons/src/icons/format-align-left.svg?raw';
import formatAlignRightRaw from '@virustotal/vtstrap-icons/src/icons/format-align-right.svg?raw';
import formatBoldRaw from '@virustotal/vtstrap-icons/src/icons/format-bold.svg?raw';
import formatCodeRaw from '@virustotal/vtstrap-icons/src/icons/format-code.svg?raw';
import formatItalicRaw from '@virustotal/vtstrap-icons/src/icons/format-italic.svg?raw';
import formatQuoteRaw from '@virustotal/vtstrap-icons/src/icons/format-quote.svg?raw';
import formatUnderlineRaw from '@virustotal/vtstrap-icons/src/icons/format-underline.svg?raw';
import gameConsoleControllerRaw from '@virustotal/vtstrap-icons/src/icons/game-console-controller.svg?raw';
import gasStationRaw from '@virustotal/vtstrap-icons/src/icons/gas-station.svg?raw';
import gaugeRaw from '@virustotal/vtstrap-icons/src/icons/gauge.svg?raw';
import gearWrenchRaw from '@virustotal/vtstrap-icons/src/icons/gear-wrench.svg?raw';
import gearRaw from '@virustotal/vtstrap-icons/src/icons/gear.svg?raw';
import geminiRaw from '@virustotal/vtstrap-icons/src/icons/gemini.svg?raw';
import githubRaw from '@virustotal/vtstrap-icons/src/icons/github.svg?raw';
import globeLinkRaw from '@virustotal/vtstrap-icons/src/icons/globe-link.svg?raw';
import globeLocationPinRaw from '@virustotal/vtstrap-icons/src/icons/globe-location-pin.svg?raw';
import globeMagnifyingGlassRaw from '@virustotal/vtstrap-icons/src/icons/globe-magnifying-glass.svg?raw';
import globeRaw from '@virustotal/vtstrap-icons/src/icons/globe.svg?raw';
import goRaw from '@virustotal/vtstrap-icons/src/icons/go.svg?raw';
import google1Raw from '@virustotal/vtstrap-icons/src/icons/google-1.svg?raw';
import googleRaw from '@virustotal/vtstrap-icons/src/icons/google.svg?raw';
import graphBarsBaselineRaw from '@virustotal/vtstrap-icons/src/icons/graph-bars-baseline.svg?raw';
import graphSimpleRaw from '@virustotal/vtstrap-icons/src/icons/graph-simple.svg?raw';
import graphRaw from '@virustotal/vtstrap-icons/src/icons/graph.svg?raw';
import grid2Raw from '@virustotal/vtstrap-icons/src/icons/grid-2.svg?raw';
import grid3Raw from '@virustotal/vtstrap-icons/src/icons/grid-3.svg?raw';
import gridRaw from '@virustotal/vtstrap-icons/src/icons/grid.svg?raw';
import groupCircleRaw from '@virustotal/vtstrap-icons/src/icons/group-circle.svg?raw';
import hammerWrenchRaw from '@virustotal/vtstrap-icons/src/icons/hammer-wrench.svg?raw';
import handEllipsisRaw from '@virustotal/vtstrap-icons/src/icons/hand-ellipsis.svg?raw';
import handGearRaw from '@virustotal/vtstrap-icons/src/icons/hand-gear.svg?raw';
import handRegularRaw from '@virustotal/vtstrap-icons/src/icons/hand-regular.svg?raw';
import handshakeRaw from '@virustotal/vtstrap-icons/src/icons/handshake.svg?raw';
import hardDriveRaw from '@virustotal/vtstrap-icons/src/icons/hard-drive.svg?raw';
import hatRaw from '@virustotal/vtstrap-icons/src/icons/hat.svg?raw';
import heartRaw from '@virustotal/vtstrap-icons/src/icons/heart.svg?raw';
import highlightRaw from '@virustotal/vtstrap-icons/src/icons/highlight.svg?raw';
import homeRaw from '@virustotal/vtstrap-icons/src/icons/home.svg?raw';
import houseWifiRaw from '@virustotal/vtstrap-icons/src/icons/house-wifi.svg?raw';
import huntingRaw from '@virustotal/vtstrap-icons/src/icons/hunting.svg?raw';
import idCardClipRaw from '@virustotal/vtstrap-icons/src/icons/id-card-clip.svg?raw';
import idsRaw from '@virustotal/vtstrap-icons/src/icons/ids.svg?raw';
import imageRaw from '@virustotal/vtstrap-icons/src/icons/image.svg?raw';
import infoCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/info-circle-filled.svg?raw';
import infoCircleRaw from '@virustotal/vtstrap-icons/src/icons/info-circle.svg?raw';
import infoSparkRaw from '@virustotal/vtstrap-icons/src/icons/info-spark.svg?raw';
import infoRaw from '@virustotal/vtstrap-icons/src/icons/info.svg?raw';
import inquiryCardsRaw from '@virustotal/vtstrap-icons/src/icons/inquiry-cards.svg?raw';
import insightSparkRaw from '@virustotal/vtstrap-icons/src/icons/insight-spark.svg?raw';
import intelligenceRaw from '@virustotal/vtstrap-icons/src/icons/intelligence.svg?raw';
import justifyLeftRaw from '@virustotal/vtstrap-icons/src/icons/justify-left.svg?raw';
import kebabMenuHorizontalCircleRaw from '@virustotal/vtstrap-icons/src/icons/kebab-menu-horizontal-circle.svg?raw';
import langRaw from '@virustotal/vtstrap-icons/src/icons/lang.svg?raw';
import laptopRaw from '@virustotal/vtstrap-icons/src/icons/laptop.svg?raw';
import layerArrowDownRaw from '@virustotal/vtstrap-icons/src/icons/layer-arrow-down.svg?raw';
import layerArrowUpRaw from '@virustotal/vtstrap-icons/src/icons/layer-arrow-up.svg?raw';
import layerGroupVerticalRaw from '@virustotal/vtstrap-icons/src/icons/layer-group-vertical.svg?raw';
import layerVerticalCheckRaw from '@virustotal/vtstrap-icons/src/icons/layer-vertical-check.svg?raw';
import layersArrowDownRaw from '@virustotal/vtstrap-icons/src/icons/layers-arrow-down.svg?raw';
import layersSquareRaw from '@virustotal/vtstrap-icons/src/icons/layers-square.svg?raw';
import layersRaw from '@virustotal/vtstrap-icons/src/icons/layers.svg?raw';
import leafRaw from '@virustotal/vtstrap-icons/src/icons/leaf.svg?raw';
import lightbulbRaw from '@virustotal/vtstrap-icons/src/icons/lightbulb.svg?raw';
import lineMonitorRaw from '@virustotal/vtstrap-icons/src/icons/line-monitor.svg?raw';
import linkRaw from '@virustotal/vtstrap-icons/src/icons/link.svg?raw';
import list2Raw from '@virustotal/vtstrap-icons/src/icons/list-2.svg?raw';
import listMagnifyingGlassRaw from '@virustotal/vtstrap-icons/src/icons/list-magnifying-glass.svg?raw';
import listSquaresRaw from '@virustotal/vtstrap-icons/src/icons/list-squares.svg?raw';
import listUlRaw from '@virustotal/vtstrap-icons/src/icons/list-ul.svg?raw';
import localPharmacyRaw from '@virustotal/vtstrap-icons/src/icons/local-pharmacy.svg?raw';
import locationPinCheckRaw from '@virustotal/vtstrap-icons/src/icons/location-pin-check.svg?raw';
import locationPinIpCircleRaw from '@virustotal/vtstrap-icons/src/icons/location-pin-ip-circle.svg?raw';
import locationPinIpRaw from '@virustotal/vtstrap-icons/src/icons/location-pin-ip.svg?raw';
import locationPinMapRaw from '@virustotal/vtstrap-icons/src/icons/location-pin-map.svg?raw';
import locationPinQuestionMarkRaw from '@virustotal/vtstrap-icons/src/icons/location-pin-question-mark.svg?raw';
import locationPinXmarkRaw from '@virustotal/vtstrap-icons/src/icons/location-pin-xmark.svg?raw';
import locationPinRaw from '@virustotal/vtstrap-icons/src/icons/location-pin.svg?raw';
import lockOpenRaw from '@virustotal/vtstrap-icons/src/icons/lock-open.svg?raw';
import lockSslRaw from '@virustotal/vtstrap-icons/src/icons/lock-ssl.svg?raw';
import lockRaw from '@virustotal/vtstrap-icons/src/icons/lock.svg?raw';
import longArrowLeftRaw from '@virustotal/vtstrap-icons/src/icons/long-arrow-left.svg?raw';
import longArrowRightRaw from '@virustotal/vtstrap-icons/src/icons/long-arrow-right.svg?raw';
import magnifyingGlassChartRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-chart.svg?raw';
import magnifyingGlassIocStreamRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-ioc-stream.svg?raw';
import magnifyingGlassListRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-list.svg?raw';
import magnifyingGlassMinusRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-minus.svg?raw';
import magnifyingGlassPlusRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-plus.svg?raw';
import magnifyingGlassQuestionMarkRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-question-mark.svg?raw';
import magnifyingGlassSimiliaritiesRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-similiarities.svg?raw';
import magnifyingGlassSparkRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-spark.svg?raw';
import magnifyingGlassThreadLandscapeRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass-thread-landscape.svg?raw';
import magnifyingGlassRaw from '@virustotal/vtstrap-icons/src/icons/magnifying-glass.svg?raw';
import mandiantLogoMonochromeRaw from '@virustotal/vtstrap-icons/src/icons/mandiant-logo-monochrome.svg?raw';
import mandiantRaw from '@virustotal/vtstrap-icons/src/icons/mandiant.svg?raw';
import mapRaw from '@virustotal/vtstrap-icons/src/icons/map.svg?raw';
import megaphoneRaw from '@virustotal/vtstrap-icons/src/icons/megaphone.svg?raw';
import menuKebabHorizontalRaw from '@virustotal/vtstrap-icons/src/icons/menu-kebab-horizontal.svg?raw';
import menuKebabVerticalRaw from '@virustotal/vtstrap-icons/src/icons/menu-kebab-vertical.svg?raw';
import menuRaw from '@virustotal/vtstrap-icons/src/icons/menu.svg?raw';
import messagePenRaw from '@virustotal/vtstrap-icons/src/icons/message-pen.svg?raw';
import messageSparkRaw from '@virustotal/vtstrap-icons/src/icons/message-spark.svg?raw';
import messageRaw from '@virustotal/vtstrap-icons/src/icons/message.svg?raw';
import microsoft1Raw from '@virustotal/vtstrap-icons/src/icons/microsoft-1.svg?raw';
import microsoftRaw from '@virustotal/vtstrap-icons/src/icons/microsoft.svg?raw';
import militaryMedalRaw from '@virustotal/vtstrap-icons/src/icons/military-medal.svg?raw';
import minusCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/minus-circle-filled.svg?raw';
import minusCircleRaw from '@virustotal/vtstrap-icons/src/icons/minus-circle.svg?raw';
import minusSquareRaw from '@virustotal/vtstrap-icons/src/icons/minus-square.svg?raw';
import minusRaw from '@virustotal/vtstrap-icons/src/icons/minus.svg?raw';
import mitreMatrixRaw from '@virustotal/vtstrap-icons/src/icons/mitre-matrix.svg?raw';
import mitreRaw from '@virustotal/vtstrap-icons/src/icons/mitre.svg?raw';
import mobileTouchRaw from '@virustotal/vtstrap-icons/src/icons/mobile-touch.svg?raw';
import monitor1Raw from '@virustotal/vtstrap-icons/src/icons/monitor-1.svg?raw';
import monitorRaw from '@virustotal/vtstrap-icons/src/icons/monitor.svg?raw';
import moonRaw from '@virustotal/vtstrap-icons/src/icons/moon.svg?raw';
import multisimilarityRaw from '@virustotal/vtstrap-icons/src/icons/multisimilarity.svg?raw';
import newRaw from '@virustotal/vtstrap-icons/src/icons/new.svg?raw';
import nodesAltRaw from '@virustotal/vtstrap-icons/src/icons/nodes-alt.svg?raw';
import nodesMagnifyingGlassRaw from '@virustotal/vtstrap-icons/src/icons/nodes-magnifying-glass.svg?raw';
import nodesSkullRaw from '@virustotal/vtstrap-icons/src/icons/nodes-skull.svg?raw';
import nodesRaw from '@virustotal/vtstrap-icons/src/icons/nodes.svg?raw';
import noteRaw from '@virustotal/vtstrap-icons/src/icons/note.svg?raw';
import objectGroupRaw from '@virustotal/vtstrap-icons/src/icons/object-group.svg?raw';
import objectUngroupRaw from '@virustotal/vtstrap-icons/src/icons/object-ungroup.svg?raw';
import pageEarthEuropeLayerRaw from '@virustotal/vtstrap-icons/src/icons/page-earth-europe-layer.svg?raw';
import paperclipRaw from '@virustotal/vtstrap-icons/src/icons/paperclip.svg?raw';
import pauseRaw from '@virustotal/vtstrap-icons/src/icons/pause.svg?raw';
import pdfRaw from '@virustotal/vtstrap-icons/src/icons/pdf.svg?raw';
import penSparkRaw from '@virustotal/vtstrap-icons/src/icons/pen-spark.svg?raw';
import pencilRaw from '@virustotal/vtstrap-icons/src/icons/pencil.svg?raw';
import personCheckRaw from '@virustotal/vtstrap-icons/src/icons/person-check.svg?raw';
import personExclamationMarkRaw from '@virustotal/vtstrap-icons/src/icons/person-exclamation-mark.svg?raw';
import personMinusRaw from '@virustotal/vtstrap-icons/src/icons/person-minus.svg?raw';
import personXmarkRaw from '@virustotal/vtstrap-icons/src/icons/person-xmark.svg?raw';
import phoneArrowUpRightRaw from '@virustotal/vtstrap-icons/src/icons/phone-arrow-up-right.svg?raw';
import phoneRaw from '@virustotal/vtstrap-icons/src/icons/phone.svg?raw';
import photoCameraSlashRaw from '@virustotal/vtstrap-icons/src/icons/photo-camera-slash.svg?raw';
import pictureSparkRaw from '@virustotal/vtstrap-icons/src/icons/picture-spark.svg?raw';
import pieChartRaw from '@virustotal/vtstrap-icons/src/icons/pie-chart.svg?raw';
import planRaw from '@virustotal/vtstrap-icons/src/icons/plan.svg?raw';
import planeRaw from '@virustotal/vtstrap-icons/src/icons/plane.svg?raw';
import playRaw from '@virustotal/vtstrap-icons/src/icons/play.svg?raw';
import plugRaw from '@virustotal/vtstrap-icons/src/icons/plug.svg?raw';
import plusCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/plus-circle-filled.svg?raw';
import plusCircleRaw from '@virustotal/vtstrap-icons/src/icons/plus-circle.svg?raw';
import plusRaw from '@virustotal/vtstrap-icons/src/icons/plus.svg?raw';
import portRaw from '@virustotal/vtstrap-icons/src/icons/port.svg?raw';
import powerRaw from '@virustotal/vtstrap-icons/src/icons/power.svg?raw';
import printerRaw from '@virustotal/vtstrap-icons/src/icons/printer.svg?raw';
import privateScanningRaw from '@virustotal/vtstrap-icons/src/icons/private-scanning.svg?raw';
import psychologyRaw from '@virustotal/vtstrap-icons/src/icons/psychology.svg?raw';
import pythonRaw from '@virustotal/vtstrap-icons/src/icons/python.svg?raw';
import questionMarkCircleRaw from '@virustotal/vtstrap-icons/src/icons/question-mark-circle.svg?raw';
import questionMarkRaw from '@virustotal/vtstrap-icons/src/icons/question-mark.svg?raw';
import radioButtonCheckedRaw from '@virustotal/vtstrap-icons/src/icons/radio-button-checked.svg?raw';
import radioButtonPartialRaw from '@virustotal/vtstrap-icons/src/icons/radio-button-partial.svg?raw';
import ramRaw from '@virustotal/vtstrap-icons/src/icons/ram.svg?raw';
import ravenRaw from '@virustotal/vtstrap-icons/src/icons/raven.svg?raw';
import rebaseRaw from '@virustotal/vtstrap-icons/src/icons/rebase.svg?raw';
import retrohuntHistoryRaw from '@virustotal/vtstrap-icons/src/icons/retrohunt-history.svg?raw';
import retrohuntRaw from '@virustotal/vtstrap-icons/src/icons/retrohunt.svg?raw';
import rewindRaw from '@virustotal/vtstrap-icons/src/icons/rewind.svg?raw';
import rhombusArrowDownRaw from '@virustotal/vtstrap-icons/src/icons/rhombus-arrow-down.svg?raw';
import ribbonRaw from '@virustotal/vtstrap-icons/src/icons/ribbon.svg?raw';
import routerRaw from '@virustotal/vtstrap-icons/src/icons/router.svg?raw';
import sandboxAndroboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-androbox.svg?raw';
import sandboxBitdamAtpRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-bitdam-atp.svg?raw';
import sandboxC2aeRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-c2ae.svg?raw';
import sandboxCapaRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-capa.svg?raw';
import sandboxCapeSandboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-cape-sandbox.svg?raw';
import sandboxCapeRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-cape.svg?raw';
import sandboxCyberAdaptRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-cyber-adapt.svg?raw';
import sandboxDasSecurityOrcasRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-das-security-orcas.svg?raw';
import sandboxDocguardRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-docguard.svg?raw';
import sandboxDrwebVxcubeRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-drweb-vxcube.svg?raw';
import sandboxElfDigestRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-elf-digest.svg?raw';
import sandboxFSecureSandboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-f-secure-sandbox.svg?raw';
import sandboxFSecureRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-f-secure.svg?raw';
import sandboxFenriscanRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-fenriscan.svg?raw';
import sandboxLastlineRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-lastline.svg?raw';
import sandboxMalwationRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-malwation.svg?raw';
import sandboxMicrosoftSysinternalsRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-microsoft-sysinternals.svg?raw';
import sandboxNsfocusPomaRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-nsfocus-poma.svg?raw';
import sandboxOsXSandboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-os-x-sandbox.svg?raw';
import sandboxOsXRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-os-x.svg?raw';
import sandboxQianxinReddripRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-qianxin-reddrip.svg?raw';
import sandboxReaqtaHiveRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-reaqta-hive.svg?raw';
import sandboxRisingMovesRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-rising-moves.svg?raw';
import sandboxSangforZsandRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-sangfor-zsand.svg?raw';
import sandboxSecneurxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-secneurx.svg?raw';
import sandboxSecondwriteRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-secondwrite.svg?raw';
import sandboxSndboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-sndbox.svg?raw';
import sandboxTencentHaboRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-tencent-habo.svg?raw';
import sandboxVenuseyeSandboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-venuseye-sandbox.svg?raw';
import sandboxVenuseyeRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-venuseye.svg?raw';
import sandboxVirustotalBoxOfApplesRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-box-of-apples.svg?raw';
import sandboxVirustotalCuckooforkRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-cuckoofork.svg?raw';
import sandboxVirustotalDroidyRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-droidy.svg?raw';
import sandboxVirustotalJoseboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-josebox.svg?raw';
import sandboxVirustotalJujuboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-jujubox.svg?raw';
import sandboxVirustotalObserverRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-observer.svg?raw';
import sandboxVirustotalR2dboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-r2dbox.svg?raw';
import sandboxVirustotalSandboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-sandbox.svg?raw';
import sandboxVirustotalZenboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-virustotal-zenbox.svg?raw';
import sandboxVmrayRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-vmray.svg?raw';
import sandboxYomiHunterRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-yomi-hunter.svg?raw';
import sandboxZenboxAndroidRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-zenbox-android.svg?raw';
import sandboxZenboxLinuxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-zenbox-linux.svg?raw';
import sandboxZenboxRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-zenbox.svg?raw';
import sandboxZscalerRaw from '@virustotal/vtstrap-icons/src/icons/sandbox-zscaler.svg?raw';
import scaleBalancedRaw from '@virustotal/vtstrap-icons/src/icons/scale-balanced.svg?raw';
import schoolHatRaw from '@virustotal/vtstrap-icons/src/icons/school-hat.svg?raw';
import screenDeviceRaw from '@virustotal/vtstrap-icons/src/icons/screen-device.svg?raw';
import screenEllipsisRaw from '@virustotal/vtstrap-icons/src/icons/screen-ellipsis.svg?raw';
import screenGearRaw from '@virustotal/vtstrap-icons/src/icons/screen-gear.svg?raw';
import screenShieldRaw from '@virustotal/vtstrap-icons/src/icons/screen-shield.svg?raw';
import screenSkullRaw from '@virustotal/vtstrap-icons/src/icons/screen-skull.svg?raw';
import screenRaw from '@virustotal/vtstrap-icons/src/icons/screen.svg?raw';
import screwdriverBenchRaw from '@virustotal/vtstrap-icons/src/icons/screwdriver-bench.svg?raw';
import selectChildrenRaw from '@virustotal/vtstrap-icons/src/icons/select-children.svg?raw';
import selectParentRaw from '@virustotal/vtstrap-icons/src/icons/select-parent.svg?raw';
import sendRaw from '@virustotal/vtstrap-icons/src/icons/send.svg?raw';
import sensorsRaw from '@virustotal/vtstrap-icons/src/icons/sensors.svg?raw';
import serverRaw from '@virustotal/vtstrap-icons/src/icons/server.svg?raw';
import shareRaw from '@virustotal/vtstrap-icons/src/icons/share.svg?raw';
import shieldCheckRaw from '@virustotal/vtstrap-icons/src/icons/shield-check.svg?raw';
import shieldExclamationMarkRaw from '@virustotal/vtstrap-icons/src/icons/shield-exclamation-mark.svg?raw';
import shieldPersonRaw from '@virustotal/vtstrap-icons/src/icons/shield-person.svg?raw';
import shieldSlashRaw from '@virustotal/vtstrap-icons/src/icons/shield-slash.svg?raw';
import shieldSslRaw from '@virustotal/vtstrap-icons/src/icons/shield-ssl.svg?raw';
import shieldRaw from '@virustotal/vtstrap-icons/src/icons/shield.svg?raw';
import shoppingCartRaw from '@virustotal/vtstrap-icons/src/icons/shopping-cart.svg?raw';
import sigmaRaw from '@virustotal/vtstrap-icons/src/icons/sigma.svg?raw';
import similarRaw from '@virustotal/vtstrap-icons/src/icons/similar.svg?raw';
import skullGroupRaw from '@virustotal/vtstrap-icons/src/icons/skull-group.svg?raw';
import skullRelationRaw from '@virustotal/vtstrap-icons/src/icons/skull-relation.svg?raw';
import skullZipRaw from '@virustotal/vtstrap-icons/src/icons/skull-zip.svg?raw';
import skullRaw from '@virustotal/vtstrap-icons/src/icons/skull.svg?raw';
import smartphoneRaw from '@virustotal/vtstrap-icons/src/icons/smartphone.svg?raw';
import sortRaw from '@virustotal/vtstrap-icons/src/icons/sort.svg?raw';
import spadeRaw from '@virustotal/vtstrap-icons/src/icons/spade.svg?raw';
import speakerXmarkRaw from '@virustotal/vtstrap-icons/src/icons/speaker-xmark.svg?raw';
import speedometerRaw from '@virustotal/vtstrap-icons/src/icons/speedometer.svg?raw';
import spinnerFourthRaw from '@virustotal/vtstrap-icons/src/icons/spinner-fourth.svg?raw';
import spinnerQuarterRaw from '@virustotal/vtstrap-icons/src/icons/spinner-quarter.svg?raw';
import squareArrowCornerTopRightRaw from '@virustotal/vtstrap-icons/src/icons/square-arrow-corner-top-right.svg?raw';
import squareArrowLeftRaw from '@virustotal/vtstrap-icons/src/icons/square-arrow-left.svg?raw';
import squareCircleCornersRaw from '@virustotal/vtstrap-icons/src/icons/square-circle-corners.svg?raw';
import squareCornersQuestionMarkRaw from '@virustotal/vtstrap-icons/src/icons/square-corners-question-mark.svg?raw';
import squareDottedRaw from '@virustotal/vtstrap-icons/src/icons/square-dotted.svg?raw';
import squareRssRaw from '@virustotal/vtstrap-icons/src/icons/square-rss.svg?raw';
import squareRaw from '@virustotal/vtstrap-icons/src/icons/square.svg?raw';
import starFilledRaw from '@virustotal/vtstrap-icons/src/icons/star-filled.svg?raw';
import starRaw from '@virustotal/vtstrap-icons/src/icons/star.svg?raw';
import statsRaw from '@virustotal/vtstrap-icons/src/icons/stats.svg?raw';
import stethoscopeRaw from '@virustotal/vtstrap-icons/src/icons/stethoscope.svg?raw';
import storefrontRaw from '@virustotal/vtstrap-icons/src/icons/storefront.svg?raw';
import subdomainRaw from '@virustotal/vtstrap-icons/src/icons/subdomain.svg?raw';
import sunRaw from '@virustotal/vtstrap-icons/src/icons/sun.svg?raw';
import tableRaw from '@virustotal/vtstrap-icons/src/icons/table.svg?raw';
import tabletListRaw from '@virustotal/vtstrap-icons/src/icons/tablet-list.svg?raw';
import tagsDottedRaw from '@virustotal/vtstrap-icons/src/icons/tags-dotted.svg?raw';
import tagsRaw from '@virustotal/vtstrap-icons/src/icons/tags.svg?raw';
import targetArrowRaw from '@virustotal/vtstrap-icons/src/icons/target-arrow.svg?raw';
import targetDetectRaw from '@virustotal/vtstrap-icons/src/icons/target-detect.svg?raw';
import targetEyeRaw from '@virustotal/vtstrap-icons/src/icons/target-eye.svg?raw';
import targetRaw from '@virustotal/vtstrap-icons/src/icons/target.svg?raw';
import terminalRaw from '@virustotal/vtstrap-icons/src/icons/terminal.svg?raw';
import testTubeRaw from '@virustotal/vtstrap-icons/src/icons/test-tube.svg?raw';
import thpRaw from '@virustotal/vtstrap-icons/src/icons/thp.svg?raw';
import thumbsDownFilledRaw from '@virustotal/vtstrap-icons/src/icons/thumbs-down-filled.svg?raw';
import thumbsDownRaw from '@virustotal/vtstrap-icons/src/icons/thumbs-down.svg?raw';
import thumbsUpFilledRaw from '@virustotal/vtstrap-icons/src/icons/thumbs-up-filled.svg?raw';
import thumbsUpRaw from '@virustotal/vtstrap-icons/src/icons/thumbs-up.svg?raw';
import thumbtackFilledRaw from '@virustotal/vtstrap-icons/src/icons/thumbtack-filled.svg?raw';
import thumbtackRaw from '@virustotal/vtstrap-icons/src/icons/thumbtack.svg?raw';
import thumbtacksRaw from '@virustotal/vtstrap-icons/src/icons/thumbtacks.svg?raw';
import thunderboltRaw from '@virustotal/vtstrap-icons/src/icons/thunderbolt.svg?raw';
import timelineRaw from '@virustotal/vtstrap-icons/src/icons/timeline.svg?raw';
import timerRaw from '@virustotal/vtstrap-icons/src/icons/timer.svg?raw';
import tractorRaw from '@virustotal/vtstrap-icons/src/icons/tractor.svg?raw';
import triangleRaw from '@virustotal/vtstrap-icons/src/icons/triangle.svg?raw';
import twoFactorAuthenticationRaw from '@virustotal/vtstrap-icons/src/icons/two-factor-authentication.svg?raw';
import twoGearsRaw from '@virustotal/vtstrap-icons/src/icons/two-gears.svg?raw';
import twoUsersRaw from '@virustotal/vtstrap-icons/src/icons/two-users.svg?raw';
import unknownRaw from '@virustotal/vtstrap-icons/src/icons/unknown.svg?raw';
import unlinkRaw from '@virustotal/vtstrap-icons/src/icons/unlink.svg?raw';
import upgradeRaw from '@virustotal/vtstrap-icons/src/icons/upgrade.svg?raw';
import uploadRaw from '@virustotal/vtstrap-icons/src/icons/upload.svg?raw';
import userAccountRaw from '@virustotal/vtstrap-icons/src/icons/user-account.svg?raw';
import userConfigRaw from '@virustotal/vtstrap-icons/src/icons/user-config.svg?raw';
import userHeadsetRaw from '@virustotal/vtstrap-icons/src/icons/user-headset.svg?raw';
import userLockRaw from '@virustotal/vtstrap-icons/src/icons/user-lock.svg?raw';
import userMagnifyingGlassRaw from '@virustotal/vtstrap-icons/src/icons/user-magnifying-glass.svg?raw';
import userPlusRaw from '@virustotal/vtstrap-icons/src/icons/user-plus.svg?raw';
import userTargetRaw from '@virustotal/vtstrap-icons/src/icons/user-target.svg?raw';
import userVictimRaw from '@virustotal/vtstrap-icons/src/icons/user-victim.svg?raw';
import userRaw from '@virustotal/vtstrap-icons/src/icons/user.svg?raw';
import usersRaw from '@virustotal/vtstrap-icons/src/icons/users.svg?raw';
import vennDiagramRaw from '@virustotal/vtstrap-icons/src/icons/venn-diagram.svg?raw';
import verticalLineChevronLeftRaw from '@virustotal/vtstrap-icons/src/icons/vertical-line-chevron-left.svg?raw';
import verticalLineChevronRightRaw from '@virustotal/vtstrap-icons/src/icons/vertical-line-chevron-right.svg?raw';
import videoCameraRaw from '@virustotal/vtstrap-icons/src/icons/video-camera.svg?raw';
import vtDatabaseRaw from '@virustotal/vtstrap-icons/src/icons/vt-database.svg?raw';
import vtFillRaw from '@virustotal/vtstrap-icons/src/icons/vt-fill.svg?raw';
import vtRibbonRaw from '@virustotal/vtstrap-icons/src/icons/vt-ribbon.svg?raw';
import vtRaw from '@virustotal/vtstrap-icons/src/icons/vt.svg?raw';
import walletRaw from '@virustotal/vtstrap-icons/src/icons/wallet.svg?raw';
import webRaw from '@virustotal/vtstrap-icons/src/icons/web.svg?raw';
import whoisRaw from '@virustotal/vtstrap-icons/src/icons/whois.svg?raw';
import xTwitterRaw from '@virustotal/vtstrap-icons/src/icons/x-twitter.svg?raw';
import xmarkCircleFilledRaw from '@virustotal/vtstrap-icons/src/icons/xmark-circle-filled.svg?raw';
import xmarkCircleRaw from '@virustotal/vtstrap-icons/src/icons/xmark-circle.svg?raw';
import xmarkRaw from '@virustotal/vtstrap-icons/src/icons/xmark.svg?raw';
import yaraExportRaw from '@virustotal/vtstrap-icons/src/icons/yara-export.svg?raw';
import yaraRaw from '@virustotal/vtstrap-icons/src/icons/yara.svg?raw';
import zenboxAndroidRaw from '@virustotal/vtstrap-icons/src/icons/zenbox-android.svg?raw';
import zenboxLinuxRaw from '@virustotal/vtstrap-icons/src/icons/zenbox-linux.svg?raw';
import {svg} from 'lit-html';
import {unsafeSVG} from 'lit/directives/unsafe-svg.js';
// Icons exports
export const actorIcon = svg`${unsafeSVG(actorRaw)}`;
export const aiIcon = svg`${unsafeSVG(aiRaw)}`;
export const androidFullIcon = svg`${unsafeSVG(androidFullRaw)}`;
export const androidSmallIcon = svg`${unsafeSVG(androidSmallRaw)}`;
export const apiBIcon = svg`${unsafeSVG(apiBRaw)}`;
export const apiKeyIcon = svg`${unsafeSVG(apiKeyRaw)}`;
export const apiIcon = svg`${unsafeSVG(apiRaw)}`;
export const arrowCornersIcon = svg`${unsafeSVG(arrowCornersRaw)}`;
export const arrowCurveTopRightIcon = svg`${unsafeSVG(arrowCurveTopRightRaw)}`;
export const arrowDown19Icon = svg`${unsafeSVG(arrowDown19Raw)}`;
export const arrowDownCircleFilledIcon = svg`${unsafeSVG(arrowDownCircleFilledRaw)}`;
export const arrowDownCircleIcon = svg`${unsafeSVG(arrowDownCircleRaw)}`;
export const arrowDownZAIcon = svg`${unsafeSVG(arrowDownZARaw)}`;
export const arrowDownIcon = svg`${unsafeSVG(arrowDownRaw)}`;
export const arrowLeftIcon = svg`${unsafeSVG(arrowLeftRaw)}`;
export const arrowPointerIcon = svg`${unsafeSVG(arrowPointerRaw)}`;
export const arrowRightArrowLeftIcon = svg`${unsafeSVG(arrowRightArrowLeftRaw)}`;
export const arrowRightFromRectangleIcon = svg`${unsafeSVG(arrowRightFromRectangleRaw)}`;
export const arrowRightToRectangleIcon = svg`${unsafeSVG(arrowRightToRectangleRaw)}`;
export const arrowRightIcon = svg`${unsafeSVG(arrowRightRaw)}`;
export const arrowRotateLeftIcon = svg`${unsafeSVG(arrowRotateLeftRaw)}`;
export const arrowRotateRightIcon = svg`${unsafeSVG(arrowRotateRightRaw)}`;
export const arrowTrendingDownIcon = svg`${unsafeSVG(arrowTrendingDownRaw)}`;
export const arrowTrendingUpIcon = svg`${unsafeSVG(arrowTrendingUpRaw)}`;
export const arrowTurnDownRightIcon = svg`${unsafeSVG(arrowTurnDownRightRaw)}`;
export const arrowTurnRightFromSquareIcon = svg`${unsafeSVG(arrowTurnRightFromSquareRaw)}`;
export const arrowUpAZIcon = svg`${unsafeSVG(arrowUpAZRaw)}`;
export const arrowUpCircleFilledIcon = svg`${unsafeSVG(arrowUpCircleFilledRaw)}`;
export const arrowUpCircleIcon = svg`${unsafeSVG(arrowUpCircleRaw)}`;
export const arrowUpDownIcon = svg`${unsafeSVG(arrowUpDownRaw)}`;
export const arrowUpRightFromSquareIcon = svg`${unsafeSVG(arrowUpRightFromSquareRaw)}`;
export const arrowUpIcon = svg`${unsafeSVG(arrowUpRaw)}`;
export const arrowsCenterFromCornersIcon = svg`${unsafeSVG(arrowsCenterFromCornersRaw)}`;
export const arrowsDownUpListIcon = svg`${unsafeSVG(arrowsDownUpListRaw)}`;
export const arrowsUpDownListIcon = svg`${unsafeSVG(arrowsUpDownListRaw)}`;
export const artificialIntelligenceIcon = svg`${unsafeSVG(artificialIntelligenceRaw)}`;
export const asmIcon = svg`${unsafeSVG(asmRaw)}`;
export const atSignIcon = svg`${unsafeSVG(atSignRaw)}`;
export const balanceIcon = svg`${unsafeSVG(balanceRaw)}`;
export const bankIcon = svg`${unsafeSVG(bankRaw)}`;
export const barChartIcon = svg`${unsafeSVG(barChartRaw)}`;
export const barsFilterIcon = svg`${unsafeSVG(barsFilterRaw)}`;
export const barsIcon = svg`${unsafeSVG(barsRaw)}`;
export const bellFilledIcon = svg`${unsafeSVG(bellFilledRaw)}`;
export const bellSlashIcon = svg`${unsafeSVG(bellSlashRaw)}`;
export const bellIcon = svg`${unsafeSVG(bellRaw)}`;
export const binIcon = svg`${unsafeSVG(binRaw)}`;
export const bombIcon = svg`${unsafeSVG(bombRaw)}`;
export const bookBookmarkIcon = svg`${unsafeSVG(bookBookmarkRaw)}`;
export const bookIcon = svg`${unsafeSVG(bookRaw)}`;
export const bookmarkIcon = svg`${unsafeSVG(bookmarkRaw)}`;
export const booksIcon = svg`${unsafeSVG(booksRaw)}`;
export const boxClosed2Icon = svg`${unsafeSVG(boxClosed2Raw)}`;
export const boxClosedIcon = svg`${unsafeSVG(boxClosedRaw)}`;
export const boxOpenIcon = svg`${unsafeSVG(boxOpenRaw)}`;
export const bracketsIcon = svg`${unsafeSVG(bracketsRaw)}`;
export const briefcaseIcon = svg`${unsafeSVG(briefcaseRaw)}`;
export const browserArrowUpIcon = svg`${unsafeSVG(browserArrowUpRaw)}`;
export const browserContentIcon = svg`${unsafeSVG(browserContentRaw)}`;
export const browserEarthEuropeIcon = svg`${unsafeSVG(browserEarthEuropeRaw)}`;
export const browserIcon = svg`${unsafeSVG(browserRaw)}`;
export const bug2Icon = svg`${unsafeSVG(bug2Raw)}`;
export const bugPlayIcon = svg`${unsafeSVG(bugPlayRaw)}`;
export const bugTargetIcon = svg`${unsafeSVG(bugTargetRaw)}`;
export const bugIcon = svg`${unsafeSVG(bugRaw)}`;
export const buildingsIcon = svg`${unsafeSVG(buildingsRaw)}`;
export const busIcon = svg`${unsafeSVG(busRaw)}`;
export const buzzerIcon = svg`${unsafeSVG(buzzerRaw)}`;
export const cableIcon = svg`${unsafeSVG(cableRaw)}`;
export const calendarIcon = svg`${unsafeSVG(calendarRaw)}`;
export const cameraLensIcon = svg`${unsafeSVG(cameraLensRaw)}`;
export const candleIcon = svg`${unsafeSVG(candleRaw)}`;
export const carIcon = svg`${unsafeSVG(carRaw)}`;
export const cardsIcon = svg`${unsafeSVG(cardsRaw)}`;
export const caretDownIcon = svg`${unsafeSVG(caretDownRaw)}`;
export const caretLeftIcon = svg`${unsafeSVG(caretLeftRaw)}`;
export const caretRightIcon = svg`${unsafeSVG(caretRightRaw)}`;
export const caretUpDownIcon = svg`${unsafeSVG(caretUpDownRaw)}`;
export const caretUpIcon = svg`${unsafeSVG(caretUpRaw)}`;
export const centerHorizontallyIcon = svg`${unsafeSVG(centerHorizontallyRaw)}`;
export const centerNodeIcon = svg`${unsafeSVG(centerNodeRaw)}`;
export const centerVerticallyIcon = svg`${unsafeSVG(centerVerticallyRaw)}`;
export const chartBarsIcon = svg`${unsafeSVG(chartBarsRaw)}`;
export const checkCircleFilledIcon = svg`${unsafeSVG(checkCircleFilledRaw)}`;
export const checkCircleIcon = svg`${unsafeSVG(checkCircleRaw)}`;
export const checkDottedIcon = svg`${unsafeSVG(checkDottedRaw)}`;
export const checkSquareIcon = svg`${unsafeSVG(checkSquareRaw)}`;
export const checkIcon = svg`${unsafeSVG(checkRaw)}`;
export const chevronCircleDownIcon = svg`${unsafeSVG(chevronCircleDownRaw)}`;
export const chevronCircleLeftIcon = svg`${unsafeSVG(chevronCircleLeftRaw)}`;
export const chevronCircleRightIcon = svg`${unsafeSVG(chevronCircleRightRaw)}`;
export const chevronCircle_upIcon = svg`${unsafeSVG(chevronCircle_upRaw)}`;
export const chevronDownUpIcon = svg`${unsafeSVG(chevronDownUpRaw)}`;
export const chevronDownIcon = svg`${unsafeSVG(chevronDownRaw)}`;
export const chevronLeftIcon = svg`${unsafeSVG(chevronLeftRaw)}`;
export const chevronRightIcon = svg`${unsafeSVG(chevronRightRaw)}`;
export const chevronUpDownIcon = svg`${unsafeSVG(chevronUpDownRaw)}`;
export const chevronUpIcon = svg`${unsafeSVG(chevronUpRaw)}`;
export const circleLockIcon = svg`${unsafeSVG(circleLockRaw)}`;
export const circleSlashIcon = svg`${unsafeSVG(circleSlashRaw)}`;
export const circleIcon = svg`${unsafeSVG(circleRaw)}`;
export const cityIcon = svg`${unsafeSVG(cityRaw)}`;
export const clipboardIcon = svg`${unsafeSVG(clipboardRaw)}`;
export const clockRotateLeftIcon = svg`${unsafeSVG(clockRotateLeftRaw)}`;
export const clockSlashIcon = svg`${unsafeSVG(clockSlashRaw)}`;
export const clockIcon = svg`${unsafeSVG(clockRaw)}`;
export const cloudCodeIcon = svg`${unsafeSVG(cloudCodeRaw)}`;
export const cloudDownloadIcon = svg`${unsafeSVG(cloudDownloadRaw)}`;
export const cloudUploadIcon = svg`${unsafeSVG(cloudUploadRaw)}`;
export const cloudIcon = svg`${unsafeSVG(cloudRaw)}`;
export const clubIcon = svg`${unsafeSVG(clubRaw)}`;
export const clueIcon = svg`${unsafeSVG(clueRaw)}`;
export const clusteringIcon = svg`${unsafeSVG(clusteringRaw)}`;
export const collectionIcon = svg`${unsafeSVG(collectionRaw)}`;
export const commentsIcon = svg`${unsafeSVG(commentsRaw)}`;
export const componentsIcon = svg`${unsafeSVG(componentsRaw)}`;
export const connectedNodesIcon = svg`${unsafeSVG(connectedNodesRaw)}`;
export const connectionsIcon = svg`${unsafeSVG(connectionsRaw)}`;
export const consoleIcon = svg`${unsafeSVG(consoleRaw)}`;
export const cookieIcon = svg`${unsafeSVG(cookieRaw)}`;
export const copyLinkIcon = svg`${unsafeSVG(copyLinkRaw)}`;
export const copyIcon = svg`${unsafeSVG(copyRaw)}`;
export const crosshairIcon = svg`${unsafeSVG(crosshairRaw)}`;
export const crowdsourcedYaraIcon = svg`${unsafeSVG(crowdsourcedYaraRaw)}`;
export const dashboardAlternateIcon = svg`${unsafeSVG(dashboardAlternateRaw)}`;
export const dashboardIcon = svg`${unsafeSVG(dashboardRaw)}`;
export const databaseIcon = svg`${unsafeSVG(databaseRaw)}`;
export const destructionIcon = svg`${unsafeSVG(destructionRaw)}`;
export const deviceIcon = svg`${unsafeSVG(deviceRaw)}`;
export const devicesIcon = svg`${unsafeSVG(devicesRaw)}`;
export const diagram2Icon = svg`${unsafeSVG(diagram2Raw)}`;
export const diagramCircle2Icon = svg`${unsafeSVG(diagramCircle2Raw)}`;
export const diagramCircleIcon = svg`${unsafeSVG(diagramCircleRaw)}`;
export const diagramIcon = svg`${unsafeSVG(diagramRaw)}`;
export const diffIcon = svg`${unsafeSVG(diffRaw)}`;
export const diplomaIcon = svg`${unsafeSVG(diplomaRaw)}`;
export const dnaIcon = svg`${unsafeSVG(dnaRaw)}`;
export const docChartBarMagnifyingGlassIcon = svg`${unsafeSVG(docChartBarMagnifyingGlassRaw)}`;
export const docChartBarIcon = svg`${unsafeSVG(docChartBarRaw)}`;
export const docConfirmIcon = svg`${unsafeSVG(docConfirmRaw)}`;
export const docDollarIcon = svg`${unsafeSVG(docDollarRaw)}`;
export const docEmptyIcon = svg`${unsafeSVG(docEmptyRaw)}`;
export const docEvtxIcon = svg`${unsafeSVG(docEvtxRaw)}`;
export const docIssueIcon = svg`${unsafeSVG(docIssueRaw)}`;
export const docLinkIcon = svg`${unsafeSVG(docLinkRaw)}`;
export const docMemdumpIcon = svg`${unsafeSVG(docMemdumpRaw)}`;
export const docPenCheckIcon = svg`${unsafeSVG(docPenCheckRaw)}`;
export const docPenIcon = svg`${unsafeSVG(docPenRaw)}`;
export const docPhoneIcon = svg`${unsafeSVG(docPhoneRaw)}`;
export const docSecureIcon = svg`${unsafeSVG(docSecureRaw)}`;
export const docSkullIcon = svg`${unsafeSVG(docSkullRaw)}`;
export const docUrlIcon = svg`${unsafeSVG(docUrlRaw)}`;
export const docVtIcon = svg`${unsafeSVG(docVtRaw)}`;
export const docWrittenIcon = svg`${unsafeSVG(docWrittenRaw)}`;
export const dollarChipIcon = svg`${unsafeSVG(dollarChipRaw)}`;
export const dollarIcon = svg`${unsafeSVG(dollarRaw)}`;
export const domainIcon = svg`${unsafeSVG(domainRaw)}`;
export const downloadDocIcon = svg`${unsafeSVG(downloadDocRaw)}`;
export const downloadIcon = svg`${unsafeSVG(downloadRaw)}`;
export const draftmanSquareIcon = svg`${unsafeSVG(draftmanSquareRaw)}`;
export const dragDottedIcon = svg`${unsafeSVG(dragDottedRaw)}`;
export const dragIndicatorIcon = svg`${unsafeSVG(dragIndicatorRaw)}`;
export const dragIcon = svg`${unsafeSVG(dragRaw)}`;
export const enterpriseIcon = svg`${unsafeSVG(enterpriseRaw)}`;
export const envelopeArrowRightIcon = svg`${unsafeSVG(envelopeArrowRightRaw)}`;
export const envelopeFastIcon = svg`${unsafeSVG(envelopeFastRaw)}`;
export const envelopeHookIcon = svg`${unsafeSVG(envelopeHookRaw)}`;
export const envelopeIcon = svg`${unsafeSVG(envelopeRaw)}`;
export const ethernetIcon = svg`${unsafeSVG(ethernetRaw)}`;
export const exclamationCircleFilledIcon = svg`${unsafeSVG(exclamationCircleFilledRaw)}`;
export const exclamationCircleIcon = svg`${unsafeSVG(exclamationCircleRaw)}`;
export const exclamationMarkDiamondFilledIcon = svg`${unsafeSVG(exclamationMarkDiamondFilledRaw)}`;
export const exclamationMarkDiamondIcon = svg`${unsafeSVG(exclamationMarkDiamondRaw)}`;
export const exclamationMarkOctagonFilledIcon = svg`${unsafeSVG(exclamationMarkOctagonFilledRaw)}`;
export const exclamationMarkOctagonIcon = svg`${unsafeSVG(exclamationMarkOctagonRaw)}`;
export const exclamationTriangleFilledIcon = svg`${unsafeSVG(exclamationTriangleFilledRaw)}`;
export const exclamationTriangleIcon = svg`${unsafeSVG(exclamationTriangleRaw)}`;
export const exclamationIcon = svg`${unsafeSVG(exclamationRaw)}`;
export const expandCircleIcon = svg`${unsafeSVG(expandCircleRaw)}`;
export const expandIcon = svg`${unsafeSVG(expandRaw)}`;
export const eyeSlashIcon = svg`${unsafeSVG(eyeSlashRaw)}`;
export const eyeIcon = svg`${unsafeSVG(eyeRaw)}`;
export const facebookIcon = svg`${unsafeSVG(facebookRaw)}`;
export const fastForwardIcon = svg`${unsafeSVG(fastForwardRaw)}`;
export const faviconDetectionsIcon = svg`${unsafeSVG(faviconDetectionsRaw)}`;
export const faviconIcon = svg`${unsafeSVG(faviconRaw)}`;
export const filetypeApkIcon = svg`${unsafeSVG(filetypeApkRaw)}`;
export const filetypeAsfIcon = svg`${unsafeSVG(filetypeAsfRaw)}`;
export const filetypeBpmIcon = svg`${unsafeSVG(filetypeBpmRaw)}`;
export const filetypeCIcon = svg`${unsafeSVG(filetypeCRaw)}`;
export const filetypeCabIcon = svg`${unsafeSVG(filetypeCabRaw)}`;
export const filetypeCapIcon = svg`${unsafeSVG(filetypeCapRaw)}`;
export const filetypeCppIcon = svg`${unsafeSVG(filetypeCppRaw)}`;
export const filetypeCrxIcon = svg`${unsafeSVG(filetypeCrxRaw)}`;
export const filetypeDebIcon = svg`${unsafeSVG(filetypeDebRaw)}`;
export const filetypeDmgIcon = svg`${unsafeSVG(filetypeDmgRaw)}`;
export const filetypeDocIcon = svg`${unsafeSVG(filetypeDocRaw)}`;
export const filetypeDocxIcon = svg`${unsafeSVG(filetypeDocxRaw)}`;
export const filetypeElfIcon = svg`${unsafeSVG(filetypeElfRaw)}`;
export const filetypeEmailIcon = svg`${unsafeSVG(filetypeEmailRaw)}`;
export const filetypeFileIcon = svg`${unsafeSVG(filetypeFileRaw)}`;
export const filetypeFlashIcon = svg`${unsafeSVG(filetypeFlashRaw)}`;
export const filetypeGifIcon = svg`${unsafeSVG(filetypeGifRaw)}`;
export const filetypeGzipIcon = svg`${unsafeSVG(filetypeGzipRaw)}`;
export const filetypeHtmlIcon = svg`${unsafeSVG(filetypeHtmlRaw)}`;
export const filetypeIcoIcon = svg`${unsafeSVG(filetypeIcoRaw)}`;
export const filetypeIpaIcon = svg`${unsafeSVG(filetypeIpaRaw)}`;
export const filetypeIsoIcon = svg`${unsafeSVG(filetypeIsoRaw)}`;
export const filetypeJarIcon = svg`${unsafeSVG(filetypeJarRaw)}`;
export const filetypeJavaIcon = svg`${unsafeSVG(filetypeJavaRaw)}`;
export const filetypeJavascriptIcon = svg`${unsafeSVG(filetypeJavascriptRaw)}`;
export const filetypeJpegIcon = svg`${unsafeSVG(filetypeJpegRaw)}`;
export const filetypeLnkIcon = svg`${unsafeSVG(filetypeLnkRaw)}`;
export const filetypeMachoIcon = svg`${unsafeSVG(filetypeMachoRaw)}`;
export const filetypeMp3Icon = svg`${unsafeSVG(filetypeMp3Raw)}`;
export const filetypeMp4Icon = svg`${unsafeSVG(filetypeMp4Raw)}`;
export const filetypeMsiIcon = svg`${unsafeSVG(filetypeMsiRaw)}`;
export const filetypeOdpIcon = svg`${unsafeSVG(filetypeOdpRaw)}`;
export const filetypeOdtIcon = svg`${unsafeSVG(filetypeOdtRaw)}`;
export const filetypePcapIcon = svg`${unsafeSVG(filetypePcapRaw)}`;
export const filetypePdfIcon = svg`${unsafeSVG(filetypePdfRaw)}`;
export const filetypePedllIcon = svg`${unsafeSVG(filetypePedllRaw)}`;
export const filetypePeexeIcon = svg`${unsafeSVG(filetypePeexeRaw)}`;
export const filetypePngIcon = svg`${unsafeSVG(filetypePngRaw)}`;
export const filetypePptIcon = svg`${unsafeSVG(filetypePptRaw)}`;
export const filetypePptxIcon = svg`${unsafeSVG(filetypePptxRaw)}`;
export const filetypeRarIcon = svg`${unsafeSVG(filetypeRarRaw)}`;
export const filetypeRomIcon = svg`${unsafeSVG(filetypeRomRaw)}`;
export const filetypeRpmIcon = svg`${unsafeSVG(filetypeRpmRaw)}`;
export const filetypeRtfIcon = svg`${unsafeSVG(filetypeRtfRaw)}`;
export const filetypeSvgIcon = svg`${unsafeSVG(filetypeSvgRaw)}`;
export const filetypeSzipIcon = svg`${unsafeSVG(filetypeSzipRaw)}`;
export const filetypeTarIcon = svg`${unsafeSVG(filetypeTarRaw)}`;
export const filetypeTiffIcon = svg`${unsafeSVG(filetypeTiffRaw)}`;
export const filetypeTtfIcon = svg`${unsafeSVG(filetypeTtfRaw)}`;
export const filetypeTxtIcon = svg`${unsafeSVG(filetypeTxtRaw)}`;
export const filetypeUrlIcon = svg`${unsafeSVG(filetypeUrlRaw)}`;
export const filetypeWavIcon = svg`${unsafeSVG(filetypeWavRaw)}`;
export const filetypeXlsIcon = svg`${unsafeSVG(filetypeXlsRaw)}`;
export const filetypeXlslxIcon = svg`${unsafeSVG(filetypeXlslxRaw)}`;
export const filetypeXmlIcon = svg`${unsafeSVG(filetypeXmlRaw)}`;
export const filetypeZipIcon = svg`${unsafeSVG(filetypeZipRaw)}`;
export const filterIcon = svg`${unsafeSVG(filterRaw)}`;
export const filtersIcon = svg`${unsafeSVG(filtersRaw)}`;
export const fingerprintIcon = svg`${unsafeSVG(fingerprintRaw)}`;
export const fireIcon = svg`${unsafeSVG(fireRaw)}`;
export const flagFilledIcon = svg`${unsafeSVG(flagFilledRaw)}`;
export const flagIcon = svg`${unsafeSVG(flagRaw)}`;
export const floppyDiskArrowRightIcon = svg`${unsafeSVG(floppyDiskArrowRightRaw)}`;
export const floppyDiskIcon = svg`${unsafeSVG(floppyDiskRaw)}`;
export const folderArrowUpLeftIcon = svg`${unsafeSVG(folderArrowUpLeftRaw)}`;
export const folderArrowUpIcon = svg`${unsafeSVG(folderArrowUpRaw)}`;
export const folderPlusIcon = svg`${unsafeSVG(folderPlusRaw)}`;
export const folderXmarkIcon = svg`${unsafeSVG(folderXmarkRaw)}`;
export const folderIcon = svg`${unsafeSVG(folderRaw)}`;
export const formatAlignCenterIcon = svg`${unsafeSVG(formatAlignCenterRaw)}`;
export const formatAlignLeftIcon = svg`${unsafeSVG(formatAlignLeftRaw)}`;
export const formatAlignRightIcon = svg`${unsafeSVG(formatAlignRightRaw)}`;
export const formatBoldIcon = svg`${unsafeSVG(formatBoldRaw)}`;
export const formatCodeIcon = svg`${unsafeSVG(formatCodeRaw)}`;
export const formatItalicIcon = svg`${unsafeSVG(formatItalicRaw)}`;
export const formatQuoteIcon = svg`${unsafeSVG(formatQuoteRaw)}`;
export const formatUnderlineIcon = svg`${unsafeSVG(formatUnderlineRaw)}`;
export const gameConsoleControllerIcon = svg`${unsafeSVG(gameConsoleControllerRaw)}`;
export const gasStationIcon = svg`${unsafeSVG(gasStationRaw)}`;
export const gaugeIcon = svg`${unsafeSVG(gaugeRaw)}`;
export const gearWrenchIcon = svg`${unsafeSVG(gearWrenchRaw)}`;
export const gearIcon = svg`${unsafeSVG(gearRaw)}`;
export const geminiIcon = svg`${unsafeSVG(geminiRaw)}`;
export const githubIcon = svg`${unsafeSVG(githubRaw)}`;
export const globeLinkIcon = svg`${unsafeSVG(globeLinkRaw)}`;
export const globeLocationPinIcon = svg`${unsafeSVG(globeLocationPinRaw)}`;
export const globeMagnifyingGlassIcon = svg`${unsafeSVG(globeMagnifyingGlassRaw)}`;
export const globeIcon = svg`${unsafeSVG(globeRaw)}`;
export const goIcon = svg`${unsafeSVG(goRaw)}`;
export const google1Icon = svg`${unsafeSVG(google1Raw)}`;
export const googleIcon = svg`${unsafeSVG(googleRaw)}`;
export const graphBarsBaselineIcon = svg`${unsafeSVG(graphBarsBaselineRaw)}`;
export const graphSimpleIcon = svg`${unsafeSVG(graphSimpleRaw)}`;
export const graphIcon = svg`${unsafeSVG(graphRaw)}`;
export const grid2Icon = svg`${unsafeSVG(grid2Raw)}`;
export const grid3Icon = svg`${unsafeSVG(grid3Raw)}`;
export const gridIcon = svg`${unsafeSVG(gridRaw)}`;
export const groupCircleIcon = svg`${unsafeSVG(groupCircleRaw)}`;
export const hammerWrenchIcon = svg`${unsafeSVG(hammerWrenchRaw)}`;
export const handEllipsisIcon = svg`${unsafeSVG(handEllipsisRaw)}`;
export const handGearIcon = svg`${unsafeSVG(handGearRaw)}`;
export const handRegularIcon = svg`${unsafeSVG(handRegularRaw)}`;
export const handshakeIcon = svg`${unsafeSVG(handshakeRaw)}`;
export const hardDriveIcon = svg`${unsafeSVG(hardDriveRaw)}`;
export const hatIcon = svg`${unsafeSVG(hatRaw)}`;
export const heartIcon = svg`${unsafeSVG(heartRaw)}`;
export const highlightIcon = svg`${unsafeSVG(highlightRaw)}`;
export const homeIcon = svg`${unsafeSVG(homeRaw)}`;
export const houseWifiIcon = svg`${unsafeSVG(houseWifiRaw)}`;
export const huntingIcon = svg`${unsafeSVG(huntingRaw)}`;
export const idCardClipIcon = svg`${unsafeSVG(idCardClipRaw)}`;
export const idsIcon = svg`${unsafeSVG(idsRaw)}`;
export const imageIcon = svg`${unsafeSVG(imageRaw)}`;
export const infoCircleFilledIcon = svg`${unsafeSVG(infoCircleFilledRaw)}`;
export const infoCircleIcon = svg`${unsafeSVG(infoCircleRaw)}`;
export const infoSparkIcon = svg`${unsafeSVG(infoSparkRaw)}`;
export const infoIcon = svg`${unsafeSVG(infoRaw)}`;
export const inquiryCardsIcon = svg`${unsafeSVG(inquiryCardsRaw)}`;
export const insightSparkIcon = svg`${unsafeSVG(insightSparkRaw)}`;
export const intelligenceIcon = svg`${unsafeSVG(intelligenceRaw)}`;
export const justifyLeftIcon = svg`${unsafeSVG(justifyLeftRaw)}`;
export const kebabMenuHorizontalCircleIcon = svg`${unsafeSVG(kebabMenuHorizontalCircleRaw)}`;
export const langIcon = svg`${unsafeSVG(langRaw)}`;
export const laptopIcon = svg`${unsafeSVG(laptopRaw)}`;
export const layerArrowDownIcon = svg`${unsafeSVG(layerArrowDownRaw)}`;
export const layerArrowUpIcon = svg`${unsafeSVG(layerArrowUpRaw)}`;
export const layerGroupVerticalIcon = svg`${unsafeSVG(layerGroupVerticalRaw)}`;
export const layerVerticalCheckIcon = svg`${unsafeSVG(layerVerticalCheckRaw)}`;
export const layersArrowDownIcon = svg`${unsafeSVG(layersArrowDownRaw)}`;
export const layersSquareIcon = svg`${unsafeSVG(layersSquareRaw)}`;
export const layersIcon = svg`${unsafeSVG(layersRaw)}`;
export const leafIcon = svg`${unsafeSVG(leafRaw)}`;
export const lightbulbIcon = svg`${unsafeSVG(lightbulbRaw)}`;
export const lineMonitorIcon = svg`${unsafeSVG(lineMonitorRaw)}`;
export const linkIcon = svg`${unsafeSVG(linkRaw)}`;
export const list2Icon = svg`${unsafeSVG(list2Raw)}`;
export const listMagnifyingGlassIcon = svg`${unsafeSVG(listMagnifyingGlassRaw)}`;
export const listSquaresIcon = svg`${unsafeSVG(listSquaresRaw)}`;
export const listUlIcon = svg`${unsafeSVG(listUlRaw)}`;
export const localPharmacyIcon = svg`${unsafeSVG(localPharmacyRaw)}`;
export const locationPinCheckIcon = svg`${unsafeSVG(locationPinCheckRaw)}`;
export const locationPinIpCircleIcon = svg`${unsafeSVG(locationPinIpCircleRaw)}`;
export const locationPinIpIcon = svg`${unsafeSVG(locationPinIpRaw)}`;
export const locationPinMapIcon = svg`${unsafeSVG(locationPinMapRaw)}`;
export const locationPinQuestionMarkIcon = svg`${unsafeSVG(locationPinQuestionMarkRaw)}`;
export const locationPinXmarkIcon = svg`${unsafeSVG(locationPinXmarkRaw)}`;
export const locationPinIcon = svg`${unsafeSVG(locationPinRaw)}`;
export const lockOpenIcon = svg`${unsafeSVG(lockOpenRaw)}`;
export const lockSslIcon = svg`${unsafeSVG(lockSslRaw)}`;
export const lockIcon = svg`${unsafeSVG(lockRaw)}`;
export const longArrowLeftIcon = svg`${unsafeSVG(longArrowLeftRaw)}`;
export const longArrowRightIcon = svg`${unsafeSVG(longArrowRightRaw)}`;
export const magnifyingGlassChartIcon = svg`${unsafeSVG(magnifyingGlassChartRaw)}`;
export const magnifyingGlassIocStreamIcon = svg`${unsafeSVG(magnifyingGlassIocStreamRaw)}`;
export const magnifyingGlassListIcon = svg`${unsafeSVG(magnifyingGlassListRaw)}`;
export const magnifyingGlassMinusIcon = svg`${unsafeSVG(magnifyingGlassMinusRaw)}`;
export const magnifyingGlassPlusIcon = svg`${unsafeSVG(magnifyingGlassPlusRaw)}`;
export const magnifyingGlassQuestionMarkIcon = svg`${unsafeSVG(magnifyingGlassQuestionMarkRaw)}`;
export const magnifyingGlassSimiliaritiesIcon = svg`${unsafeSVG(magnifyingGlassSimiliaritiesRaw)}`;
export const magnifyingGlassSparkIcon = svg`${unsafeSVG(magnifyingGlassSparkRaw)}`;
export const magnifyingGlassThreadLandscapeIcon = svg`${unsafeSVG(magnifyingGlassThreadLandscapeRaw)}`;
export const magnifyingGlassIcon = svg`${unsafeSVG(magnifyingGlassRaw)}`;
export const mandiantLogoMonochromeIcon = svg`${unsafeSVG(mandiantLogoMonochromeRaw)}`;
export const mandiantIcon = svg`${unsafeSVG(mandiantRaw)}`;
export const mapIcon = svg`${unsafeSVG(mapRaw)}`;
export const megaphoneIcon = svg`${unsafeSVG(megaphoneRaw)}`;
export const menuKebabHorizontalIcon = svg`${unsafeSVG(menuKebabHorizontalRaw)}`;
export const menuKebabVerticalIcon = svg`${unsafeSVG(menuKebabVerticalRaw)}`;
export const menuIcon = svg`${unsafeSVG(menuRaw)}`;
export const messagePenIcon = svg`${unsafeSVG(messagePenRaw)}`;
export const messageSparkIcon = svg`${unsafeSVG(messageSparkRaw)}`;
export const messageIcon = svg`${unsafeSVG(messageRaw)}`;
export const microsoft1Icon = svg`${unsafeSVG(microsoft1Raw)}`;
export const microsoftIcon = svg`${unsafeSVG(microsoftRaw)}`;
export const militaryMedalIcon = svg`${unsafeSVG(militaryMedalRaw)}`;
export const minusCircleFilledIcon = svg`${unsafeSVG(minusCircleFilledRaw)}`;
export const minusCircleIcon = svg`${unsafeSVG(minusCircleRaw)}`;
export const minusSquareIcon = svg`${unsafeSVG(minusSquareRaw)}`;
export const minusIcon = svg`${unsafeSVG(minusRaw)}`;
export const mitreMatrixIcon = svg`${unsafeSVG(mitreMatrixRaw)}`;
export const mitreIcon = svg`${unsafeSVG(mitreRaw)}`;
export const mobileTouchIcon = svg`${unsafeSVG(mobileTouchRaw)}`;
export const monitor1Icon = svg`${unsafeSVG(monitor1Raw)}`;
export const monitorIcon = svg`${unsafeSVG(monitorRaw)}`;
export const moonIcon = svg`${unsafeSVG(moonRaw)}`;
export const multisimilarityIcon = svg`${unsafeSVG(multisimilarityRaw)}`;
export const newIcon = svg`${unsafeSVG(newRaw)}`;
export const nodesAltIcon = svg`${unsafeSVG(nodesAltRaw)}`;
export const nodesMagnifyingGlassIcon = svg`${unsafeSVG(nodesMagnifyingGlassRaw)}`;
export const nodesSkullIcon = svg`${unsafeSVG(nodesSkullRaw)}`;
export const nodesIcon = svg`${unsafeSVG(nodesRaw)}`;
export const noteIcon = svg`${unsafeSVG(noteRaw)}`;
export const objectGroupIcon = svg`${unsafeSVG(objectGroupRaw)}`;
export const objectUngroupIcon = svg`${unsafeSVG(objectUngroupRaw)}`;
export const pageEarthEuropeLayerIcon = svg`${unsafeSVG(pageEarthEuropeLayerRaw)}`;
export const paperclipIcon = svg`${unsafeSVG(paperclipRaw)}`;
export const pauseIcon = svg`${unsafeSVG(pauseRaw)}`;
export const pdfIcon = svg`${unsafeSVG(pdfRaw)}`;
export const penSparkIcon = svg`${unsafeSVG(penSparkRaw)}`;
export const pencilIcon = svg`${unsafeSVG(pencilRaw)}`;
export const personCheckIcon = svg`${unsafeSVG(personCheckRaw)}`;
export const personExclamationMarkIcon = svg`${unsafeSVG(personExclamationMarkRaw)}`;
export const personMinusIcon = svg`${unsafeSVG(personMinusRaw)}`;
export const personXmarkIcon = svg`${unsafeSVG(personXmarkRaw)}`;
export const phoneArrowUpRightIcon = svg`${unsafeSVG(phoneArrowUpRightRaw)}`;
export const phoneIcon = svg`${unsafeSVG(phoneRaw)}`;
export const photoCameraSlashIcon = svg`${unsafeSVG(photoCameraSlashRaw)}`;
export const pictureSparkIcon = svg`${unsafeSVG(pictureSparkRaw)}`;
export const pieChartIcon = svg`${unsafeSVG(pieChartRaw)}`;
export const planIcon = svg`${unsafeSVG(planRaw)}`;
export const planeIcon = svg`${unsafeSVG(planeRaw)}`;
export const playIcon = svg`${unsafeSVG(playRaw)}`;
export const plugIcon = svg`${unsafeSVG(plugRaw)}`;
export const plusCircleFilledIcon = svg`${unsafeSVG(plusCircleFilledRaw)}`;
export const plusCircleIcon = svg`${unsafeSVG(plusCircleRaw)}`;
export const plusIcon = svg`${unsafeSVG(plusRaw)}`;
export const portIcon = svg`${unsafeSVG(portRaw)}`;
export const powerIcon = svg`${unsafeSVG(powerRaw)}`;
export const printerIcon = svg`${unsafeSVG(printerRaw)}`;
export const privateScanningIcon = svg`${unsafeSVG(privateScanningRaw)}`;
export const psychologyIcon = svg`${unsafeSVG(psychologyRaw)}`;
export const pythonIcon = svg`${unsafeSVG(pythonRaw)}`;
export const questionMarkCircleIcon = svg`${unsafeSVG(questionMarkCircleRaw)}`;
export const questionMarkIcon = svg`${unsafeSVG(questionMarkRaw)}`;
export const radioButtonCheckedIcon = svg`${unsafeSVG(radioButtonCheckedRaw)}`;
export const radioButtonPartialIcon = svg`${unsafeSVG(radioButtonPartialRaw)}`;
export const ramIcon = svg`${unsafeSVG(ramRaw)}`;
export const ravenIcon = svg`${unsafeSVG(ravenRaw)}`;
export const rebaseIcon = svg`${unsafeSVG(rebaseRaw)}`;
export const retrohuntHistoryIcon = svg`${unsafeSVG(retrohuntHistoryRaw)}`;
export const retrohuntIcon = svg`${unsafeSVG(retrohuntRaw)}`;
export const rewindIcon = svg`${unsafeSVG(rewindRaw)}`;
export const rhombusArrowDownIcon = svg`${unsafeSVG(rhombusArrowDownRaw)}`;
export const ribbonIcon = svg`${unsafeSVG(ribbonRaw)}`;
export const routerIcon = svg`${unsafeSVG(routerRaw)}`;
export const sandboxAndroboxIcon = svg`${unsafeSVG(sandboxAndroboxRaw)}`;
export const sandboxBitdamAtpIcon = svg`${unsafeSVG(sandboxBitdamAtpRaw)}`;
export const sandboxC2aeIcon = svg`${unsafeSVG(sandboxC2aeRaw)}`;
export const sandboxCapaIcon = svg`${unsafeSVG(sandboxCapaRaw)}`;
export const sandboxCapeSandboxIcon = svg`${unsafeSVG(sandboxCapeSandboxRaw)}`;
export const sandboxCapeIcon = svg`${unsafeSVG(sandboxCapeRaw)}`;
export const sandboxCyberAdaptIcon = svg`${unsafeSVG(sandboxCyberAdaptRaw)}`;
export const sandboxDasSecurityOrcasIcon = svg`${unsafeSVG(sandboxDasSecurityOrcasRaw)}`;
export const sandboxDocguardIcon = svg`${unsafeSVG(sandboxDocguardRaw)}`;
export const sandboxDrwebVxcubeIcon = svg`${unsafeSVG(sandboxDrwebVxcubeRaw)}`;
export const sandboxElfDigestIcon = svg`${unsafeSVG(sandboxElfDigestRaw)}`;
export const sandboxFSecureSandboxIcon = svg`${unsafeSVG(sandboxFSecureSandboxRaw)}`;
export const sandboxFSecureIcon = svg`${unsafeSVG(sandboxFSecureRaw)}`;
export const sandboxFenriscanIcon = svg`${unsafeSVG(sandboxFenriscanRaw)}`;
export const sandboxLastlineIcon = svg`${unsafeSVG(sandboxLastlineRaw)}`;
export const sandboxMalwationIcon = svg`${unsafeSVG(sandboxMalwationRaw)}`;
export const sandboxMicrosoftSysinternalsIcon = svg`${unsafeSVG(sandboxMicrosoftSysinternalsRaw)}`;
export const sandboxNsfocusPomaIcon = svg`${unsafeSVG(sandboxNsfocusPomaRaw)}`;
export const sandboxOsXSandboxIcon = svg`${unsafeSVG(sandboxOsXSandboxRaw)}`;
export const sandboxOsXIcon = svg`${unsafeSVG(sandboxOsXRaw)}`;
export const sandboxQianxinReddripIcon = svg`${unsafeSVG(sandboxQianxinReddripRaw)}`;
export const sandboxReaqtaHiveIcon = svg`${unsafeSVG(sandboxReaqtaHiveRaw)}`;
export const sandboxRisingMovesIcon = svg`${unsafeSVG(sandboxRisingMovesRaw)}`;
export const sandboxSangforZsandIcon = svg`${unsafeSVG(sandboxSangforZsandRaw)}`;
export const sandboxSecneurxIcon = svg`${unsafeSVG(sandboxSecneurxRaw)}`;
export const sandboxSecondwriteIcon = svg`${unsafeSVG(sandboxSecondwriteRaw)}`;
export const sandboxSndboxIcon = svg`${unsafeSVG(sandboxSndboxRaw)}`;
export const sandboxTencentHaboIcon = svg`${unsafeSVG(sandboxTencentHaboRaw)}`;
export const sandboxVenuseyeSandboxIcon = svg`${unsafeSVG(sandboxVenuseyeSandboxRaw)}`;
export const sandboxVenuseyeIcon = svg`${unsafeSVG(sandboxVenuseyeRaw)}`;
export const sandboxVirustotalBoxOfApplesIcon = svg`${unsafeSVG(sandboxVirustotalBoxOfApplesRaw)}`;
export const sandboxVirustotalCuckooforkIcon = svg`${unsafeSVG(sandboxVirustotalCuckooforkRaw)}`;
export const sandboxVirustotalDroidyIcon = svg`${unsafeSVG(sandboxVirustotalDroidyRaw)}`;
export const sandboxVirustotalJoseboxIcon = svg`${unsafeSVG(sandboxVirustotalJoseboxRaw)}`;
export const sandboxVirustotalJujuboxIcon = svg`${unsafeSVG(sandboxVirustotalJujuboxRaw)}`;
export const sandboxVirustotalObserverIcon = svg`${unsafeSVG(sandboxVirustotalObserverRaw)}`;
export const sandboxVirustotalR2dboxIcon = svg`${unsafeSVG(sandboxVirustotalR2dboxRaw)}`;
export const sandboxVirustotalSandboxIcon = svg`${unsafeSVG(sandboxVirustotalSandboxRaw)}`;
export const sandboxVirustotalZenboxIcon = svg`${unsafeSVG(sandboxVirustotalZenboxRaw)}`;
export const sandboxVmrayIcon = svg`${unsafeSVG(sandboxVmrayRaw)}`;
export const sandboxYomiHunterIcon = svg`${unsafeSVG(sandboxYomiHunterRaw)}`;
export const sandboxZenboxAndroidIcon = svg`${unsafeSVG(sandboxZenboxAndroidRaw)}`;
export const sandboxZenboxLinuxIcon = svg`${unsafeSVG(sandboxZenboxLinuxRaw)}`;
export const sandboxZenboxIcon = svg`${unsafeSVG(sandboxZenboxRaw)}`;
export const sandboxZscalerIcon = svg`${unsafeSVG(sandboxZscalerRaw)}`;
export const scaleBalancedIcon = svg`${unsafeSVG(scaleBalancedRaw)}`;
export const schoolHatIcon = svg`${unsafeSVG(schoolHatRaw)}`;
export const screenDeviceIcon = svg`${unsafeSVG(screenDeviceRaw)}`;
export const screenEllipsisIcon = svg`${unsafeSVG(screenEllipsisRaw)}`;
export const screenGearIcon = svg`${unsafeSVG(screenGearRaw)}`;
export const screenShieldIcon = svg`${unsafeSVG(screenShieldRaw)}`;
export const screenSkullIcon = svg`${unsafeSVG(screenSkullRaw)}`;
export const screenIcon = svg`${unsafeSVG(screenRaw)}`;
export const screwdriverBenchIcon = svg`${unsafeSVG(screwdriverBenchRaw)}`;
export const selectChildrenIcon = svg`${unsafeSVG(selectChildrenRaw)}`;
export const selectParentIcon = svg`${unsafeSVG(selectParentRaw)}`;
export const sendIcon = svg`${unsafeSVG(sendRaw)}`;
export const sensorsIcon = svg`${unsafeSVG(sensorsRaw)}`;
export const serverIcon = svg`${unsafeSVG(serverRaw)}`;
export const shareIcon = svg`${unsafeSVG(shareRaw)}`;
export const shieldCheckIcon = svg`${unsafeSVG(shieldCheckRaw)}`;
export const shieldExclamationMarkIcon = svg`${unsafeSVG(shieldExclamationMarkRaw)}`;
export const shieldPersonIcon = svg`${unsafeSVG(shieldPersonRaw)}`;
export const shieldSlashIcon = svg`${unsafeSVG(shieldSlashRaw)}`;
export const shieldSslIcon = svg`${unsafeSVG(shieldSslRaw)}`;
export const shieldIcon = svg`${unsafeSVG(shieldRaw)}`;
export const shoppingCartIcon = svg`${unsafeSVG(shoppingCartRaw)}`;
export const sigmaIcon = svg`${unsafeSVG(sigmaRaw)}`;
export const similarIcon = svg`${unsafeSVG(similarRaw)}`;
export const skullGroupIcon = svg`${unsafeSVG(skullGroupRaw)}`;
export const skullRelationIcon = svg`${unsafeSVG(skullRelationRaw)}`;
export const skullZipIcon = svg`${unsafeSVG(skullZipRaw)}`;
export const skullIcon = svg`${unsafeSVG(skullRaw)}`;
export const smartphoneIcon = svg`${unsafeSVG(smartphoneRaw)}`;
export const sortIcon = svg`${unsafeSVG(sortRaw)}`;
export const spadeIcon = svg`${unsafeSVG(spadeRaw)}`;
export const speakerXmarkIcon = svg`${unsafeSVG(speakerXmarkRaw)}`;
export const speedometerIcon = svg`${unsafeSVG(speedometerRaw)}`;
export const spinnerFourthIcon = svg`${unsafeSVG(spinnerFourthRaw)}`;
export const spinnerQuarterIcon = svg`${unsafeSVG(spinnerQuarterRaw)}`;
export const squareArrowCornerTopRightIcon = svg`${unsafeSVG(squareArrowCornerTopRightRaw)}`;
export const squareArrowLeftIcon = svg`${unsafeSVG(squareArrowLeftRaw)}`;
export const squareCircleCornersIcon = svg`${unsafeSVG(squareCircleCornersRaw)}`;
export const squareCornersQuestionMarkIcon = svg`${unsafeSVG(squareCornersQuestionMarkRaw)}`;
export const squareDottedIcon = svg`${unsafeSVG(squareDottedRaw)}`;
export const squareRssIcon = svg`${unsafeSVG(squareRssRaw)}`;
export const squareIcon = svg`${unsafeSVG(squareRaw)}`;
export const starFilledIcon = svg`${unsafeSVG(starFilledRaw)}`;
export const starIcon = svg`${unsafeSVG(starRaw)}`;
export const statsIcon = svg`${unsafeSVG(statsRaw)}`;
export const stethoscopeIcon = svg`${unsafeSVG(stethoscopeRaw)}`;
export const storefrontIcon = svg`${unsafeSVG(storefrontRaw)}`;
export const subdomainIcon = svg`${unsafeSVG(subdomainRaw)}`;
export const sunIcon = svg`${unsafeSVG(sunRaw)}`;
export const tableIcon = svg`${unsafeSVG(tableRaw)}`;
export const tabletListIcon = svg`${unsafeSVG(tabletListRaw)}`;
export const tagsDottedIcon = svg`${unsafeSVG(tagsDottedRaw)}`;
export const tagsIcon = svg`${unsafeSVG(tagsRaw)}`;
export const targetArrowIcon = svg`${unsafeSVG(targetArrowRaw)}`;
export const targetDetectIcon = svg`${unsafeSVG(targetDetectRaw)}`;
export const targetEyeIcon = svg`${unsafeSVG(targetEyeRaw)}`;
export const targetIcon = svg`${unsafeSVG(targetRaw)}`;
export const terminalIcon = svg`${unsafeSVG(terminalRaw)}`;
export const testTubeIcon = svg`${unsafeSVG(testTubeRaw)}`;
export const thpIcon = svg`${unsafeSVG(thpRaw)}`;
export const thumbsDownFilledIcon = svg`${unsafeSVG(thumbsDownFilledRaw)}`;
export const thumbsDownIcon = svg`${unsafeSVG(thumbsDownRaw)}`;
export const thumbsUpFilledIcon = svg`${unsafeSVG(thumbsUpFilledRaw)}`;
export const thumbsUpIcon = svg`${unsafeSVG(thumbsUpRaw)}`;
export const thumbtackFilledIcon = svg`${unsafeSVG(thumbtackFilledRaw)}`;
export const thumbtackIcon = svg`${unsafeSVG(thumbtackRaw)}`;
export const thumbtacksIcon = svg`${unsafeSVG(thumbtacksRaw)}`;
export const thunderboltIcon = svg`${unsafeSVG(thunderboltRaw)}`;
export const timelineIcon = svg`${unsafeSVG(timelineRaw)}`;
export const timerIcon = svg`${unsafeSVG(timerRaw)}`;
export const tractorIcon = svg`${unsafeSVG(tractorRaw)}`;
export const triangleIcon = svg`${unsafeSVG(triangleRaw)}`;
export const twoFactorAuthenticationIcon = svg`${unsafeSVG(twoFactorAuthenticationRaw)}`;
export const twoGearsIcon = svg`${unsafeSVG(twoGearsRaw)}`;
export const twoUsersIcon = svg`${unsafeSVG(twoUsersRaw)}`;
export const unknownIcon = svg`${unsafeSVG(unknownRaw)}`;
export const unlinkIcon = svg`${unsafeSVG(unlinkRaw)}`;
export const upgradeIcon = svg`${unsafeSVG(upgradeRaw)}`;
export const uploadIcon = svg`${unsafeSVG(uploadRaw)}`;
export const userAccountIcon = svg`${unsafeSVG(userAccountRaw)}`;
export const userConfigIcon = svg`${unsafeSVG(userConfigRaw)}`;
export const userHeadsetIcon = svg`${unsafeSVG(userHeadsetRaw)}`;
export const userLockIcon = svg`${unsafeSVG(userLockRaw)}`;
export const userMagnifyingGlassIcon = svg`${unsafeSVG(userMagnifyingGlassRaw)}`;
export const userPlusIcon = svg`${unsafeSVG(userPlusRaw)}`;
export const userTargetIcon = svg`${unsafeSVG(userTargetRaw)}`;
export const userVictimIcon = svg`${unsafeSVG(userVictimRaw)}`;
export const userIcon = svg`${unsafeSVG(userRaw)}`;
export const usersIcon = svg`${unsafeSVG(usersRaw)}`;
export const vennDiagramIcon = svg`${unsafeSVG(vennDiagramRaw)}`;
export const verticalLineChevronLeftIcon = svg`${unsafeSVG(verticalLineChevronLeftRaw)}`;
export const verticalLineChevronRightIcon = svg`${unsafeSVG(verticalLineChevronRightRaw)}`;
export const videoCameraIcon = svg`${unsafeSVG(videoCameraRaw)}`;
export const vtDatabaseIcon = svg`${unsafeSVG(vtDatabaseRaw)}`;
export const vtFillIcon = svg`${unsafeSVG(vtFillRaw)}`;
export const vtRibbonIcon = svg`${unsafeSVG(vtRibbonRaw)}`;
export const vtIcon = svg`${unsafeSVG(vtRaw)}`;
export const walletIcon = svg`${unsafeSVG(walletRaw)}`;
export const webIcon = svg`${unsafeSVG(webRaw)}`;
export const whoisIcon = svg`${unsafeSVG(whoisRaw)}`;
export const xTwitterIcon = svg`${unsafeSVG(xTwitterRaw)}`;
export const xmarkCircleFilledIcon = svg`${unsafeSVG(xmarkCircleFilledRaw)}`;
export const xmarkCircleIcon = svg`${unsafeSVG(xmarkCircleRaw)}`;
export const xmarkIcon = svg`${unsafeSVG(xmarkRaw)}`;
export const yaraExportIcon = svg`${unsafeSVG(yaraExportRaw)}`;
export const yaraIcon = svg`${unsafeSVG(yaraRaw)}`;
export const zenboxAndroidIcon = svg`${unsafeSVG(zenboxAndroidRaw)}`;
export const zenboxLinuxIcon = svg`${unsafeSVG(zenboxLinuxRaw)}`;
